import React from "react";
import styled from "styled-components";


function Subtitulo({ data, styles }) {
    const text = data.subtitulo;

    const { subtitulo } = styles;
    const margin = subtitulo.margin;
    const colorTexto = subtitulo.colorTexto;
    const fontSize = subtitulo.fontSize;
    const textTransform = subtitulo.textTransform;
    const fontWeigth = subtitulo.fontWeigth;

    const lineas = text.split("\n").map((linea, index, array) => (
        <React.Fragment key={index}>
            {linea}
            {index < array.length - 1 && <br />}
        </React.Fragment>
    ));

    return (
        <SubtituloContainer
            margin={margin}
            textTransform={textTransform}
        >
            <P 
                fontWeigth={fontWeigth}
                colorTexto={colorTexto}
                fontSize={fontSize}
            >
                {lineas}
            </P>
        </SubtituloContainer>
    );
}

const SubtituloContainer = styled.div`
    margin: ${({ margin }) => margin};
    text-transform: ${({ textTransform }) => textTransform};
`;

const P = styled.p`
    font-weight: ${({ fontWeigth }) => fontWeigth};
    margin: 2px 0 2px 0;
    color: ${({ colorTexto }) => colorTexto};
    font-size: ${({ fontSize }) => fontSize};
`;


export default Subtitulo;
