import React, { useState } from "react";
import Social from "../components/Social";

const Redes = ({ user }) => {
    return (
        <>
            <Social user={user}/>
        </>
    );
};

export default Redes;
