import Links from "../components/Links";

const MyLinks = ({ user }) => {
    return (
        <>
            <Links user={user} />
        </>
    );
};

export default MyLinks;
