import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import Section from "./Section";
import ToogleButton from "./ToogleButton";
import Lock from "./Lock";
import { HexAlphaColorPicker } from "react-colorful";
import { useTheme } from "../hooks/ThemeContext";

const ColorButton = ({
    text = "Color fondo",
    defaultColor = "#000000",
    textArea = "",
    placeholder = "",
    value = "",
    padding,
    usable = true,
    setTrue = false,
    onColorChange,
    onTextChange,
    onToggleChange,
}) => {

    const { themeContent, theme } = useTheme();
    const [colors, setColors] = useState(themeContent);
    useEffect(() => {
        setColors(themeContent);
    }, [themeContent, theme]);

    const [color, setColor] = useState(defaultColor);
    const [enable, setEnable] = useState(setTrue);
    const [picker, setPicker] = useState(false);

    const pickerHex = useRef();

    const handleInputChange = (e) => {
        const text = e.target.value;
        onTextChange(text);
    };

    const handleToogle = () => {
        setEnable(!enable);
        onToggleChange(!enable);
    };

    const handleColorChange = (newColor) => {
        if (!newColor.startsWith("#")) {
            newColor = "#" + newColor;
        }
        setColor(newColor.toUpperCase());
        onColorChange(newColor);
    };;

    const TitleDivStyle = {
        display: "flex",
        justifyContent: "space-between",
    };

    const [isClick, setIsClick] = useState(false);

    const handleBlockClick = (e) => {
        e.stopPropagation();

        setIsClick(true);
        setTimeout(() => {
            setIsClick(false);
        }, 500);
    };

    const handlePickerClick = (e) => {
        e.stopPropagation();
    };

    useEffect(() => {
        if (picker === true) {
            pickerHex.current.focus();
        }
    }, [picker]);

    return (
        <>
            <StyledDiv
                $bgcolor={usable ? colors.surfaceContainer : colors.bgSwitchDesactivado}
                onClick={handleBlockClick}
            >
                {textArea !== "" ? (
                    <>
                        <div style={!usable ? TitleDivStyle : null}>
                            {!usable ? (
                                <div
                                    style={{
                                        marginTop: "5px",
                                        marginRight: "10px",
                                    }}
                                >
                                    <Lock onShake={isClick} />
                                </div>
                            ) : null}
                        </div>
                        <InputContainer>
                            <Input
                                disabled={usable ? false : true}
                                placeholder={placeholder}
                                $padd={padding}
                                type="text"
                                value={value}
                                onChange={handleInputChange}
                                $bgcolor={usable ? colors.onSurfaceContainer : colors.circleSwitchDesactivado}
                                $col={colors.onSurface}
                                $colorPH={'#999'}
                            />
                            <ToogleButton
                                onChange={handleToogle}
                                setTrue={enable}
                                usable={usable ? true : false}
                                disable={!usable}
                            />
                        </InputContainer>
                    </>
                ) : null}
                <ContainerFlex>
                    <StyledP color={colors.onSurface}>{text}</StyledP>
                    <StyledColorDiv>
                        {picker ? (
                            <ModalWrapper onClick={() => setPicker(false)}>
                                <ModalContent onClick={handlePickerClick}>
                                    <HexAlphaColorPicker
                                        color={color}
                                        onChange={(newColor) =>
                                            handleColorChange(newColor)
                                        }
                                    />
                                    <div className="d-flex my-2 w-100 justify-content-center">
                                        <SelectedColor
                                            style={{
                                                backgroundColor: `${color}`,
                                            }}
                                        ></SelectedColor>
                                        <InputHex
                                            type="text"
                                            value={color}
                                            onChange={(e) =>
                                                handleColorChange(
                                                    e.target.value,
                                                )
                                            }
                                            ref={pickerHex}
                                        />
                                    </div>
                                </ModalContent>
                            </ModalWrapper>
                        ) : null}
                        <>
                            <SelectedColor
                                style={{
                                    backgroundColor: `${color}`,
                                }}
                                onClick={() => setPicker(true)}
                            ></SelectedColor>
                            <StyledP color={colors.onSurface}>{color}</StyledP>
                        </>
                    </StyledColorDiv>
                </ContainerFlex>
            </StyledDiv>
        </>
    );
};

const SelectedColor = styled.div`
    cursor: pointer;
    width: 25px;
    height: 25px;
    border-radius: 5px;
    margin: 2px;
    border: 1px solid #818181;
`;

const ModalWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
`;

const ModalContent = styled.div`
    background-color: white;
    padding: 20px;
    border-radius: 8px;
`;

const StyledDiv = styled.div`
    width: 100%;
    height: 100%;
    background-color: ${(props) => props.$bgcolor};
    border-radius: 10px;
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.2);
    margin: 10px 0;
    padding: 3px 0;
`;

const ContainerFlex = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    // @media (max-width: 300px) {
    //     flex-direction: column;
    // }
`;

const StyledP = styled.p`
    padding: 0px;
    font-size: 14px;
    text-align: center;
    margin: 0;
    color: ${(props) => props.color};
`;

const StyledColorDiv = styled.div`
    border: 1px solid #818181;
    border-radius: 5px;
    margin: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 40%;
    @media (max-width: 345px) {
        width: 60%;
    }
`;

const InputContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Input = styled.input`
    width: 70%;
    padding: 10px;
    padding-bottom: ${(props) => props.$padd};
    background-color: ${(props) => props.$bgcolor};
    border: none;
    border-radius: 5px;
    font-size: 0.8rem;
    text-align: left;
    outline: none;
    margin: 10px 10px;
    color: ${(props) => props.$col};

    &::placeholder {
        color: ${(props) => props.$colorPH};
    }
`;

const InputHex = styled.input`
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    border: none;
    max-width: 60px;
`;

// width: 100%;
// padding: 10px;
// background-color: #E8E0E5;
// border: none;
// border-radius: 5px;
// font-size: 0.8rem;
// text-align: left;
// outline: none;
// margin: 10px 10px;

export default ColorButton;
