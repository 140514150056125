import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { db, storage } from "../../../config/firebase.config.js";
import {
    // getFirestore,
    // doc,
    // getDoc,
    getDocs,
    query,
    where,
    collection,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import constant from "../../../constants/constMap.js";
import { saveDataToLocal, loadDataFromLocal } from "../utils/LocalStorage";

const Avatar = ({ width = "44px", show = true }) => {
    // const [userData, setUserData] = useState({});
    const [imageUrl, setImageUrl] = useState(loadDataFromLocal("avatar") || "");

    useEffect(() => {
        const auth = getAuth();
        const currentUser = auth.currentUser;
        const userUID = currentUser ? currentUser.uid : "";

        const checkUser = async () => {
            const userQuery = query(
                collection(db, constant.collectionUser),
                where("perfil.uid", "==", userUID),
            );

            const querySnapshot = await getDocs(userQuery);

            if (querySnapshot.docs.length > 0) {
                // setUserData(querySnapshot.docs[0].data());
                setImageUrl(
                    querySnapshot.docs[0].data().perfil.img ||
                        querySnapshot.docs[0].data().perfil.default,
                );
                saveDataToLocal(
                    "avatar",
                    querySnapshot.docs[0].data().perfil.img ||
                        querySnapshot.docs[0].data().perfil.default,
                );
                // console.log("Avatar seteado");
            } else {
                // console.log("El documento del usuario no existe avatar.");
            }
        };

        checkUser();
    }, []);

    return (
        <>
            {show ? (
                imageUrl ? (
                    <Img width={width} src={imageUrl} alt="" />
                ) : null
            ) : null}
        </>
    );
};

const Img = styled.img`
    width: ${(props) => props.width};
    height: ${(props) => props.width};
    aspect-ratio: 1/1;
    border-radius: 50%;
    object-fit: cover;
`;

export default Avatar;
