import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Title from "../common/TitleSection";
import Subtitle from "../common/SubtitleSection";
import Section from "../common/Section";
// import Separator from "../common/Separator";
import AlertModal from "../common/AlertModal.jsx";
import Card from "../common/AcountCard";
import { getDocs, query, where, collection } from "firebase/firestore";
import { getAuth, deleteUser as deleteAuthUser } from "firebase/auth";
import { doc, deleteDoc } from "firebase/firestore";
import { deleteObject, ref } from "firebase/storage";
import { db, storage } from "../../../config/firebase.config.js";
import constant from "../../../constants/constMap.js";
import { checkUserDocument } from "../../../utils/utils.js";
import { useTheme } from "../hooks/ThemeContext.jsx";
import firebase from "../../../config/firebase.config.js";
import { useLang } from "../hooks/LangContext";


const Account = () => {
    const { themeContent, theme } = useTheme();
    const [colors, setColors] = useState(themeContent);
    const [userData, setUserData] = useState(null);
    const [url, setUrl] = useState("");
    const [user, setUser] = useState("Usuario");
    const [mail, setMail] = useState("");
    const [plan, setPlan] = useState("Gratuito test");
    const [btnStyle, setBtnStyle] = useState({});
    const [isInputFilled, setIsInputFilled] = useState(false);
    const [alert, setAlert] = useState(false);
    const host = window.location.host;
    
    useEffect(() => {
        setColors(themeContent);
    }, [themeContent, theme]);
    
    const { langContent, lang } = useLang();
    const [content, setContent] = useState(langContent);
    useEffect(() => {
        setContent(langContent);
    }, [langContent, lang]);
    
    const ContentAccount = content.Account;

    const buttonStyleActive = {
        color: colors.onSurface,
        bgcolor: colors.surface,
        border: `1px solid ${colors.onSurface}`,
    };

    const buttonStyleInactive = {
        color: colors.onSurfaceContainer,
        bgcolor: colors.surfaceContainerHigh,
        border: `1px solid ${colors.onSurfaceContainer}`,
    };

    useEffect(() => {
        if (isInputFilled) {
            setBtnStyle(buttonStyleActive);
        } else {
            setBtnStyle(buttonStyleInactive);
        }
    }, [isInputFilled, colors]);

    useEffect(() => {
        if (isInputFilled) {
            setBtnStyle(buttonStyleActive);
        } else {
            setBtnStyle(buttonStyleInactive);
        }
    }, [isInputFilled]);

    useEffect(() => {
        const auth = getAuth();
        const currentUser = auth.currentUser;
        const uid = currentUser ? currentUser.uid : "";

        const fetchData = async () => {
            try {
                if (currentUser) {
                    const documentExists = await checkUserDocument(db, uid);

                    if (!documentExists) {
                        return;
                    }

                    const q = query(
                        collection(db, constant.collectionUser),
                        where("perfil.uid", "==", uid),
                    );
                    try {
                        const querySnapshot = await getDocs(q);
                        const userData = querySnapshot.docs[0].data().perfil;
                        setUserData(userData);
                    } catch (error) {
                        console.error("Error al verificar el usuario:", error);
                    }
                }
            } catch (error) {
                console.error("Error al verificar el usuario:", error);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        if (userData) {
            setUser(userData.nombre);
            setPlan(userData.plan);
            setUrl(`${host}/${userData.nombre}`);
            setMail(userData.mail);
        }
    }, [userData]);

    const handleChange = (e) => {
        const inputFields = document.querySelectorAll("input");
        let allFieldsFilled = true;

        inputFields.forEach((input) => {
            if (input.value.trim() === "") {
                allFieldsFilled = false;
            }
        });

        setIsInputFilled(allFieldsFilled);
    };

    const handleClickCreate = () => {
        console.log("Creando nuevo enlace");
    };

    const handleSaveChanges = (e) => {
        e.preventDefault();
        console.log("Guardando cambios");
    };

    const handleStandarPlan = () => {
        console.log("Cambiando a plan estandar");
    };

    const handleClickDeleteAccount = () => {
        console.log("Click en eliminar cuenta");
        setAlert(true);
    };

    const handleDeleteAccount = async () => {
        try {
            setAlert(false);

            const auth = getAuth();
            const currentUser = auth.currentUser;

            console.log("currentUser", currentUser);

            if (!currentUser) {
                console.error("No hay usuario actualmente autenticado.");
                return;
            }
            // Solo funciona si el usuario esta autenticado recientemente
            // const user = firebase.auth().currentUser;
            // if (user) {
            //     try {
            //         await user.delete();
            //         console.log("Cuenta eliminada correctamente.");
            //     } catch (error) {
            //         console.error(
            //             "Error al eliminar la cuenta:",
            //             error.message,
            //         );
            //     }
            // } else {
            //     console.log("No hay usuario actualmente autenticado.");
            // }

            // await firebase.auth().currentUser.delete().then(() => {
            //     console.log("Usuario eliminado exitosamente.");
            //     window.location.reload()
            // }).catch((error) => {
            //     console.error("Error al eliminar la cuenta del usuario:", error);
            // });

            // const userDocRef = doc(db, constant.collectionUser, currentUser.uid);
            // await deleteDoc(userDocRef);

            // const userStorageRef = ref(storage, `${currentUser.uid}`);
            // await deleteObject(userStorageRef);

            console.log("Usuario eliminado exitosamente.");
        } catch (error) {
            console.error("Error al eliminar la cuenta del usuario:", error);
        }
    };

    return (
        <>
            {alert && (
                <AlertModal
                    show={true}
                    parrafo="¿Estas seguro de eliminar tu cuenta? Esto borrara toda tu informacion."
                    title="Eliminar cuenta"
                    onCancel={() => setAlert(false)}
                    onDelete={handleDeleteAccount}
                />
            )}
            <Container>
                <Section>
                    <Title
                        size="1.5rem"
                        title={ContentAccount.title}
                        align="center"
                    />
                    <Card
                        title={user}
                        subtitle={url}
                        colors={colors}
                        onClickButton={handleClickCreate}
                    />
                </Section>
                <Section>
                    <Title title={ContentAccount.miInfo.title} />
                    <Form $col={colors.onSurface}>
                        <label>{ContentAccount.miInfo.inputs[0]}</label>
                        <input
                            type="text"
                            placeholder={user}
                            onChange={handleChange}
                        />
                        <label>{ContentAccount.miInfo.inputs[1]}</label>
                        <input
                            type="email"
                            placeholder={mail}
                            onChange={handleChange}
                        />

                        <Button
                            $col={btnStyle.color}
                            $bg={btnStyle.bgcolor}
                            $bor={btnStyle.border}
                            onClick={handleSaveChanges}
                        >
                            {ContentAccount.miInfo.btn}
                        </Button>
                    </Form>
                </Section>
                <Section>
                    <Title title={ContentAccount.misLinks.title}></Title>
                    <Ul $col={colors.onSurface}>
                        <li>
                            {ContentAccount.misLinks.inputs[0]}{" "}
                            <span>{user}</span>{" "}
                        </li>
                        <li>
                            {ContentAccount.misLinks.inputs[1]}{" "}
                            <span>{plan}</span>{" "}
                        </li>
                    </Ul>
                    <Button
                        $col={buttonStyleActive.color}
                        $bg={buttonStyleActive.bgcolor}
                        $bor={buttonStyleActive.border}
                        onClick={handleStandarPlan}
                    >
                        {ContentAccount.misLinks.btn}
                    </Button>
                </Section>
                <Section>
                    <Title title={ContentAccount.delete.title}></Title>
                    <Subtitle title={ContentAccount.delete.text}></Subtitle>
                    <Button
                        $col={colors.onError}
                        $bg={colors.error}
                        $bor=""
                        onClick={handleClickDeleteAccount}
                    >
                        {ContentAccount.delete.btn}
                    </Button>
                </Section>
            </Container>
        </>
    );
};

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
`;

const Button = styled.button`
    width: 100%;
    height: 2.5rem;
    border-radius: 10px;
    border: none;
    border: ${(props) => props.$bor};
    margin-bottom: 1rem;
    padding-left: 1rem;
    background-color: ${(props) => props.$bg};
    color: ${(props) => props.$col};
    font-weight: bold;
    text-align: center;
`;

const Form = styled.form`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    label {
        font-size: 0.8rem;
        font-weight: regular;
        color: ${(props) => props.$col};
    }
    input {
        width: 100%;
        height: 2rem;
        background-color: transparent;
        border: none;
        border: 1px 0 0 0 solid ${(props) => props.$col};
        border-bottom: 1px solid ${(props) => props.$col};
        outline: none;
        margin-bottom: 1rem;
    }
`;

const Ul = styled.ul`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 0px 3rem 0px 0px;
    text-align: left;

    li {
        text-align: left;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 0.8rem;
        font-weight: regular;
        color: ${(props) => props.$col};
        margin-bottom: 1rem;

        span {
            font-size: 0.8rem;
            font-weight: bold;
            color: ${(props) => props.$col};
            text-align: left;
        }
    }
`;

export default Account;
