import React from "react";
import styled from "styled-components";
import content from "../../../content/es/dashboardContent.json";

const NotFound = () => {
    return (
        <Container>
            <ErrorContent >
                <h1>{content.NotFound.title}</h1>
                <h2>{content.NotFound.subtitle}</h2>
                <A href="/">{content.NotFound.button}</A>
            </ErrorContent>
        </Container>
    );
};

const ErrorContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 5rem;
    h1 {
        font-size: 10rem;
    }
    h2 {
        font-size: 3rem;
    }

    @media (max-width: 768px) {
        h1 {
            font-size: 8rem;
        }
        h2 {
            font-size: 2rem;
        }
    }
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
`;

const A = styled.a`
    margin-top: 2rem;
    font-size: 2rem;
    text-decoration: none;
    color: #a84ac9;
    &:hover {
        color: #8A36A7;
    }
`;

export default NotFound;


