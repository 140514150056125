import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { useTheme } from "../hooks/ThemeContext";

const Lock = ({ onShake }) => {
    const { theme } = useTheme();

    const [isShaking, setIsShaking] = useState(onShake);

    useEffect(() => {
        setIsShaking(onShake);
    }, [onShake]);

    return (
        <>
            {theme === "light" ? (
                <StyledLock
                    src="/img/svg/lock.svg"
                    alt="lock"
                    $isshaking={isShaking ? "true" : "false"}
                />
            ) : (
                <StyledLock
                    src="/img/svg/lock-dark.svg"
                    alt="lock"
                    $isshaking={isShaking ? "true" : "false"}
                />
            )}
        </>
    );
};

const shake = keyframes`
    0% { transform: translateX(0); }
    20% { transform: translateX(-5px); }
    40% { transform: translateX(5px); }
    60% { transform: translateX(-5px); }
    80% { transform: translateX(5px); }
    100% { transform: translateX(0); }
`;

const StyledLock = styled.img`
    animation: ${(props) => (props.$isshaking == "true" ? shake : "")} 0.5s;
    animation-timing-function: ease-in-out;
`;

export default Lock;
