import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useTheme } from "../hooks/ThemeContext";
import Loading from "./Loading";

const ButtonRappid = ({ text, onClick, loading=false }) => {
    const { themeContent, theme } = useTheme();
    const [colors, setColors] = useState(themeContent);
    useEffect(() => {
        setColors(themeContent);
    }, [themeContent, theme]);

    return (
        <>
            <Button
                onClick={onClick}
                $col={colors.onSurface}
                $bord={colors.onSurface}
            >
                {
                    loading ? 
                        <Loading height="30px" width="30px" position="relative" /> 
                        : text 
                }
            </Button>
        </>
    );
};

const Button = styled.button`
    background-color: transparent;
    border: none;
    color: ${(props) => props.$col};
    padding: 8px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    border-radius: 50px;
    cursor: pointer;
    border: 1px solid ${(props) => props.$bord};
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 120px;
    min-height: 48px;
`;

export default ButtonRappid;
