import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import BottomSheets from "../common/BottomSheet";
import Avatar from "./Avatar";
import constMap from "../../../constants/constMap.js";
import AlertModal from "./AlertModal.jsx";
// import TakePicture from "./TakePicture.jsx";
import { db, storage } from "../../../config/firebase.config.js";
import "firebase/firestore";
import "firebase/storage";

const EditLogo = ({ text, user }) => {
    const collection = constMap.collectionUser;
    const [showDelete, setShowDelete] = useState(false);
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);

    useEffect(() => {
        const fetch = async () => {
            try {
                const docRef = db.collection(collection).doc(user);
                const docSnapshot = await docRef.get();
                if (docSnapshot.exists) {
                    const userData = docSnapshot.data();
                    setSelectedImage(
                        userData.perfil.img || userData.perfil.default,
                    );
                } else {
                    console.log("Personaliza: No existe el usuario.");
                }
            } catch (error) {
                console.error(
                    "Fondo: Error al obtener la imagen de Firestore: ",
                    error,
                );
            }
        };
        fetch();
    }, [user]);

    const [toggleBottomSheet, setToggleBottomSheet] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    const handleBottomSheet = () => {
        setToggleBottomSheet(!toggleBottomSheet);
        setShowDelete(false);
        setShow(!show);
    };

    const handleDeletePicture = () => {
        try {

            const defaultImageRef = storage.ref(`${user}/images/Avatar.jpg`);
            defaultImageRef.getDownloadURL().then((url) => {
                db.collection(collection)
                    .doc(user)
                    .update({
                        "perfil.img": "",
                        "datos.logo": url,
                    })
                    .then(() => {
                        window.location.reload();
                    })
                    .catch((error) => {
                        console.error(
                            "Error al eliminar la imagen de Firestore: ",
                            error,
                        );
                    });

            });
        } catch (error) {
            console.error("Error al eliminar la imagen: ", error);
        }
        setShowDelete(false);
    };

    const handleSetShowDelete = () => {
        setShowDelete(true);
    };

    const handleCancel = () => {
        setShowDelete(false);
    };

    return (
        <>
            <AlertModal
                show={showDelete}
                onCancel={handleCancel}
                onDelete={handleDeletePicture}
            />
            <Logo onClick={handleBottomSheet}>
                <Avatar width="100%" />
            </Logo>
            <Text onClick={handleBottomSheet}>{text}</Text>
            <BottomSheets
                user={user}
                toggleBottomSheet={toggleBottomSheet}
                onClose={() => handleBottomSheet(false)}
                onDeletePicture={handleSetShowDelete}
                show={show}
            />
        </>
    );
};

const Logo = styled.div`
    width: 100px;
    aspect-ratio: 1/1;
    background-color: #d9d9d9;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    cursor: pointer;
`;

const Text = styled.p`
    text-align: center;
    font-size: 15px;
    font-weight: 500;
    color: #4099eb;
    margin-top: 10px;
`;

export default EditLogo;
