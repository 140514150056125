import {
    getFirestore,
    getDocs,
    query,
    where,
    collection,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import SidebarDesktop from "../components/SidebarDesktop";
import Fondo from "../Vistas/Fondo";
import Header from "../Vistas/Header";
import Links from "../Vistas/Links";
import Redes from "../Vistas/Redes";
import ViewMockup from "../common/ViewMockup";
import LinkShare from "../common/LinkShare.jsx";
import styled from "styled-components";
import { saveDataToLocal, loadDataFromLocal } from "../utils/LocalStorage";
import { useNavigate } from "react-router-dom";
import { checkUserDocument } from "../../../utils/utils.js";
import constant from "../../../constants/constMap.js";
import { useTheme } from "../hooks/ThemeContext";
import { useLang } from "../hooks/LangContext";
import { Container } from "react-bootstrap";
import Wallet from "./Wallet";
import Account from "./Account";
// import Planes from "./Plans";
import Privacy from "./Privacy";
import Help from "./Help";

function App() {
    const { themeContent, theme } = useTheme();
    const [colors, setColors] = useState(themeContent);
    useEffect(() => {
        setColors(themeContent);
    }, [themeContent, theme]);

    const { langContent, lang } = useLang();
    const [content, setContent] = useState(langContent);
    useEffect(() => {
        setContent(langContent);
    }, [langContent, lang]);

    const [innerWidth, setInnerWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleWidthChange = () => {
            const newWidth = window.innerWidth;
            setInnerWidth(newWidth);
        };

        window.addEventListener("resize", handleWidthChange);

        handleWidthChange();

        return () => {
            window.removeEventListener("resize", handleWidthChange);
        };
    }, []);

    const isMobile = innerWidth < constant.breakpoints.mobile;
    const isTablet =
        innerWidth >= constant.breakpoints.mobile &&
        innerWidth < constant.breakpoints.tablet;

    const Navigate = useNavigate();

    const [vistaActual, setVistaActual] = useState(content.SidebarDesktop.dashboardMenu[0].text);
    const [account, setAccount] = useState(null);
    const [settings, setSettings] = useState(null);

    const [user, setUser] = useState(null);

    useEffect(() => {
        const auth = getAuth();
        const db = getFirestore();
        const currentUser = auth.currentUser;
        const uid = currentUser ? currentUser.uid : "";

        const checkUser = async () => {
            try {
                if (!currentUser) {
                    Navigate("/auth");
                    return;
                } else if (!currentUser.emailVerified) {
                    Navigate("/verificacion");
                    return;
                } else {
                    const documentExists = await checkUserDocument(db, uid);

                    if (!documentExists) {
                        Navigate("/welcome");
                        return;
                    }

                    const q = query(
                        collection(db, constant.collectionUser),
                        where("perfil.uid", "==", uid),
                    );

                    try {
                        const querySnapshot = await getDocs(q);
                        const userData =
                            querySnapshot.docs[0].data().perfil.nombre;
                        setUser(userData);
                    } catch (error) {
                        console.error("Error al verificar el usuario:", error);
                    }
                }
            } catch (error) {
                console.error("Error al verificar el usuario:", error);
            }
        };

        checkUser();
    }, []);

    useEffect(() => {
        let vista = loadDataFromLocal("vista");

        if (!vista) {
            vista = vistaActual;
            saveDataToLocal("vista", vistaActual);
        } else {
            setVistaActual(vista);
        }
    }, []);

    const handleChangeVista = (newVista) => {
        setAccount(null);
        setSettings(null);
        setVistaActual(newVista);
        saveDataToLocal("vista", newVista);
    };

    const handleAccount = (account) => {
        setVistaActual(null);
        setSettings(null);
        setAccount(account);
    };

    const handleSettings = (setting) => {
        setAccount(null);
        setVistaActual(null);
        setSettings(setting);
    };

    const renderDashboard = () => {
        return (
            <Container
                style={{
                    marginTop: isMobile ? "130px" : "0",
                    maxWidth: "1200px",
                    width: "100%",
                    marginLeft: "auto",
                    marginRight: "auto",
                }}
                className="d-flex flex-column align-items-center justify-content-center"
            >
                {(vistaActual === 'Fondo' || vistaActual === 'Background') && (
                    <Fondo isMobile={isMobile} user={user} />
                )}
                {(vistaActual === 'Perfil' || vistaActual === 'Profile') && (
                    <Header isMobile={isMobile} user={user} />
                )}
                {(vistaActual === 'Enlaces' || vistaActual === 'Links') && (
                    <Links isMobile={isMobile} user={user} />
                )}
                {(vistaActual === 'Redes' || vistaActual === 'Social') && (
                    <Redes isMobile={isMobile} user={user} />
                )}
            </Container>
        );
    };

    const renderAccount = () => {
        return (
            <Container
                style={{
                    maxWidth: "1200px",
                    width: "100%",
                    marginLeft: "auto",
                    marginRight: "auto",
                }}
                className="d-flex flex-column align-items-center justify-content-center"
            >
                {(account === 'Mi cuenta' || account === 'My account') && <Account />}
                {(account === 'Billetera' || account === 'Wallet') && <Wallet />}
            </Container>
        );
    };

    const renderSettings = () => {
        return (
            <Container
                style={{
                    marginTop: isMobile ? "130px" : "0",
                    maxWidth: "1200px",
                    width: "100%",
                    marginLeft: "auto",
                    marginRight: "auto",
                }}
                className="d-flex flex-column align-items-center justify-content-center"
            >
                {(settings === 'Privacy Policies' || settings === 'Políticas de privacidad') && <Privacy/> }
                {(settings === 'Ayuda' || settings === 'Help') && <Help/> }
            </Container>
        );
    };

    return (
        <>
            {isMobile && (
                <DivMobile $bg={colors.surface}>
                    <MinWidth>
                        <Navbar
                            user={user}
                            setVista={handleChangeVista}
                            setAccount={handleAccount}
                            setSettings={handleSettings}
                            vista={vistaActual}
                        />
                        {renderDashboard()}
                        {renderAccount()}
                        {renderSettings()}
                        <ViewMockup user={user} isMobile={isMobile} />
                    </MinWidth>
                    <LinkShare user={user} isMobile={isMobile} />
                </DivMobile>
            )}
            {!isMobile && (
                <DivDesktop $bg={colors.surface}>
                    <SidebarDesktop
                        user={user}
                        setVista={handleChangeVista}
                        setAccount={handleAccount}
                        setSettings={handleSettings}
                        vista={vistaActual}
                    />
                    <Dashboard $bg={colors.surface} isTablet={isTablet}>
                        {renderDashboard()}
                        {renderAccount()}
                        {renderSettings()}
                    </Dashboard>
                    <Mockup
                        $bg={colors.surface}
                        $b={colors.surfaceContainerHighest}
                    >
                        <ViewMockup user={user} isMobile={isMobile} />
                        <LinkShare user={user} />
                    </Mockup>
                </DivDesktop>
            )}
        </>
    );
}

const MinWidth = styled.div`
    min-width: 100%;
    margin-bottom: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const DivMobile = styled.div`
    width: 100%;
    height: auto;
    min-height: 100vh;
    background-color: ${(props) => props.$bg};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    position: relative;
    overflow-x: hidden;
`;

const DivDesktop = styled.div`
    width: 100%;
    height: auto;
    min-height: 100vh;
    background-color: ${(props) => props.$bg};
    display: flex;
    overflow-y: auto;
`;

const Dashboard = styled.div`
    width: 100%;
    height: auto;
    max-width: 40%;
    min-width: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-top: 35px;
    overflow: auto;
    background-color: ${(props) => props.$bg};
    height: calc(100vh - 35px);
    overflow-y: auto;
    position: relative;
    z-index: 1;
    left: ${(props) => (props.isTablet ? "100px" : "100px")};

    @media (min-width: 769px) and (max-width: 992px) {
        padding: 0 10px;
    }

    @media (min-width: 993px) and (max-width: 1200px) {
        padding: 0 30px;
    }

    @media (min-width: 1201px) and (max-width: 1600px) {
        padding: 0 50px;
    }

    @media (min-width: 1601px) {
        padding: 0 90px;
    }
`;

const Mockup = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: ${(props) => props.$bg};
    border-left: 1px solid ${(props) => props.$b};

    @media (min-width: 769px) {
        position: relative;
        left: 100px;
        z-index: 0;
    }
`;

export default App;
