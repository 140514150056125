import React, { Fragment } from "react";
import styled from "styled-components";

function Descripcion({ text, styles }) {
    const { descripcion } = styles;
    const colorTexto = descripcion.colorTexto;
    const fontSize = descripcion.fontSize;
    const textTransform = descripcion.textTransform;
    const fontWeigth = descripcion.fontWeigth;

    const lineas = text.split("\n").map((linea, index, array) => (
        <Fragment key={index}>
            {linea}
            {index < array.length - 1 && <br />}
        </Fragment>
    ));

    if (text !== "") {
        return (
            <DescripcionContainer
                textTransform={textTransform}
            >
                <P 
                    fontWeigth={fontWeigth}
                    colorTexto={colorTexto}
                    fontSize={fontSize}
                >
                    {lineas}
                </P>
            </DescripcionContainer>
        );
    } else {
        return null;
    }
}

const DescripcionContainer = styled.div`
    text-transform: ${({ textTransform }) => textTransform};
`;

const P = styled.p`
    font-weight: ${({ fontWeigth }) => fontWeigth};
    color: ${({ colorTexto }) => colorTexto};
    font-size: ${({ fontSize }) => fontSize};
`;

export default Descripcion;
