import React, { useState, useEffect } from "react";
import constantes from "../../../constants/constMap.js";
import defaultDB from "../../../constants/defaultDB.js";
import Loading from "../common/Loading.jsx";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { SvgIcon } from "../common/SvgIcon.jsx";
import { db, storage } from "../../../config/firebase.config.js";
import { getAuth } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { Span, Input, Title } from "./Login";
import styled from "styled-components";
import "firebase/firestore";
import content from "../../../content/es/dashboardContent.json";


const welcome = content.Welcome;

const Welcome = () => {
    const Navigate = useNavigate();

    const [showLoading, setShowLoading] = useState(false);
    const [redirectToDashboard, setRedirectToDashboard] = useState(false);
    const [name, setName] = useState("");
    // const [isNameValid, setIsNameValid] = useState(false);
    // const [isClicked, setIsClicked] = useState(false);

    const auth = getAuth();
    const currentUser = auth.currentUser;
    const email = currentUser?.email;

    useEffect(() => {
        if (!currentUser) {
            Navigate("/auth");
        }
    }, [currentUser]);

    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    const handleContinueClick = async () => {
        setShowLoading(true);
        // setIsClicked(true);

        if (name === "") {
            alert("Por favor ingresa tu nombre");
            // setIsClicked(false);
            setShowLoading(false);
            return;
        }

        try {
            const userUid = currentUser ? currentUser.uid : "";
            const existingUser = await db
                .collection(constantes.collectionUser)
                .doc(name)
                .get();

            if (existingUser.exists) {
                alert("El nombre de usuario ya existe");
                window.location.reload();
            } else {
                const userDocRef = doc(db, constantes.collectionUser, name);
                const userData = defaultDB;

                userData.perfil.nombre = name;
                if (email) {
                    userData.perfil.mail = email;
                } else {
                    userData.perfil.mail = constantes.default.email;
                }
                userData.perfil.uid = userUid;
                userData.perfil.plan = constantes.default.plan;
                userData.perfil.default = constantes.default.logo;
                userData.datos.logo = constantes.default.logo;
                userData.datos.enlacePrincipal = `${window.location.origin}/${name}`;
                userData.datos.enlacePrinCorto = `${window.location.origin}/${name}`;
                userData.datos.enlaceRappid = `${window.location.origin}`;

                await setDoc(userDocRef, userData);

                setShowLoading(false);
                setRedirectToDashboard(true);

                const storageRef = storage.ref();
                const avatarRef = storageRef.child(`${name}/images/Avatar.jpg`);

                const defaultAvatarUrl = `${process.env.PUBLIC_URL}/img/default/default.jpg`;

                const response = await fetch(defaultAvatarUrl);
                const blob = await response.blob();
                await avatarRef.put(blob);
            }
        } catch (error) {
            console.error("Error al cargar datos:", error);
            setShowLoading(false);
        }
    };

    useEffect(() => {
        if (redirectToDashboard) {
            Navigate("/editor");
        }
    }, [redirectToDashboard]);

    return (
        <>
            <div
                style={{ height: "100vh", width: "100vw" }}
                className="d-flex justify-content-center align-items-center flex-column"
            >
                {showLoading ? (
                    <Loading />
                ) : (
                    <>
                        <Link to="/" className="d-flex" aria-label="homepage">
                            <SvgIcon
                                src="logo.svg"
                                width="40px"
                                height="60px"
                            />
                        </Link>
                        <Title style={{ marginTop: "5vh" }}>
                            {welcome.title}
                        </Title>
                        <Span
                            style={{
                                textAlign: "center",
                                display: "flex",
                                justifyContent: "center",
                                marginBottom: "5vh",
                            }}
                        >
                            {welcome.text}
                        </Span>
                        <Input
                            type="text"
                            placeholder="Tu nombre"
                            onChange={handleNameChange}
                        />
                        <img src="./img/svg/welcome.svg" alt="welcome" />
                        <ButtonContinue onClick={handleContinueClick}>
                            {welcome.button}
                        </ButtonContinue>
                    </>
                )}
            </div>
        </>
    );
};

const ButtonContinue = styled.button`
    background-color: #a84ac9;
    color: #fff;
    font-size: 12px;
    padding: 10px 10px;
    border: 1px solid transparent;
    border-radius: 8px;
    max-width: 300px;
    width: 40vw;
    margin-top: 10px;
    cursor: pointer;
    text-align: center;
`;

export default Welcome;
