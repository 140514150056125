import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useTheme } from "../hooks/ThemeContext";
import { useLang } from "../hooks/LangContext";

const AlertModal = ({
    show,
    onDelete,
    onCancel,
    onlyAlert = false,
    title,
    parrafo,
    actionText,
}) => {
    const { themeContent, theme } = useTheme();
    const [colors, setColors] = useState(themeContent);
    useEffect(() => {
        setColors(themeContent);
    }, [themeContent, theme]);

    const { langContent, lang } = useLang();
    const [content, setContent] = useState(langContent);
    useEffect(() => {
        setContent(langContent);
    }, [langContent, lang]);

    const [showDelete, setShowDelete] = useState(show);

    useEffect(() => {
        if (showDelete) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
    }, [showDelete]);

    useEffect(() => {
        setShowDelete(show);
    }, [show]);

    const handleClose = () => {
        onCancel();
        setShowDelete(false);
    };

    const handleDeleteClick = () => {
        onDelete();
        setShowDelete(false);
    };

    const iconWarning = theme === 'light' ? content.Modal.icon.light : content.Modal.icon.dark;

    return (
        <>
            {showDelete ? (
                <Bg>
                    <Delete $bgcolor={colors.surfaceContainer}>
                        <img src={iconWarning} alt="delete" />
                        <Title $col={colors.onSurface}>{title}</Title>
                        <P $col={colors.onSurface}>{parrafo}</P>
                        {onlyAlert ? (
                            <Button $col={colors.primary} onClick={handleClose}>{content.Modal.alert.entendido}</Button>
                        ) : (
                            <ButtonContainer>
                                <Button $col={colors.primary} onClick={handleClose}>{content.Modal.delete.cancelar}</Button>
                                <Button $col={colors.primary} onClick={handleDeleteClick}>
                                    {actionText}
                                </Button>
                            </ButtonContainer>
                        )}
                    </Delete>
                </Bg>
            ) : null}
        </>
    );
};

const Delete = styled.div`
    position: absolute;
    width: 312px;
    height: 240px;
    background-color: ${(props) => props.$bgcolor};
    border-radius: 28px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 2010;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 24px;
    overflow: hidden;
`;

const Bg = styled.div`
    position: fixed;
    min-width: 100%;
    min-height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 2009;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
`;

const Title = styled.h3`
    font-size: 24px;
    font-weight: regular;
    margin: 0;
    margin: 20px 0;
    color: ${props => props.$col};
`;

const P = styled.p`
    font-size: 14px;
    font-weight: regular;
    margin: 0;
    color: ${props => props.$col};
    text-align: center;
`;

const ButtonContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
`;

const Button = styled.button`
    background-color: transparent;
    font-size: 14px;
    font-weight: regular;
    border: none;
    color: ${(props) => props.$col};
    cursor: pointer;
    padding: 0 15px;
`;

export default AlertModal;
