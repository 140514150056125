import React, { useEffect, useState } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import Loading from "../common/Loading";

const AuthRoute = (props) => {
    const { children } = props;
    const auth = getAuth();
    const navigate = useNavigate();
    const [isAuthenticated, setIsAuthenticated] = useState(null);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setIsAuthenticated(true);
            } else {
                setIsAuthenticated(false);
                navigate("/auth");
            }
        });

        return () => unsubscribe();
    }, [auth, navigate]);

    if (isAuthenticated === null) {
        return <Loading />;
    }

    return isAuthenticated ? <>{children}</> : null;
};

export default AuthRoute;
