import React, { useState, useEffect } from "react";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import PageContainer from "../common/Container";
import { SvgIcon } from "../common/SvgIcon";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { Divider } from "antd";
import PasswordInput from "./PasswordInput";

import {
    signInWithGoogle,
    signInWithFacebook,
    signUpWithMail,
    signInWithMail,
} from "./Auth";

import Recuperar from "../common/Recuperar";
import dashboardContent from "../../../content/es/dashboardContent.json";
import Loading from "../common/Loading";

const content = dashboardContent.Login;

const LoginPage = ({ active }) => {
    // const auth = getAuth();
    const navigate = useNavigate();
    const [showLoading, setShowLoading] = useState(false);
    const [authing, setAuthing] = useState(false);
    const [isActive, setIsActive] = useState(active);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [login, setLogin] = useState(true);
    const [rememberMe, setRememberMe] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [colorError, setColorError] = useState("red");
    const [termsCond, setTermsCond] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState("");

    useEffect(() => {
        const rememberedEmail = localStorage.getItem("rememberedEmail");
        const rememberedPassword = localStorage.getItem("rememberedPassword");

        if (rememberedEmail && rememberedPassword) {
            setEmail(rememberedEmail);
            setPassword(rememberedPassword);
            setRememberMe(true);
        }
    }, []);

    const isFormValid = email !== "" && password !== "";

    const handleGoogleLogin = () => {
        setShowLoading(true);
        signInWithGoogle()
            .then((result) => {
                navigate("/editor");
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const handleFacebookLogin = () => {
        setShowLoading(true);
        signInWithFacebook()
            .then((result) => {
                navigate("/editor");
            })
            .catch((error) => {
                console.log("Hubo un error");
            });
    };

    const handleEmailSignUp = (email, password) => {
        setShowLoading(true);

        if (password.length < 8) {
            setColorError("red");
            setErrorMessage(content.errores.longitud);
            return;
        } else {
            signUpWithMail(email, password)
                .then((result) => {
                    navigate("/verificacion");
                })
                .catch((error) => {
                    console.error("Hubo un error al iniciar sesion");
                });
        }
    };

    const handleEmailSignIn = (email, password) => {
        
        if (email === "" || password === "") {
            setColorError("red");
            setErrorMessage(content.errores.vacios);
            return;
        }

        setShowLoading(true);

        signInWithMail(email, password)
            .then((userCredential) => {
                navigate("/editor");
                setErrorMessage("");

                if (rememberMe) {
                    localStorage.setItem("rememberedEmail", email);
                    localStorage.setItem("rememberedPassword", password);
                }
            })
            .catch((error) => {
                console.error("Hubo un error al iniciar sesion");
                setColorError("red");
                setErrorMessage(content.errores.usuario);
                navigate("/editor");
            });
    };

    const handleForgotPassword = (email) => {
        const auth = getAuth();

        sendPasswordResetEmail(auth, email)
            .then(() => {
                console.log("modal algo");
                setIsModalOpen(true);
                setModalMessage(content.errores.recuperar);
            })
            .catch((error) => {
                setIsModalOpen(true);
                setModalMessage(content.errores.errorRec);
            });
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setModalMessage("");
    };

    const handleRegisterClick = () => {
        setIsActive(true);
        setErrorMessage("");
    };

    const handleLoginClick = () => {
        setIsActive(false);
        setErrorMessage("");
    };

    const handleLogRegClick = () => {
        setLogin(!login);
        setErrorMessage("");
    };

    const handleTerms = () => {
        setTermsCond(!termsCond);
    };

    return (
        <>
            {showLoading ? (
                <Loading />
            ) : (
                <>
                    <Body>
                        {termsCond ? (
                            <Container active={true}>
                                <div
                                    style={{
                                        width: "100%",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        marginTop: "2rem",
                                    }}
                                >
                                    <img
                                        src="/img/svg/arrow.svg"
                                        onClick={handleTerms}
                                        style={{ alignSelf: "flex-start" }}
                                        width="40px"
                                        alt="arrow"
                                    />
                                    <Title style={{ fontSize: "1rem" }}>
                                        {content.terminos.titulo}
                                    </Title>
                                    <ul>
                                        {content.terminos.texto.map(
                                            (parrafo, index) => (
                                                <Li key={index}>{parrafo}</Li>
                                            ),
                                        )}
                                    </ul>
                                </div>
                            </Container>
                        ) : null}
                        <Container
                            style={{ display: termsCond ? "none" : "flex" }}
                            active={isActive}
                            id="container"
                        >
                            <FormContainer style={SignUp(isActive)}>
                                <Form>
                                    <Title>{content.registro.registro}</Title>
                                    <SocialIcons>
                                        <SignInButton
                                            onClick={handleGoogleLogin}
                                            disabled={authing}
                                        >
                                            <SocialAnchor
                                                href="#"
                                                className="icon"
                                            >
                                                <img src="/img/svg/google.svg" alt="google"></img>
                                            </SocialAnchor>
                                        </SignInButton>
                                        <SignInButton
                                            onClick={handleFacebookLogin}
                                            disabled={authing}
                                        >
                                            <SocialAnchor
                                                href="#"
                                                className="icon"
                                            >
                                                <img src="/img/svg/face.svg" alt="facebook"></img>
                                            </SocialAnchor>
                                        </SignInButton>
                                    </SocialIcons>
                                    <Span>{content.login.o}</Span>
                                    <Input
                                        type="email"
                                        placeholder={content.login.emailPH}
                                        value={email}
                                        onChange={(e) =>
                                            setEmail(e.target.value)
                                        }
                                    />
                                    <Input
                                        type="password"
                                        placeholder={content.login.passwordPH}
                                        value={password}
                                        onChange={(e) =>
                                            setPassword(e.target.value)
                                        }
                                    />
                                    <Error style={{ color: colorError }}>
                                        {errorMessage}
                                    </Error>
                                    <Span
                                        style={{
                                            width: "100%",
                                            textAlign: "center",
                                            cursor: "pointer",
                                        }}
                                    >
                                        {content.registro.term1}
                                        <span
                                            onClick={handleTerms}
                                            style={{
                                                color: "#A84AC9",
                                                fontWeight: "600",
                                            }}
                                        >
                                            {content.registro.term2}
                                        </span>
                                        {content.registro.term3}
                                    </Span>
                                    <Button
                                        type="button"
                                        onClick={() =>
                                            handleEmailSignUp(email, password)
                                        }
                                    >
                                        {content.registro.registrarme}
                                    </Button>
                                </Form>
                            </FormContainer>
                            <FormContainer style={SignIn(isActive)}>
                                <Form>
                                    <Title>{content.login.redes}</Title>
                                    <SocialIcons>
                                        <SignInButton
                                            onClick={handleGoogleLogin}
                                            disabled={authing}
                                        >
                                            <SocialAnchor
                                                href="#"
                                                className="icon"
                                            >
                                                <img src="/img/svg/google.svg" alt="google"></img>
                                            </SocialAnchor>
                                        </SignInButton>
                                        <SignInButton
                                            onClick={handleFacebookLogin}
                                            disabled={authing}
                                        >
                                            <SocialAnchor
                                                href="#"
                                                className="icon"
                                            >
                                                <img src="/img/svg/face.svg" alt="facebook"></img>
                                            </SocialAnchor>
                                        </SignInButton>
                                    </SocialIcons>
                                    <Span>{content.login.o}</Span>
                                    <Input
                                        type="email"
                                        placeholder={content.login.emailPH}
                                        value={email}
                                        onChange={(e) =>
                                            setEmail(e.target.value)
                                        }
                                    />
                                    <Input
                                        type="password"
                                        placeholder={content.login.passwordPH}
                                        value={password}
                                        onChange={(e) =>
                                            setPassword(e.target.value)
                                        }
                                    />
                                    <Error style={{ color: colorError }}>
                                        {errorMessage}
                                    </Error>
                                    <Anchor
                                        style={{ color: "#A84AC9" }}
                                        onClick={() =>
                                            handleForgotPassword(email)
                                        }
                                    >
                                        {content.login.olvide}
                                    </Anchor>
                                    <Button
                                        type="button"
                                        onClick={() =>
                                            handleEmailSignIn(email, password)
                                        }
                                    >
                                        {content.login.iniciar}
                                    </Button>
                                </Form>
                            </FormContainer>
                            <ToggleContainer active={isActive}>
                                <Toggle active={isActive}>
                                    {/* <TogglePanel> */}
                                    <ToggleLeft active={isActive}>
                                        {" "}
                                        {/*isActive true => mostrar*/}
                                        <Title style={{ color: "white" }}>
                                            {content.registro.loginPreg}
                                        </Title>
                                        <Paragraph style={{ color: "white" }}>
                                            {content.login.directamente}
                                        </Paragraph>
                                        <Button
                                            type="button"
                                            style={ButtonToggle}
                                            onClick={handleLoginClick}
                                        >
                                            {content.login.iniciar}
                                        </Button>
                                    </ToggleLeft>
                                    {/* </TogglePanel> */}
                                    {/* <TogglePanel> */}
                                    <ToggleRight active={isActive}>
                                        <Title style={{ color: "white" }}>
                                            {content.login.titulo}
                                        </Title>
                                        <Paragraph style={{ color: "white" }}>
                                            {content.login.registroPreg}{" "}
                                            {content.login.registroRes}
                                        </Paragraph>
                                        <Button
                                            type="button"
                                            style={ButtonToggle}
                                            onClick={handleRegisterClick}
                                        >
                                            {content.registro.registrarme}
                                        </Button>
                                    </ToggleRight>
                                    {/* </TogglePanel> */}
                                </Toggle>
                            </ToggleContainer>
                        </Container>
                        <Recuperar
                            isOpen={isModalOpen}
                            onClose={closeModal}
                            message={modalMessage}
                        />
                    </Body>
                    <BodyMobile>
                        <PageContainer>
                            <Link to="/" className="d-flex" aria-label="homepage">
                                <SvgIcon
                                    src="logo.svg"
                                    width="40px"
                                    height="60px"
                                />
                            </Link>

                            {termsCond ? (
                                <div
                                    style={{
                                        width: "100%",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        marginTop: "2rem",
                                    }}
                                >
                                    <img
                                        src="/img/svg/arrow.svg"
                                        onClick={handleTerms}
                                        style={{ alignSelf: "flex-start" }}
                                        width="40px"
                                        alt="arrow"
                                    />
                                    <Title style={{ fontSize: "1rem" }}>
                                        {content.terminos.titulo}
                                    </Title>
                                    <ul>
                                        {content.terminos.texto.map(
                                            (parrafo, index) => (
                                                <Li key={index}>{parrafo}</Li>
                                            ),
                                        )}
                                    </ul>
                                </div>
                            ) : null}

                            <div
                                style={{
                                    margin: "20px 0px",
                                    width: "100%",
                                    alignItems: "center",
                                    display: termsCond ? "none" : "block",
                                }}
                            >
                                <Title>
                                    {login
                                        ? "¡Hola de nuevo!"
                                        : "Únete a RAppID Links"}
                                </Title>
                                <Span
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                >
                                    {login
                                        ? "Ingresa a tu RAppID Links"
                                        : "¡Registrate gratis!"}
                                </Span>
                            </div>

                            <Form
                                style={{ display: termsCond ? "none" : "flex" }}
                            >
                                <Input
                                    type="email"
                                    placeholder={content.login.emailPH}
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                <PasswordInput
                                    value={password}
                                    onChange={(e) =>
                                        setPassword(e.target.value)
                                    }
                                />
                                <Error style={{ color: colorError }}>
                                    {errorMessage}
                                </Error>
                                <RemForget>
                                    {login ? (
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                width: "100%",
                                                alignItems: "center",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width: "100%",
                                                    display: "flex",
                                                    justifyContent:
                                                        "space-between",
                                                }}
                                            >
                                                <Recordarme>
                                                    <input
                                                        style={{
                                                            width: "20px",
                                                        }}
                                                        type="checkbox"
                                                        placeholder=""
                                                        onChange={(e) =>
                                                            setRememberMe(
                                                                e.target
                                                                    .checked,
                                                            )
                                                        }
                                                    />
                                                    <Span
                                                        style={{
                                                            margin: "0 1rem 0 5px",
                                                        }}
                                                    >
                                                        {
                                                            content.login
                                                                .recordarme
                                                        }
                                                    </Span>
                                                </Recordarme>
                                                <Anchor
                                                    style={{ color: "#A84AC9" }}
                                                    onClick={() =>
                                                        handleForgotPassword(
                                                            email,
                                                        )
                                                    }
                                                >
                                                    {content.login.olvide}
                                                </Anchor>
                                            </div>
                                            <Button
                                                type="button"
                                                onClick={() =>
                                                    handleEmailSignIn(
                                                        email,
                                                        password,
                                                    )
                                                }
                                                isFormValid={isFormValid}
                                            >
                                                {content.login.iniciar}
                                            </Button>
                                        </div>
                                    ) : (
                                        <>
                                            <div
                                                className="d-block w-100"
                                                style={{
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    textAlign: "center",
                                                }}
                                            >
                                                <Button
                                                    type="button"
                                                    style={{ width: "100%" }}
                                                    onClick={() =>
                                                        handleEmailSignUp(
                                                            email,
                                                            password,
                                                        )
                                                    }
                                                    isFormValid={isFormValid}
                                                >
                                                    {content.registro.iniciar}
                                                </Button>
                                                <br></br>
                                                <br></br>
                                                <Span
                                                    style={{
                                                        width: "100%",
                                                        cursor: "pointer",
                                                    }}
                                                >
                                                    {content.registro.term1}
                                                    <span
                                                        onClick={handleTerms}
                                                        style={{
                                                            color: "#A84AC9",
                                                            fontWeight: "600",
                                                        }}
                                                    >
                                                        {content.registro.term2}
                                                    </span>
                                                    {content.registro.term3}
                                                </Span>
                                            </div>
                                        </>
                                    )}
                                </RemForget>
                                <div className="d-flex w-100 my-3 justify-content-center">
                                    <Divider style={DividerStyle} />
                                    <span style={{ color: "#CECECE" }}>o</span>
                                    <Divider style={DividerStyle} />
                                </div>
                                <BotonGoogle
                                    type="button"
                                    onClick={handleGoogleLogin}
                                >
                                    <img
                                        style={{ marginRight: "1rem" }}
                                        src="/img/svg/google.svg"
                                        alt="google"
                                    ></img>
                                    {content.login.google}
                                </BotonGoogle>
                                <BotonGoogle
                                    type="button"
                                    onClick={handleFacebookLogin}
                                >
                                    <img
                                        style={{ marginRight: "1rem" }}
                                        src="/img/svg/face.svg"
                                        alt="facebook"
                                    ></img>
                                    {content.login.facebook}
                                </BotonGoogle>
                                <Anchor
                                    style={{ color: "#A84AC9" }}
                                    onClick={handleLogRegClick}
                                >
                                    <Span
                                        style={{
                                            color: "#6B6B6B",
                                            marginRight: "5px",
                                        }}
                                    >
                                        {login
                                            ? content.login.registroPreg
                                            : content.registro.loginPreg}
                                    </Span>
                                    {login
                                        ? content.login.registroRes
                                        : content.registro.loginRes}
                                </Anchor>
                            </Form>
                            <Recuperar
                                isOpen={isModalOpen}
                                onClose={closeModal}
                                message={modalMessage}
                            />
                        </PageContainer>
                    </BodyMobile>
                </>
            )}
        </>
    );
};

const Body = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Montserrat", sans-serif;
    background-color: #c9d6ff;
    background: linear-gradient(to right, #e2e2e2, #c9d6ff);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
    width: 100vw;

    @media (max-width: 768px) {
        display: none;
    }
`;

export const Title = styled.h2`
    text-align: center;
    font-size: 30px;
    font-weight: 600;
    color: #101010;
`;

const Container = styled.div`
    background-color: #fff;
    border-radius: 30px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.35);
    position: relative;
    overflow: hidden;
    width: 768px;
    max-width: 100%;
    min-height: 480px;
`;

const Paragraph = styled.p`
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.3px;
    margin: 20px 0;
`;

export const Span = styled.span`
    font-size: 12px;
`;

export const Anchor = styled.a`
    color: #333;
    font-size: 13px;
    text-decoration: none;
    margin: 15px 0 10px;
    cursor: pointer;

    @media (max-width: 300px) {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
`;

const Button = styled.button`
    background-color: #5a82c2;
    color: #fff;
    font-size: 12px;
    padding: 10px 45px;
    border: 1px solid transparent;
    border-radius: 8px;
    font-weight: 600;
    letter-spacing: 0.5px;
    margin-top: 10px;
    cursor: pointer;

    @media (max-width: 768px) {
        width: 90vw;
        border-radius: 50px;
        background-color: #a84ac9;
        color: #fff;
        max-width: 400px;

        ${(props) =>
            !props.isFormValid &&
            `
      background-color: rgba(29,27,32,0.12);
      color: rgba(29,27,32,0.40);
    `}
    }
`;

const Form = styled.form`
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 40px;
    height: 100%;

    @media (max-width: 768px) {
        height: 80%;
    }
`;

export const Input = styled.input`
    background-color: #eee;
    border: none;
    margin: 8px 0;
    padding: 10px 15px;
    font-size: 13px;
    border-radius: 8px;
    width: 100%;
    outline: none;
    max-width: 300px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;

    @media (max-width: 768px) {
        width: 90vw;
        max-width: 400px;
        &:hover {
            border: 1px solid #101010;
        }
    }
`;

const FormContainer = styled.div`
    position: absolute;
    top: 0;
    height: 100%;
    transition: all 0.6s ease-in-out;
`;

const SignIn = (active) => {
    return {
        left: 0,
        width: "50%",
        zIndex: 2,
        transform: active ? "translateX(100%)" : "translateX(0)",
    };
};

const SignUp = (active) => {
    return {
        left: 0,
        width: "50%",
        opacity: active ? 1 : 0,
        zIndex: active ? 5 : 0,
        transform: active ? "translateX(100%)" : "translateX(0)",
    };
};

const SocialIcons = styled.div`
    margin: 20px 0;
`;

const SocialAnchor = styled.a`
    border: 1px solid #ccc;
    border-radius: 20%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 0 3px;
    width: 40px;
    height: 40px;
`;

// A continuación, se definen los estilos para sign-in, sign-up y los paneles toggle.
// Debes completar estos estilos basándote en tu CSS original.

const ToggleContainer = styled.div`
    position: absolute;
    top: 0;
    left: 50%;
    width: 50%;
    height: 100%;
    overflow: hidden;
    transition: all 0.6s ease-in-out;
    border-radius: 50px 0 0 50px;
    z-index: 1000;

    ${(props) =>
        props.active &&
        css`
            transform: translateX(-100%);
            border-radius: 0 50px 50px 0;
        `}
`;

const Toggle = styled.div`
    height: 100%;
    background: linear-gradient(to right, #a84ac9, #a84ac9);
    color: #fff;
    position: relative;
    left: -100%;
    height: 100%;
    width: 200%;
    transform: translateX(0);
    transition: all 0.6s ease-in-out;

    ${(props) =>
        props.active &&
        css`
            transform: translateX(50%);
        `}
`;

const TogglePanel = styled.div`
    position: absolute;
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 30px;
    text-align: center;
    top: 0;
    transform: translateX(0);
    transition: all 0.6s ease-in-out;
    z-index: 0;
`;

const ToggleLeft = styled(TogglePanel)`
    transform: translateX(-200%);

    ${(props) =>
        props.active &&
        css`
            transform: translateX(0%);
        `}
`;

const ToggleRight = styled(TogglePanel)`
    right:;
    transform: translateX(100%);

    ${(props) =>
        props.active &&
        css`
            transform: translateX(300%);
        `}
`;

const ButtonToggle = {
    backgroundColor: "#A84AC9",
    border: "1px solid #fff",
};

// Screen Size < 768px

const BodyMobile = styled(Body)`
    display: none;
    background: #fff;
    height: 100vh;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    margin: 5vh 2vh;

    @media (max-width: 768px) {
        display: flex;
        max-width: 400px;
        align-items: center;
        margin: 20px auto;
    }
`;

const DividerStyle = {
    margin: "0.7rem 0.2rem",
    color: "#CECECE",
    borderColor: "#CECECE",
    borderStyle: "solid",
    borderWidth: "1px",
    width: "50%",
    height: "1px",
    minWidth: "50%",
};

const RemForget = styled.div`
    width: 90vw;
    max-width: 400px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 300px) {
        display: block;
    }
`;

const Recordarme = styled.div`
    display: flex;
    align-items: center;
    margin-top: 8px;
    justify-content: center;
`;

const BotonGoogle = styled(Button)`
    @media (max-width: 768px) {
        background-color: #fff;
        color: #101010;
        border: 1px solid #ccc;
        border-radius: 50px;
        width: 90vw;
        max-width: 400px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        text-transform: none;
        padding: 8px 0;
    }
`;

const SignInButton = styled(Button)`
    background-color: transparent;
    border: none;
    padding: 0;
    texttransform: none;
    margin: 0;
`;

const Error = styled.div`
    font-weight: 600;
    font-size: 12px;
    margin: 5px 0;
    width: 90vw;
    height: 20px;
    display: flex;
    max-width: 400px;
    text-align: center;
    align-items: center;
    justify-content: center;
`;

const Li = styled.li`
    list-style: none;
    font-size: 12px;
    margin: 5px 0;
    width: 90vw;
    height: auto;
    display: flex;
    max-width: 350px;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 10px 0;

    @media (min-width: 768px) {
        max-width: 1200px;
    }
`;

export default LoginPage;
