// export const GetUser = async () => {
//     const userSnapshot = await db.collection('usuarios').doc('test').get()
//     if (userSnapshot.exists) {
//         return userSnapshot.data()
//     } else {
//         return null
//     }
// }

export const createBackground = ({ type, image, color1, color2 }) => {
    let background = null;
    if (type === "image") {
        background = image;
    } else if (type === "solido") {
        background = `linear-gradient(to bottom, ${color1}, ${color1})`;
    } else if (type === "horizontal") {
        background = `linear-gradient(to bottom, ${color1}, ${color2})`;
    } else if (type === "vertical") {
        background = `linear-gradient(to right, ${color1}, ${color2})`;
    }
    return background;
};

// export const createRedes = (socialData) => {
//     let redes = []
//     socialData.links.forEach((red) => {
//         redes.push({
//             "icon": `${red.icon}`,
//             "iconImg": `${red.iconImg}`,
//             "nombre": `${red.title}`,
//             "url": `${red.url}`,
//             "render": `si`
//         })
//     })
//     return redes
// }

// export const createLinks = (linksData) => {
//     let enlaces = []
//     linksData.links.forEach((link) => {
//         enlaces.push({
//             "icon": `${link.icon}`,
//             "iconImg": `${link.iconImg}`,
//             "title": `${link.title}`,
//             "url": `${link.url}`,
//             "show": `si`
//         })
//     })
//     return enlaces
// }
