import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useLang } from "../hooks/LangContext";

const Search = ({ handleChange, toggleBottomSheet }) => {

    const { langContent, lang } = useLang();
    const [content, setContent] = useState(langContent);
    useEffect(() => {
        setContent(langContent);
    }, [langContent, lang]);

    const searchSocial = useRef(null);

    const handleFocus = () => {
        searchSocial.current.focus();
    };

    useEffect(() => {
        if (toggleBottomSheet) {
            handleFocus();
        } else {
            searchSocial.current.value = "";
        }
    }, [toggleBottomSheet]);

    return (
        <SearchBar>
            <SearchIcon src="./img/svg/search.svg" alt="Buscar" />
            <SearchInput
                type="text"
                placeholder={content.BottomSheet.search}
                onChange={handleChange}
                ref={searchSocial}
            />
        </SearchBar>
    );
};

const SearchBar = styled.div`
    display: flex;
    align-items: center;
    background-color: #e6e6e6;
    border-radius: 50px;
    padding: 5px 16px;
    margin-bottom: 10px;
    width: 100%;
    height: 56px;
`;

const SearchInput = styled.input`
    border: none;
    background: none;
    outline: none;
    flex: 1;
    padding-left: 10px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
`;

const SearchIcon = styled.img`
    width: 24px;
    height: 24px;
    margin-right: 5px;
`;

export default Search;
