import React, { useState, useEffect } from "react";
// import Content from "../../../content/es/dashboardContent.json";
import Title from "../common/TitleSection";
import Subtitle from "../common/SubtitleSection";
import Section from "../common/Section";
import LogoEdit from "../common/EditLogo";
import { useLang } from "../hooks/LangContext";

const ProfileSection = ({ user }) => {
    
    const { langContent, lang } = useLang();
    const [content, setContent] = useState(langContent);
    useEffect(() => {
        setContent(langContent);
    }, [langContent, lang]);

    return (
        <Section>
            <Title title={content.Perfil.title} />
            <Subtitle title={content.Perfil.subtitle} />
            <LogoEdit text={content.Perfil.logoText} user={user} />
        </Section>
    );
};

export default ProfileSection;
