import React, { createContext, useState, useContext, useEffect } from "react";
import contentES from "../../../content/es/dashboardContent.json"
import contentEN from "../../../content/en/dashboardContent.json"

const LangContext = createContext();

export const LangProvider = ({ children }) => {
    const [lang, setLang] = useState(localStorage.getItem('lang') || "es");
    const [langContent, setLangContent] = useState(contentES);

    useEffect(() => {
        setLangContent(lang === "es" ? contentES : contentEN);
    }, [lang]);

    const toggleLang = () => {
        setLang((prevLang) => (prevLang === "es" ? "en" : "es"));
        localStorage.setItem('lang', lang === "es" ? "en" : "es");
    }

    return (
        <LangContext.Provider value={{ lang, toggleLang, langContent }}>
            {children}
        </LangContext.Provider>
    );
}

export const useLang = () => useContext(LangContext);