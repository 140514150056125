import {
    faBars,
    faCoffee,
    faUtensils,
    faShoppingCart,
    faDownload,
    faGlobe,
    faDesktop,
    faLink,
    faMailBulk,
    faMailForward,
} from "@fortawesome/free-solid-svg-icons";

import {
    faFacebook,
    faInstagram,
    faTwitter,
    faYoutube,
    faWhatsapp,
    faLinkedin,
    faPinterest,
    faSnapchat,
    faTiktok,
    faTelegram,
    faSpotify,
    faSoundcloud,
    faGithub,
    faTwitch,
    faBehance,
    faDribbble,
    faDiscord,
    faReddit,
    faPinterestP,
    faSlack,
    faThreads,
    faXTwitter,
} from "@fortawesome/free-brands-svg-icons";

const iconMap = {
    faGlobe: faGlobe,
    faDesktop: faDesktop,
    faLink: faLink,
    faFacebook: faFacebook,
    faInstagram: faInstagram,
    faTwitter: faTwitter,
    faBars: faBars,
    faCoffee: faCoffee,
    faUtensils: faUtensils,
    faShoppingCart: faShoppingCart,
    faYoutube: faYoutube,
    faDownload: faDownload,
    faWhatsapp: faWhatsapp,
    faLinkedin: faLinkedin,
    faPinterest: faPinterest,
    faSnapchat: faSnapchat,
    faTiktok: faTiktok,
    faTelegram: faTelegram,
    faSpotify: faSpotify,
    faSoundcloud: faSoundcloud,
    faGithub: faGithub,
    faTwitch: faTwitch,
    faBehance: faBehance,
    faDribbble: faDribbble,
    faDiscord: faDiscord,
    faReddit: faReddit,
    faEmail: faMailBulk,
    faMailForward: faMailForward,
    faPinterestP: faPinterestP,
    faSlack: faSlack,
    faThreads: faThreads,
    faTwitter: faXTwitter,
};

export default iconMap;
