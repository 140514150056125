import React, { useState, useEffect } from "react";
import { db } from "../../../config/firebase.config.js";
import Content from "../../../content/es/dashboardContent.json";
import constant from "../../../constants/constMap.js";
import Title from "../common/TitleSection";
import Subtitle from "../common/SubtitleSection";
import UsableButton from "../common/UsableButton";
// import Separator from "../common/Separator";
import styled from "styled-components";
import Section from "../common/Section";
import ColorPicker from "../common/ColorPicker";
import AddedLink from "../common/AddedLink";
import AlertModal from "../common/AlertModal.jsx";
import BottomSheets from "../common/BottomSheet.jsx";
import { TitleSubtitleSkeleton, CardSkeleton } from "../common/Skeleton";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { v4 as uuidv4 } from "uuid";
import { socialLinkByUsername } from "../common/utils/utils.js";
import { useTheme } from "../hooks/ThemeContext";
import { Container } from "react-bootstrap";
import { useDefaultDB } from "../hooks/RealTimeContext.jsx";
import { useLang } from "../hooks/LangContext";


const Social = ({ user }) => {
    const { themeContent, theme } = useTheme();
    const [colors, setColors] = useState(themeContent);
    useEffect(() => {
        setColors(themeContent);
    }, [themeContent, theme]);
    
    const { langContent, lang } = useLang();
    const [content, setContent] = useState(langContent);
    useEffect(() => {
        setContent(langContent);
    }, [langContent, lang]);
    
    const ContentSocial = content.Social;

    const [redesLoaded, setRedesLoaded] = useState(false);

    const collection = constant.collectionUser;

    const [userData, setUserData] = useState(null);

    const [socialsData, setSocialsData] = useState({});
    const [socials, setSocials] = useState([]);

    const [showDelete, setShowDelete] = useState(false);
    const [showError, setShowError] = useState(false);

    const [deleteIndex, setDeleteIndex] = useState(null);

    const [showAddSocial, setShowAddSocial] = useState(false);

    const [selectedInput, setSelectedInput] = useState(
        socialsData.position === "Arriba" ? 0 : 1,
    );

    const [show, setShow] = useState(false);

    const { setDB } = useDefaultDB();

    const defaultSocial = {
        icon: "faWhatsapp",
        iconImg: "",
        nombre: "Whatsapp",
        url: "redsocial.com",
        render: "si",
        user: "@redsocial",
    };

    useEffect(() => {
        const fetchData = async () => {
            if (user) {
                try {
                    const docRef = db.collection(collection).doc(user);
                    const docSnapshot = await docRef.get();
                    if (docSnapshot.exists) {
                        const userData = docSnapshot.data();
                        setUserData(userData);

                        const social = {
                            redes: userData.datos.redes,
                            container: {
                                colorHover: userData.styles.redes.colorHover,
                                colorIconSVG:
                                    userData.styles.redes.colorIconSVG,
                                colorPrincipal:
                                    userData.styles.redes.colorPrincipal,
                                margin: userData.styles.redes.margin,
                                padding: userData.styles.redes.padding,
                                position:
                                    userData.styles.redes.position || "Arriba",
                            },
                        };

                        setSelectedInput(
                            social.container.position === "Arriba" ? 0 : 1,
                        );
                        setSocialsData(social);
                        setSocials(social.redes);
                        setRedesLoaded(true);
                    } else {
                        console.log("No such document!");
                    }
                } catch (error) {
                    console.log("Error getting document:", error);
                }
            }
        };

        fetchData();
    }, [user]);

    useEffect(() => {
        if (userData !== null && socialsData && socialsData.container) {
            const userRef = db.collection(collection).doc(user);
            const updatedUserData = { ...userData };

            if (socials) {
                updatedUserData.datos.redes = socials;
            }

            if (socialsData.container) {
                updatedUserData.styles.redes = socialsData.container;
            }

            setDB((prevDB) => {
                return (
                    prevDB && {
                        ...prevDB,
                        styles: {
                            ...prevDB.styles,
                            redes: socialsData.container,
                        },
                        datos: {
                            ...prevDB.datos,
                            redes: socials,
                        },
                    }
                );
            });

            // userRef.set(updatedUserData, { merge: true });
        }
    }, [socials, socialsData, userData, selectedInput]);

    // Add Social

    const [toggleBottomSheet, setToggleBottomSheet] = useState(false);

    const handleBottomSheet = () => {
        setToggleBottomSheet(!toggleBottomSheet);
        setShow(!show);
    };

    // Add Social

    const handleClickSocial = (app) => {
        let formattedTitle;

        if (app.title === "X") {
            formattedTitle = "Twitter";
        } else {
            formattedTitle =
                app.title.charAt(0).toUpperCase() +
                app.title.slice(1).toLowerCase();
        }

        const newS = { ...defaultSocial };

        if (app.title === "Discord" || app.title === "Slack") {
            newS.nombre = `${app.title.toLowerCase()}`;
            newS.icon = `fa${formattedTitle}`;
            newS.user = socialLinkByUsername("", app.title.toLowerCase());
            newS.url = socialLinkByUsername("", app.title.toLowerCase());
            newS.Img = app.img;
            newS.ImgDark = app.imgDark;
            newS.id = uuidv4();
        } else if (app.title === "WhatsApp") {
            newS.nombre = `${app.title.toLowerCase()}`;
            newS.icon = `fa${formattedTitle}`;
            newS.user = `+11111111111`;
            newS.url = socialLinkByUsername("", app.title.toLowerCase());
            newS.Img = app.img;
            newS.ImgDark = app.imgDark;
            newS.id = uuidv4();
        } else {
            newS.nombre = `${app.title.toLowerCase()}`;
            newS.user = `@username`;
            newS.url = socialLinkByUsername("", app.title.toLowerCase());
            newS.icon = `fa${formattedTitle}`;
            newS.Img = app.img;
            newS.ImgDark = app.imgDark;
            newS.id = uuidv4();
        }

        setSocials([...socials, newS]);
        setSocialsData({ ...socialsData, redes: [...socials, newS] });
        setShowAddSocial(false);
    };

    // Delete Social

    const handleDelete = (index) => {
        setShowDelete(true);
        setDeleteIndex(index);
    };

    const handleRemove = () => {
        const newSocial = [...socials];
        newSocial.splice(deleteIndex, 1);
        setSocials(newSocial);
        setSocialsData((prevData) => ({
            ...prevData,
            redes: newSocial,
        }));

        setDeleteIndex(null);
        setShowDelete(false);
    };

    const handleCancelDelete = () => {
        setShowDelete(false);
    };

    // Cambios

    const hanldeSocialChange = (newSocial, index) => {
        const newData = [...socials];
        try {
            newData[index].url = socialLinkByUsername(
                newSocial.user.slice(1),
                newSocial.nombre,
            );
            newData[index].user = newSocial.user;
            newData[index].render = newSocial.show === true ? "si" : "no";

            setSocials(newData);
            setSocialsData((prevData) => ({
                ...prevData,
                redes: newData,
            }));
        } catch (error) {
            console.log(error);
        }
    };

    // Position Redes

    const handlePositionChange = (index) => {
        setSelectedInput(index);
        setSocialsData((prevData) => ({
            ...prevData,
            container: {
                ...prevData.container,
                position: index === 0 ? "Arriba" : "Abajo",
            },
        }));
    };

    // Drag and drop

    const handleDragEnd = (result) => {
        if (
            !result.destination ||
            result.destination.index === result.source.index
        ) {
            return;
        }
        const reorderedSocial = Array.from(socials);
        const [removed] = reorderedSocial.splice(result.source.index, 1);
        reorderedSocial.splice(result.destination.index, 0, removed);
        setSocials(reorderedSocial);
    };

    // Color Icon

    const handleColorChange = (color, index) => {
        setSocialsData((prevData) => ({
            ...prevData,
            container: {
                ...prevData.container,
                colorIconSVG: color,
            },
        }));
    };

    return (
        <>
            <Container>
                {redesLoaded && (
                    <>
                        <AlertModal
                            show={showError}
                            onlyAlert={true}
                            title={content.Modal.alert.title}
                            parrafo={content.Modal.alert.parrafo}
                            onCancel={() => setShowError(false)}
                        />
                        <AlertModal
                            show={showDelete}
                            onCancel={handleCancelDelete}
                            onDelete={() => handleRemove()}
                        />
                        <Section>
                            <Title title={ContentSocial.title} />
                            <Subtitle title={ContentSocial.subtitle} />
                            {!showAddSocial && (
                                <UsableButton
                                    imgSrc={ContentSocial.imgPlus}
                                    imgSrcDark={ContentSocial.imgPlusDark}
                                    btnContent={ContentSocial.btn}
                                    onClick={handleBottomSheet}
                                />
                            )}
                            {/* {showAddSocial && (
                            <AddLink
                                onAddLink={(newSocial) =>
                                    handleAddSocial(newSocial)
                                }
                                onClose={handleClose}
                                type="social"
                            />
                        )} */}
                            <DragDropContext onDragEnd={handleDragEnd}>
                                <Droppable droppableId="droppableSocial">
                                    {(provided) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.droppableProps}
                                            style={{ width: "100%" }}
                                        >
                                            {socials.map((social, index) => (
                                                <Draggable
                                                    key={social.id}
                                                    draggableId={social.id}
                                                    index={index}
                                                >
                                                    {(provided) => (
                                                        <div
                                                            ref={
                                                                provided.innerRef
                                                            }
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            <AddedLink
                                                                key={index}
                                                                link={
                                                                    social.url
                                                                }
                                                                showLink={
                                                                    social.render ===
                                                                    "si"
                                                                        ? true
                                                                        : false
                                                                }
                                                                remove={() =>
                                                                    handleDelete(
                                                                        index,
                                                                    )
                                                                }
                                                                onSocialChange={(
                                                                    newSocial,
                                                                ) => {
                                                                    hanldeSocialChange(
                                                                        newSocial,
                                                                        index,
                                                                    );
                                                                }}
                                                                socials={social}
                                                                add="social"
                                                            />
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </Section>
                        {/* <Separator /> */}
                        <Section>
                            <Title title={ContentSocial.sectionEditTitle} />
                            {ContentSocial.editsColor.map((edit, index) => (
                                <ColorPicker
                                    defaultColor={
                                        socialsData.container.colorIconSVG
                                    }
                                    key={index}
                                    text={edit}
                                    onColorChange={(color) =>
                                        handleColorChange(color, index)
                                    }
                                />
                            ))}
                        </Section>
                        {/* <Separator /> */}
                        <Section>
                            <Title title={ContentSocial.titleSection} />
                            <Subtitle title={ContentSocial.subtitleSection} />
                            <Form>
                                {ContentSocial.inputs.map((input, index) => (
                                    <ContFlex key={index}>
                                        <Input
                                            type="radio"
                                            value={input}
                                            id={input}
                                            checked={selectedInput === index}
                                            onChange={() =>
                                                handlePositionChange(index)
                                            }
                                        />
                                        <Label
                                            htmlFor={input}
                                            $col={colors.onSurface}
                                        >
                                            {input}
                                        </Label>
                                    </ContFlex>
                                ))}
                            </Form>
                        </Section>
                    </>
                )}
                {!redesLoaded && (
                    <div className="d-flex flex-column align-items-center">
                        <TitleSubtitleSkeleton />
                        <br />
                        <CardSkeleton count={1} height={40} width={350} />
                        <br />
                        <CardSkeleton count={1} height={90} width={350} />
                        <br />
                        <CardSkeleton count={1} height={90} width={350} />
                        <br />
                        <TitleSubtitleSkeleton />
                        <br />
                        <CardSkeleton count={1} height={40} width={350} />
                        <br />
                        <TitleSubtitleSkeleton />
                        <br />
                    </div>
                )}
            </Container>
            <BottomSheets
                pictureHandle={false}
                heigth="auto"
                toggleBottomSheet={toggleBottomSheet}
                onClose={() => handleBottomSheet(false)}
                onClickSocial={(app) => handleClickSocial(app)}
                show={show}
            />
        </>
    );
};

const Form = styled.form`
    display: flex;
    width: 90%;
    flex-direction: column;
`;

const ContFlex = styled.div``;

const Input = styled.input`
    width: 15px;
    aspect-ratio: 1/1;
    margin-right: 10px;

    &:checked {
        accent-color: #a84ac9;
    }
`;

const Label = styled.label`
    font-size: 1rem;
    color: ${(props) => props.$col};
`;

export default Social;
