import { db } from "../../../config/firebase.config.js";
import React, { useState, useEffect } from "react";
import Temas from "../components/Temas";
import Perzonaliza from "../components/Perzonaliza";
// import Separator from "../common/Separator";
import constant from "../../../constants/constMap.js";
import { createBackground } from "../utils/DataBaseSave";
import { CardMockupSkeleton, TitleSubtitleSkeleton } from "../common/Skeleton";
import { Container } from "react-bootstrap";
import { useDefaultDB } from "../hooks/RealTimeContext.jsx";

const Fondo = ({ user, isMobile }) => {
    const [fondoLoaded, setFondoLoaded] = useState(false);

    const collection = constant.collectionUser;

    const [userData, setUserData] = useState(null);
    const [fondoData, setFondoData] = useState();

    const [personalize, setPersonalize] = useState(true);
    const [theme, setTheme] = useState(false);

    const {setDB} = useDefaultDB();

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (user) {
                    const docRef = db.collection(collection).doc(user);
                    const docSnapshot = await docRef.get();
                    if (docSnapshot.exists) {
                        const userData = docSnapshot.data();
                        setUserData(userData);
                        setFondoData(userData.styles.general.fondoData);
                        setFondoLoaded(true);
                    } else {
                        console.error("Fondo: No such document Fondo!");
                    }
                }
            } catch (error) {
                console.error(
                    "Fondo: Error al obtener la imagen de Firestore: ",
                    error,
                );
            }
        };

        fetchData();
    }, [user]);

    useEffect(() => {
        if (userData) {
            const userRef = db.collection(collection).doc(user);
            const updatedUserData = { ...userData };

            const background = createBackground({
                type: fondoData.type,
                image: fondoData.image,
                color1: fondoData.color1,
                color2: fondoData.color2,
            });

            updatedUserData.styles.general.fondoData = fondoData;
            updatedUserData.styles.general.backgroundColor = background;

            // userRef.set(updatedUserData, { merge: true });
        }
    }, [fondoData, userData]);

    const handleChangeColor1 = (newColor) => {
        setFondoData((prevData) => ({
            ...prevData,
            color1: newColor,
            image: null,
        }));

        const background = createBackground({
            type: fondoData.type,
            image: fondoData.image,
            color1: newColor,
            color2: fondoData.color2,
        });

        setDB((prevDB) => ({
            ...prevDB,
            styles: {
                ...prevDB.styles,
                general: {
                    ...prevDB.styles.general,
                    fondoData: {
                        ...prevDB.styles.general.fondoData,
                        color1: newColor,
                        image: null,
                    },
                    backgroundColor: background,
                },
            },
        }));

    };

    const handleChangeColor2 = (newColor) => {
        setFondoData((prevData) => ({
            ...prevData,
            color2: newColor,
            image: null,
        }));

        const background = createBackground({
            type: fondoData.type,
            image: fondoData.image,
            color1: fondoData.color1,
            color2: newColor,
        });

        setDB((prevDB) => ({
            ...prevDB,
            styles: {
                ...prevDB.styles,
                general: {
                    ...prevDB.styles.general,
                    fondoData: {
                        ...prevDB.styles.general.fondoData,
                        color2: newColor,
                        image: null,
                    },
                    backgroundColor: background,
                },
            },
        }));

    };

    const handleType = (newType) => {
        setFondoData((prevData) => ({
            ...prevData,
            type: newType,
            image: null,
        }));
        setPersonalize(true);
        setTheme(false);

        const background = createBackground({
            type: newType,
            image: fondoData.image,
            color1: fondoData.color1,
            color2: fondoData.color2,
        });

        setDB((prevDB) => ({
            ...prevDB,
            styles: {
                ...prevDB.styles,
                general: {
                    ...prevDB.styles.general,
                    fondoData: {
                        ...prevDB.styles.general.fondoData,
                        type: newType,
                        image: null,
                    },
                    backgroundColor: background,
                },
            },
        }));

    }; 

    const handleImage = (item) => {
        setPersonalize(false);
        setTheme(true);
        setFondoData((prevData) => ({
            ...prevData,
            type: "image",
        }));

        setDB((prevDB) => ({
            ...prevDB,
            styles: {
                ...prevDB.styles,
                general: {
                    ...prevDB.styles.general,
                    fondoData: {
                        ...prevDB.styles.general.fondoData,
                        type: "image",
                        image: item,
                    },
                    backgroundColor: `url(${item})`,
                },
            },
        }));

    };

    const dekstopContainerStyle = {
        width: "100%",
    };

    return (
        <Container style={!isMobile ? dekstopContainerStyle : null}>
            {fondoLoaded && (
                <>
                    <Temas
                        tema={theme}
                        user={user}
                        onChangeImage={handleImage}
                        isMobile={isMobile}
                    />
                    {/* <Separator /> */}
                    <Perzonaliza
                        personalize={personalize}
                        user={user}
                        defaultCol1={fondoData.color1}
                        defaultCol2={fondoData.color2}
                        onChangeColor1={handleChangeColor1}
                        onChangeColor2={handleChangeColor2}
                        onChangeType={handleType}
                        isMobile={isMobile}
                    />
                </>
            )}
            {!fondoLoaded && (
                <div className="d-flex flex-column align-items-center">
                    <TitleSubtitleSkeleton />
                    <CardMockupSkeleton count={4} />
                    <br />
                    <br />
                    <TitleSubtitleSkeleton />
                    <CardMockupSkeleton count={4} />
                </div>
            )}
        </Container>
    );
};

export default Fondo;
