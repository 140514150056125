import React, { useState, useEffect, useRef } from "react";
import Avatar from "../common/Avatar";
import Logo from "../common/Logo";
// import PlanBanner from "../common/PlanBanner";
import Sidebar from "./Sidebar";
import { Container, Navbar, Nav } from "react-bootstrap";
import Content from "../../../content/es/dashboardContent.json";
import styled, { keyframes } from "styled-components";
import { saveDataToLocal, loadDataFromLocal } from "../utils/LocalStorage";
import { useTheme } from "../hooks/ThemeContext";
import SidebarDesktop from "./SidebarDesktop";
const pages = Content.Header.pages;

const NavbarComponent = ({ setVista, user, setAccount, setSettings }) => {
    const { themeContent, theme } = useTheme();
    const [colors, setColors] = useState(themeContent);
    useEffect(() => {
        setColors(themeContent);
    }, [themeContent, theme]);

    const index0 = loadDataFromLocal("startIndex");
    let startIndex = 0;

    if (index0) {
        startIndex = index0;
    } else {
        saveDataToLocal("startIndex", 0);
    }

    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [active, setActive] = useState(false);
    const [activeIndex, setActiveIndex] = useState(startIndex);

    const handleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const handleClick = (index) => {
        setActive(!active);
        setActiveIndex(index);
        saveDataToLocal("startIndex", index);
    };

    const FirstNavLink = {
        padding: "0.5rem 1.5rem 0 1rem",
        border: `1px solid ${colors.onSurface}`,
        color: colors.onSurface,
        borderRadius: "50px 0 0 50px",
        boxShadow: "0px 4px 5px 0px rgba(0, 0, 0, 0.25)",
    };

    const NavLinkLast = {
        padding: "0.5rem 1.5rem 0 1rem",
        color: colors.onSurface,
        width: "25%",
        textAlign: "center",
        border: `1px solid ${colors.onSurface}`,
        borderRadius: "0 50px 50px 0",
        boxShadow: "0px 4px 5px 0px rgba(0, 0, 0, 0.25)",
    };

    const NavLinkStyle = {
        border: `1px solid ${colors.onSurface}`,
        borderRight: "none",
        padding: "0.5rem 0.2rem",
        color: colors.onSurface,
        width: "25%",
        textAlign: "center",
        boxShadow: "0px 4px 5px 0px rgba(0, 0, 0, 0.25)",
    };

    const getStyle = (index) => {
        const isFirst = index === 0;
        const isLast = index === pages.length - 1;
        const isActive = index === activeIndex;

        const bgActive = colors.surfaceContainerLow;
        const bgInactive = colors.surfaceContainer;

        let linkStyle =
            isActive && isLast
                ? {
                      ...NavLinkLast,
                      backgroundColor: bgActive,
                  }
                : isActive && isFirst
                  ? {
                        ...FirstNavLink,
                        ...NavLinkStyle,
                        backgroundColor: bgActive,
                    }
                  : isActive
                    ? {
                          ...NavLinkStyle,
                          backgroundColor: bgActive,
                      }
                    : isLast
                      ? {
                            ...NavLinkLast,
                            backgroundColor: bgInactive,
                        }
                      : isFirst
                        ? {
                              ...FirstNavLink,
                              ...NavLinkStyle,
                              backgroundColor: bgInactive,
                          }
                        : {
                              ...NavLinkStyle,
                              backgroundColor: bgInactive,
                          };

        return linkStyle;
    };

    return (
        <SlideContainer>
            {isSidebarOpen && (
                <>
                    <SidebarDesktop
                        user={user}
                        setSidebarOpen={handleSidebar}
                        setAccount={setAccount}
                        setSettings={setSettings}
                    />
                </>
            )}
            <Position bg={colors.surface}>
                <Container style={{ maxWidth: "400px" }}>
                    <Navbar style={{ backgroundColor: "inherit" }}>
                        <Container className="d-flex justify-content-between w-100">
                            <Navbar.Brand href="/editor">
                                <Logo />
                            </Navbar.Brand>
                            {/* <PlanBanner /> */}
                            <div onClick={handleSidebar}>
                                <Avatar />
                            </div>
                        </Container>
                    </Navbar>
                </Container>
                <Container style={{ maxWidth: "400px" }}>
                    <Navbar>
                        <Container className="justify-content-center">
                            <Nav style={pageNav} className="">
                                {pages.map((page, index) => {
                                    const linkStyle = getStyle(index);

                                    return (
                                        <Nav.Link
                                            key={index}
                                            style={linkStyle}
                                            href="#"
                                            onClick={() => {
                                                handleClick(index);
                                                setVista(page);
                                            }}
                                        >
                                            {page}
                                        </Nav.Link>
                                    );
                                })}
                            </Nav>
                        </Container>
                    </Navbar>
                </Container>
            </Position>
        </SlideContainer>
    );
};

export default NavbarComponent;

const Position = styled.div`
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;
    background-color: ${(props) => props.bg};
`;

const slideInDiagonal = keyframes`
    from {
        transform: translate(100%, -100%) scale(0);
    }
    to {
        transform: translate(0, 0) scale(1);
    }
`;

const Slide = styled.div`
    animation: ${({ isOpen }) => (isOpen ? slideInDiagonal : "none")} 0.5s
        ease-in-out;
`;

const SlideContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: visible;
    z-index: 2000;
    transition: 0.5s;
`;

const pageNav = {
    display: "flex",
    padding: "0 0rem",
    width: "100%",
    maxWidth: "400px",
};
