import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Home from "../../../links/src/components/Home.jsx";
import constant from "../../../constants/constMap.js";
import { db } from "../../../config/firebase.config.js";
import { useDefaultDB } from "../hooks/RealTimeContext.jsx";

const MockupContainer = ({ type = "showDesktop", user }) => {
    const collection = constant.collectionUser;
    const { DB, setDB } = useDefaultDB();

    const innerWidth = window.innerWidth;

    useEffect(() => {}, [DB]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (user) {
                    const docRef = db.collection(collection).doc(user);
                    const docSnapshot = await docRef.get();
                    if (docSnapshot.exists) {
                        const userData = docSnapshot.data();
                        // setUserData(userData);
                        setDB(userData);
                    } else {
                        // console.log('Fondo: No existe el usuario.')
                    }
                }
            } catch (error) {
                console.error(
                    "Fondo: Error al obtener la imagen de Firestore: ",
                    error,
                );
            }
        };

        fetchData();
    }, [user]);

    return (
        <MockupContainerStyled type={type}>
            <ContentWrapper innerWidth={innerWidth} type={type}>
                <Home
                    data={DB.datos}
                    styles={DB.styles}
                    radius={"22px"}
                    border={"12px solid #cecece"}
                    showShare={false}
                />
            </ContentWrapper>
        </MockupContainerStyled>
    );
};
const MockupContainerStyled = styled.div`
    position: absolute;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    @media (max-width: 767px) {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-height: calc(100vh);
    }
`;

const ContentWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (min-width: 768px) {
        width: ${(props) => {
            switch (props.type) {
                case "showMobile":
                    return `calc(425px)`;
                case "showTablet":
                    return `calc(600px)`;
                default:
                    return `calc(1420px)`;
            }
        }};
        min-height: ${(props) => {
            switch (props.type) {
                case "showMobile":
                    return `calc(800px)`;
                case "showTablet":
                    return `calc(800px)`;
                default:
                    return `calc(800px)`;
            }
        }};
        transform: scale(
            ${(props) => {
                switch (props.type) {
                    case "showMobile":
                        return `calc(0.5 + ((${1200} - 800) / 1120) * 0.15 )`;
                    case "showTablet":
                        return `calc(0.36 + ((${1500} - 800) / 1120) * 0.20 )`;
                    default:
                        return `calc(0.1 + ((${1700} - 800) / 1120) * 0.25 )`;
                }
            }}
        );
    }

    @media (max-width: 767px) {
        width: ${(props) => {
            switch (props.type) {
                case "showMobile":
                    return `calc(375px)`;
                case "showTablet":
                    return `calc(645px)`;
                default:
                    return `calc(1400px)`;
            }
        }};
        transform: scale(
            ${(props) => {
                switch (props.type) {
                    case "showMobile":
                        return `calc(0.7 + ((${props.innerWidth} - 800) / 1120) * 0.15 )`;
                    case "showTablet":
                        return `calc(0.65 + ((${props.innerWidth} - 800) / 1120) * 0.5 )`;
                    default:
                        return `calc(0.30 + ((${props.innerWidth} - 800) / 1120) * 0.25 )`;
                }
            }}
        );
    }
`;


export default MockupContainer;
