import React, { useState, useEffect } from "react";
import { Slider } from "antd";
import { useTheme } from "../hooks/ThemeContext";

const IconSlider = ({ onRadiusChange, value }) => {
    const { themeContent, theme } = useTheme();
    const [colors, setColors] = useState(themeContent);
    useEffect(() => {
        setColors(themeContent);
    }, [themeContent, theme]);

    const handleRadius = (value) => {
        onRadiusChange(value);
    };

    const primaryColor = colors.primaryContainer;
    const secondaryColor = colors.onSurface;

    const marks = {
        0: { label: "0", style: { color: secondaryColor } },
        50: { label: "50", style: { color: secondaryColor } },
        100: { label: "100", style: { color: secondaryColor } },
    };

    return (
        <div className="icon-wrapper w-100 mx-3">
            <Slider
                marks={marks}
                step={null}
                onChange={handleRadius}
                value={value}
                trackStyle={{ background: primaryColor }}
                handleStyle={{ borderColor: primaryColor }}
                dotStyle={{ borderColor: primaryColor }}
                dotActiveStyle={{ borderColor: primaryColor }}
            />
        </div>
    );
};

export default IconSlider;
