const isValidUrl = (url) => {
    const urlRegex =
        /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+.*)$/i;
    return urlRegex.test(url);
};

const isValidWPNumber = (number) => {
    const numberRegex = /^\d{10}$/;
    return numberRegex.test(number);
};

const socialLinkByUsername = (username, social) => {
    const enlacesRedesSociales = {
        "behance": username ? `https://www.behance.net/${username}` : `https://www.behance.net/`,
        "discord": "https://discord.com/",
        "dribbble": username ? `https://dribbble.com/${username}` : `https://dribbble.com/`,
        "facebook": username ? `https://www.facebook.com/${username}` : `https://www.facebook.com/`,
        "fiverr": username ? `https://www.fiverr.com/${username}` : `https://www.fiverr.com/`,
        "upwork": username ? `https://www.upwork.com/freelancers/~${username}` : `https://www.upwork.com/`,
        "github": username ? `https://github.com/${username}` : `https://github.com/`,
        "instagram": username ? `https://www.instagram.com/${username}` : `https://www.instagram.com/`,
        "linkedin": username ? `https://www.linkedin.com/in/${username}` : `https://www.linkedin.com/`,
        "pinterest": username ? `https://www.pinterest.com/${username}` : `https://www.pinterest.com/`,
        "spotify": username ? `https://open.spotify.com/user/${username}` : `https://open.spotify.com/`,
        "reddit": username ? `https://www.reddit.com/r/${username}` : `https://www.reddit.com/`,
        "snapchat": username ? `https://www.snapchat.com/add/${username}` : `https://www.snapchat.com/`,
        "slack": "https://slack.com/",
        "telegram": username ? `https://t.me/${username}` : `https://t.me/`,
        "tiktok": username ? `https://www.tiktok.com/@${username}` : `https://www.tiktok.com/`,
        "twitter": username ? `https://twitter.com/${username}` : `https://twitter.com/`,
        "youtube": username ? `https://www.youtube.com/${username}` : `https://www.youtube.com/`,
        "email": username ? `mailto:${username}` : "",
        "threads": username ? `https://www.threads.net/@${username}` : `https://www.threads.net/`,
        "x": username ? `https://www.x.com/${username}` : `https://www.x.com/`,
        "whatsapp": username ? `https://api.whatsapp.com/send/?phone=${username}&text&type=phone_number&app_absent=0` : `https://wa.me/`
    };

    if (enlacesRedesSociales.hasOwnProperty(social.toLowerCase())) {
        return enlacesRedesSociales[social.toLowerCase()];
    } else {
        return null;
    }
}

const setCanvasPreview = (
    image, // HTMLImageElement
    canvas, // HTMLCanvasElement
    crop // PixelCrop
  ) => {
    const ctx = canvas.getContext("2d");
    if (!ctx) {
      throw new Error("No 2d context");
    }
  
    // devicePixelRatio slightly increases sharpness on retina devices
    // at the expense of slightly slower render times and needing to
    // size the image back down if you want to download/upload and be
    // true to the images natural size.
    const pixelRatio = window.devicePixelRatio;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
  
    canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
    canvas.height = Math.floor(crop.height * scaleY * pixelRatio);
  
    ctx.scale(pixelRatio, pixelRatio);
    ctx.imageSmoothingQuality = "high";
    ctx.save();
  
    const cropX = crop.x * scaleX;
    const cropY = crop.y * scaleY;
  
    // Move the crop origin to the canvas origin (0,0)
    ctx.translate(-cropX, -cropY);
    ctx.drawImage(
      image,
      0,
      0,
      image.naturalWidth,
      image.naturalHeight,
      0,
      0,
      image.naturalWidth,
      image.naturalHeight
    );
  
    ctx.restore();
  };

export { isValidUrl, socialLinkByUsername, isValidWPNumber, setCanvasPreview };