const constantes = {
    collectionUser: "usuarios",
    default: {
        email: "correo_predeterminado@example.com",
        plan: "free",
        logo: "./img/default/default.jpg",
        link: "",
    },
    breakpoints: {
        mobile: 768,
        tablet: 1024,
        desktop: 1280,
    }
};

export default constantes;
