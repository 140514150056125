import Home from "../dashboard/src/Pages/Home.jsx";
import Login from "../dashboard/src/Pages/Login.jsx";
import Verificacion from "../dashboard/src/Pages/Verificacion.jsx";
import Verificada from "../dashboard/src/Pages/Verificada.jsx";
import Welcome from "../dashboard/src/Pages/Welcome.jsx";
import NotFound from "../dashboard/src/Pages/NotFound.jsx";

const routes = [
    {
        path: "/editor",
        component: Home,
    },
    {
        path: "/auth",
        component: Login,
    },
    {
        path: "/verificacion",
        component: Verificacion,
    },
    {
        path: "/verificada",
        component: Verificada,
    },
    {
        path: "/welcome",
        component: Welcome,
    },
    {
        path: "*",
        component: NotFound,
    },
];

export default routes;
