import React, { lazy } from "react";

const Login = lazy(() => import("../components/Login"));

const LoginFunc = () => {
    return (
        <>
            <Login active={false} />
        </>
    );
};

export default LoginFunc;
