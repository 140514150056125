import React, { useState, useEffect } from "react";
import { db } from "../../../config/firebase.config.js";
// import content from "../../../content/es/dashboardContent.json";
import Title from "../common/TitleSection";
import Subtitle from "../common/SubtitleSection";
import UsableButton from "../common/UsableButton";
// import Separator from "../common/Separator";
import Section from "../common/Section";
import ColorPicker from "../common/ColorPicker";
import OptionEnable from "../common/OptionEnable";
import AddLink from "../common/AddLink";
import AddedLink from "../common/AddedLink";
import constant from "../../../constants/constMap.js";
import AlertModal from "../common/AlertModal.jsx";
import { TitleSubtitleSkeleton, CardSkeleton } from "../common/Skeleton";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { v4 as uuidv4 } from "uuid";
import { isValidUrl } from "../common/utils/utils.js";
import { Container } from "react-bootstrap";
import { useDefaultDB } from "../hooks/RealTimeContext.jsx";
import { useLang } from "../hooks/LangContext";

const LinksSection = ({ user }) => {
    
    const { langContent, lang } = useLang();
    const [content, setContent] = useState(langContent);
    useEffect(() => {
        setContent(langContent);
    }, [langContent, lang]);
    
    const ContentLinks = content.Links;

    const [LinksLoaded, setLinksLoaded] = useState(false);

    const collection = constant.collectionUser;

    const [userData, setUserData] = useState(null);
    const [linkData, setLinkData] = useState({});

    const [showAddLink, setShowAddLink] = useState(false);
    const [links, setLinks] = useState([]);

    const [showDelete, setShowDelete] = useState(false);
    const [showError, setShowError] = useState(false);

    const [deleteIndex, setDeleteIndex] = useState(null);

    const [radius, setRadius] = useState("0");

    const { setDB } = useDefaultDB();

    const handleRadius = (value) => {
        switch (value) {
            case 0:
                setRadius("0px");
                break;
            case 50:
                setRadius("10px");
                break;
            case 100:
                setRadius("32px");
                break;
            default:
                setRadius("0");
        }
    };

    const defaultTitle = ContentLinks.defaultTitle;

    const defaultLink = {
        icon: "faFacebook",
        iconImg: "",
        url: "milink.com",
        nombre: defaultTitle,
        render: "si",
        id: "",
    };

    useEffect(() => {
        const fetchData = async () => {
            if (user) {
                try {
                    const docRef = db.collection(collection).doc(user);
                    const docSnapshot = await docRef.get();
                    if (docSnapshot.exists) {
                        const userData = docSnapshot.data();
                        setUserData(userData);

                        const enlaces = {
                            links: userData.datos.enlaces,
                            container: {
                                color: [
                                    userData.styles.enlaces.colorTexto,
                                    userData.styles.enlaces.colorFondo,
                                    userData.styles.enlaces.border === "none"
                                        ? "#101010"
                                        : userData.styles.enlaces.border.match(
                                              /#([0-9A-Fa-f]{6})/,
                                          )[0],
                                ],
                                elementos: [
                                    userData.styles.enlaces.borderRadius !== "0"
                                        ? true
                                        : false,
                                    userData.styles.enlaces.border !== "none"
                                        ? true
                                        : false,
                                    userData.styles.enlaces.colorIcon !== ""
                                        ? true
                                        : false,
                                    userData.styles.enlaces.colorHover !== ""
                                        ? true
                                        : false,
                                ],
                            },
                        };

                        setRadius(userData.styles.enlaces.borderRadius);

                        setLinkData(enlaces);
                        setLinks(enlaces.links);
                        setLinksLoaded(true);
                    } else {
                        console.log("Links: No existe el usuario.");
                    }
                } catch (error) {
                    console.error(
                        "Links: Error al obtener la imagen de Firestore: ",
                        error,
                    );
                }
            }
        };
        fetchData();
    }, [user]);

    useEffect(() => {
        if (userData) {
            const userRef = db.collection(collection).doc(user);
            const updatedUserData = { ...userData };

            if (links) {
                updatedUserData.datos.enlaces = links;
            }

            if (linkData.container) {
                updatedUserData.styles.enlaces.colorTexto =
                    linkData.container.color[0];
                updatedUserData.styles.enlaces.colorFondo =
                    linkData.container.color[1];
                updatedUserData.styles.enlaces.border = linkData.container
                    .elementos[1]
                    ? "1px solid " + linkData.container.color[2]
                    : "none";
                updatedUserData.styles.enlaces.borderRadius = linkData.container
                    .elementos[0]
                    ? radius
                    : "0";

                setDB((prevDB) => ({
                    ...prevDB,
                    datos: {
                        ...prevDB.datos,
                        enlaces: links,
                    },
                    styles: {
                        ...prevDB.styles,
                        enlaces: {
                            ...prevDB.styles.enlaces,
                            colorTexto: linkData.container.color[0],
                            colorFondo: linkData.container.color[1],
                            border: updatedUserData.styles.enlaces.border,
                            borderRadius:
                                updatedUserData.styles.enlaces.borderRadius,
                        },
                    },
                }));
            }
            // userRef.set(updatedUserData, { merge: true });
        }
    }, [linkData, links, userData, radius]);

    const handleUsableButtonClick = () => {
        setShowAddLink(true);
        setShowError(false);
    };

    const handleClose = () => {
        setShowAddLink(false);
    };

    const handleAddLink = (newLinkData) => {
        if (!isValidUrl(newLinkData.value) || !newLinkData.value) {
            setShowError(true);
            return;
        } else {
            setShowAddLink(false);
        }

        const newLink = { ...defaultLink };
        newLink.url = `https://${newLinkData.value}`;
        newLink.nombre = newLinkData.title;
        newLink.id = uuidv4();

        setLinks([...links, newLink]);
        setLinkData({ links: [newLink, ...links], ...linkData });
        setShowAddLink(false);
    };

    const handleToggleChecked = (checked, index) => {
        const newData = { ...linkData };
        newData.container.elementos[index] = checked;
        setLinkData(newData);
    };

    const handleColorChange = (newColor, index) => {
        const newData = { ...linkData };
        newData.container.color[index] = newColor;
        setLinkData(newData);
    };

    const handleLinkChange = (newLink, index) => {
        const newData = { ...linkData };
        try {
            newData.links[index].url = newLink.url;
            newData.links[index].nombre = newLink.nombre
                ? newLink.nombre
                : defaultTitle;
            newData.links[index].render = newLink.show === true ? "si" : "no";

            setLinkData(newData);
        } catch (error) {
            console.error("Error al cambiar el enlace: ", error);
        }
    };

    // Delete Links
    const handleDelete = (index) => {
        setShowDelete(true);
        setDeleteIndex(index);
    };

    const handleRemove = () => {
        const newLinks = [...links];
        newLinks.splice(deleteIndex, 1);
        setLinks(newLinks);
        setLinkData((prevData) => ({
            ...prevData,
            links: newLinks,
        }));

        setDeleteIndex(null);
        setShowDelete(false);
    };

    const handleCancelDelete = () => {
        setShowDelete(false);
    };

    // Drag and drop
    const handleDragEnd = (result) => {
        if (
            !result.destination ||
            result.destination.index === result.source.index
        ) {
            return;
        }
        const reorderedLinks = Array.from(links);
        const [removed] = reorderedLinks.splice(result.source.index, 1);
        reorderedLinks.splice(result.destination.index, 0, removed);
        setLinks(reorderedLinks);
    };

    return (
        <Container>
            {LinksLoaded && (
                <>
                    <AlertModal
                        show={showError}
                        onlyAlert={true}
                        title={content.Modal.alert.title}
                        parrafo={content.Modal.alert.parrafo}
                        onCancel={() => setShowError(false)}
                    />
                    <AlertModal
                        show={showDelete}
                        onCancel={handleCancelDelete}
                        onDelete={() => handleRemove()}
                    />
                    <Section>
                        <Title title={content.Links.title} />
                        <Subtitle title={content.Links.subtitle} />
                        {!showAddLink && (
                            <UsableButton
                                imgSrc={ContentLinks.imgPlus}
                                imgSrcDark={ContentLinks.imgPlusDark}
                                btnContent={ContentLinks.btn}
                                onClick={handleUsableButtonClick}
                            />
                        )}
                        {showAddLink && (
                            <AddLink
                                onAddLink={(newLinkData) =>
                                    handleAddLink(newLinkData)
                                }
                                onClose={handleClose}
                                defaultTitle={defaultTitle}
                                type="link"
                            />
                        )}
                        {/* <TestDrag /> */}
                        <DragDropContext onDragEnd={handleDragEnd}>
                            <Droppable droppableId="droppableLinks">
                                {(provided) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                        style={{ width: "100%" }}
                                    >
                                        {links.map((link, index) => (
                                            <Draggable
                                                key={link.id}
                                                draggableId={link.id}
                                                index={index}
                                            >
                                                {(provided) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        <AddedLink
                                                            key={index}
                                                            link={link.url}
                                                            linkNombre={
                                                                link.nombre
                                                            }
                                                            showLink={
                                                                link.render ===
                                                                "si"
                                                                    ? true
                                                                    : false
                                                            }
                                                            remove={() =>
                                                                handleDelete(
                                                                    index,
                                                                )
                                                            }
                                                            onLinkChange={(
                                                                newLink,
                                                            ) =>
                                                                handleLinkChange(
                                                                    newLink,
                                                                    index,
                                                                )
                                                            }
                                                            index={index}
                                                        />
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </Section>
                    {/* <Separator /> */}
                    <Section>
                        <Title title={content.Links.titleSection} />
                        {ContentLinks.editsColor.map((edit, index) => (
                            <ColorPicker
                                defaultColor={linkData.container.color[index]}
                                key={index}
                                text={edit}
                                onColorChange={(color) =>
                                    handleColorChange(color, index)
                                }
                            />
                        ))}
                        {ContentLinks.editStyle.map((edit, index) => (
                            <OptionEnable
                                key={index}
                                onToggleChange={(checked) =>
                                    handleToggleChecked(checked, index)
                                }
                                text={edit}
                                setTrue={linkData.container.elementos[index]}
                                disable={index > 1 ? true : false}
                                setRadius={handleRadius}
                                value={
                                    radius === "0px"
                                        ? 0
                                        : radius === "10px"
                                          ? 50
                                          : 100
                                }
                            />
                        ))}
                    </Section>
                </>
            )}
            {!LinksLoaded && (
                <div className="d-flex flex-column align-items-center">
                    <TitleSubtitleSkeleton />
                    <CardSkeleton count={1} height={40} width={380} />
                    <br />
                    <TitleSubtitleSkeleton />
                    <CardSkeleton count={6} height={50} width={380} />
                </div>
            )}
        </Container>
    );
};

export default LinksSection;
