import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import BotonAñadir from "./BotonCompartir";
import { Divider } from "antd";
import BottomSheets from "./BottomSheet";
import { useTheme } from "../hooks/ThemeContext";
import "antd/dist/antd.css";
import { useLang } from "../hooks/LangContext";



const AddLink = ({ onAddLink, onClose, defaultTitle, type }) => {
    const { themeContent, theme } = useTheme();
    const [colors, setColors] = useState(themeContent);
    useEffect(() => {
        setColors(themeContent);
    }, [themeContent, theme]);

    const { langContent, lang } = useLang();
    const [content, setContent] = useState(langContent);
    const [ContentLink, setContentLink] = useState(content.Links.addLink);
    useEffect(() => {
        setContent(langContent);
        setContentLink(langContent.Links.addLink);
    }, [langContent, lang]);

    const [linkData, setLinkData] = useState({ title: "", value: "" });

    const titleRef = useRef(null);
    const valueRef = useRef(null);

    useEffect(() => {
        try {
            titleRef.current.focus();
        } catch (error) {
            valueRef.current.focus();
        }
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setLinkData({ ...linkData, [name]: value });
    };

    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            handleSubmit();
        }
    };

    const handleSubmit = () => {
        onAddLink(linkData);
    };

    const [toggleBottomSheet, setToggleBottomSheet] = useState(false);

    const handleBottomSheet = () => {
        setToggleBottomSheet(!toggleBottomSheet);
    };

    return (
        < >
            <AddLinkStyled $bgcolor={colors.surfaceContainer}>
                <Content>
                    <Title color={colors.onSurface}>
                        {ContentLink.title}
                        {theme === "light" ? (
                            <Img
                                src={ContentLink.closeIcon}
                                onClick={onClose}
                            />
                        ) : (
                            <Img
                                src={ContentLink.closeIconDark}
                                onClick={onClose}
                            />
                        )}
                    </Title>
                    <Url>
                        {type === "link" ? (
                            <div className="w-100">
                                <InputUrl
                                    name="title"
                                    ref={titleRef}
                                    value={linkData.title}
                                    onChange={handleInputChange}
                                    placeholder={defaultTitle}
                                    onKeyDown={handleKeyPress}
                                    $bgcolor={colors.onSurfaceContainer}
                                    $col={colors.onSurface}
                                    $colorPH={"#999"}
                                />
                            </div>
                        ) : null}
                        <div className="d-flex w-100">
                            <InputUrl
                                name="value"
                                ref={valueRef}
                                value={linkData.value}
                                onChange={handleInputChange}
                                placeholder={ContentLink.url}
                                onKeyDown={handleKeyPress}
                                $bgcolor={colors.onSurfaceContainer}
                                $col={colors.onSurface}
                                $colorPH={"#999"}
                            />
                        </div>
                        <div className="d-flex w-100 justify-content-end">
                            <BotonAñadir
                                btnContent={ContentLink.btn}
                                onClick={() => onAddLink(linkData)}
                                type="addLink"
                            />
                        </div>
                    </Url>
                    {type === "social" ? (
                        <>
                            <Divider
                                style={{
                                    borderColor: "#B1B1B1",
                                    margin: "10px 0",
                                }}
                            />
                            <Title>{ContentLink.rec}</Title>
                            <DivFlex>
                                {ContentLink.options.map((opt, index) => (
                                    <RecCont key={index}>
                                        <ImgCont>
                                            <Img src={opt.icon} />
                                        </ImgCont>
                                        <Subtitle>{opt.name}</Subtitle>
                                    </RecCont>
                                ))}
                            </DivFlex>
                            <Title onClick={handleBottomSheet} color="#A84AC9">
                                {ContentLink.ver}
                                <Img src={ContentLink.verIcon} />
                            </Title>
                        </>
                    ) : null}
                </Content>
            </AddLinkStyled>
            <BottomSheets
                pictureHandle={false}
                heigth="auto"
                toggleBottomSheet={toggleBottomSheet}
                onClose={() => handleBottomSheet(false)}
            />
        </>
    );
};

const AddLinkStyled = styled.div`
    display: flex;
    justify-content: space-around;
    padding: 10px;
    align-items: center;
    width: 100%;
    height: auto;
    background-color: ${(props) => props.$bgcolor};
    border-radius: 10px;
    cursor: pointer;
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.2);
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 90%;
    margin: 10px;
`;

const Title = styled.p`
    font-size: 14px;
    font-weight: 500;
    margin: 5px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    color: ${(props) => (props.color)};
    cursor: pointer;
`;

const Url = styled.div`
    width: 100%;
    margin: 10px 0;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
`;

const InputUrl = styled.input`
    width: 100%;
    height: 30px;
    border-radius: 4px;
    border: 1px solid #818181;
    padding: 0 10px;
    outline: none;
    background-color: #e9e9e9;
    margin: 10px 0;
    background-color: ${(props) => props.$bgcolor};
    color: ${(props) => props.$col};

    &::placeholder {
        color: ${(props) => props.$colorPH};
    }
`;

const Subtitle = styled.p`
    font-size: 12px;
    font-weight: 400;
    margin: 0;
`;

const ImgCont = styled.div`
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #d9d9d9;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Img = styled.img`
    width: 20px;
    height: 20px;
`;

const DivFlex = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
`;

const RecCont = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export default AddLink;
