import { faFacebook, faLinkedin, faTwitter, faWhatsapp} from "@fortawesome/free-brands-svg-icons";
const enlacePrincipal = `${window.location.host}${window.location.pathname}`

const content = {
    tarjetaCompartir: {
        title: "Compartir este sitio",
        copiar: "Copiar enlace",
        crea: "Crea tus sitios con Rappid Links",
        cambiarTexto: {
            textoCopiar: "Copiar este enlace",
            textoCopiado: "¡Copiado!",
        },
        shareSocial: [
            {
                icon: faFacebook,
                url: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(enlacePrincipal)}`,
                title: "Compartir en Facebook"
            },
            {
                icon: faTwitter,
                url: `https://twitter.com/intent/tweet?url=${encodeURIComponent(enlacePrincipal)}`,
                title: "Compartir en Twitter"
            },
            {
                icon: faLinkedin,
                url: `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(enlacePrincipal)}`,
                title: "Compartir en LinkedIn"
            },
            {
                icon: faWhatsapp,
                url: `https://wa.me/?text=${encodeURIComponent(enlacePrincipal)}`,
                title: "Compartir en WhatsApp"
            }
        ]
    },
}

export default content;