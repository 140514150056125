import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useTheme } from "../hooks/ThemeContext";

const SubtitleSection = ({ title }) => {
    const { themeContent, theme } = useTheme();
    const [colors, setColors] = useState(themeContent);
    useEffect(() => {
        setColors(themeContent);
    }, [themeContent, theme]);

    return <StyledH5 color={colors.onSurface}>{title}</StyledH5>;
};

const StyledH5 = styled.h5`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: 0.5px;
    color: ${(props) => props.color};
`;

export default SubtitleSection;
