import React, { useState, useEffect } from "react";
import styled from "styled-components";
// import content from "../../../content/es/dashboardContent.json";
import Separator from "../common/Separator";
import Tema from "../common/Tema";
import Lock from "../common/Lock.jsx";
import constant from "../../../constants/constMap.js";
import { useTheme } from "../hooks/ThemeContext";
import { useLang } from "../hooks/LangContext";
import { db } from "../../../config/firebase.config.js";
import { AvatarSkeleton, TitleSubtitleSkeleton } from "../common/Skeleton";
import { saveDataToLocal, loadDataFromLocal } from "../utils/LocalStorage";
import { getAuth, signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import AlertModal from "../common/AlertModal.jsx";

const SidebarDesktop = ({
    user,
    setVista,
    setAccount,
    setSettings,
    setSidebarOpen,
}) => {
    const { themeContent, theme } = useTheme();
    const [colors, setColors] = useState(themeContent);
    useEffect(() => {
        setColors(themeContent);
    }, [themeContent, theme]);
    
    const { langContent, lang, toggleLang } = useLang();
    const [content, setContent] = useState(langContent);
    useEffect(() => {
        setContent(langContent);
    }, [langContent, lang]);
    
    const sidebarContent = content.SidebarDesktop;
    const sidebarContent2 = content.Sidebar;

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const isTablet = windowWidth < constant.breakpoints.tablet;
    const isMobile = windowWidth < constant.breakpoints.mobile;

    const Navigate = useNavigate();

    const [userData, setUserData] = useState(null);
    const collection = constant.collectionUser;
    useEffect(() => {
        const fetchData = async () => {
            try {
                if (user) {
                    const docRef = db.collection(collection).doc(user);
                    const docSnapshot = await docRef.get();
                    if (docSnapshot.exists) {
                        // console.log(docSnapshot.data())
                        setUserData(docSnapshot.data());
                    } else {
                        // console.log('Fondo: No existe el usuario.')
                    }
                }
            } catch (error) {
                console.error(
                    "Sidebar: Error al obtener la imagen de Firestore: ",
                    error,
                );
            }
        };
        fetchData();
    }, [user]);

    const index0 = loadDataFromLocal("startIndex");
    let startIndex = 0;

    if (index0) {
        startIndex = index0;
    } else {
        saveDataToLocal("startIndex", 0);
    }

    const [activeVista, setActiveVista] = useState(false);
    const [activeVistaIndex, setActiveVistaIndex] = useState(startIndex);

    const [userMenu, setUserMenu] = useState(false);
    const [userMenuIndex, setUserMenuIndex] = useState(null);

    const [btmMenu, setBtmMenu] = useState(false);
    const [btmMenuIndex, setBtmMenuIndex] = useState(null);

    const [expanded, setExpanded] = useState(isMobile ? true : false);
    const [newLinkSubMenu, setNewLinkSubMenu] = useState(false);
    const [rotate, setRotate] = useState(270);

    const [shake, setShake] = useState(false);

    const [alertModal, setAlertModal] = useState(false);

    const handleShake = () => {
        setShake(!shake);
    };

    const handleUser = () => {
        setNewLinkSubMenu(!newLinkSubMenu);
        setRotate(rotate + 180);
    };

    const toggleSidebar = () => {
        if (isMobile) {
            setSidebarOpen();
        } else {
            setExpanded(!expanded);
        }
    };

    const handleClickVista = (index, item) => {
        setActiveVista(!activeVista);
        setActiveVistaIndex(index);
        saveDataToLocal("startIndex", index);

        setUserMenu(false);
        setUserMenuIndex(null);

        setBtmMenu(false);
        setBtmMenuIndex(null);

        setVista(item)
    };

    const handleClickUserMenu = (index, item) => {
        setUserMenu(!userMenu);
        setUserMenuIndex(index);

        setActiveVista(false);
        setActiveVistaIndex(null);

        setBtmMenu(false);
        setBtmMenuIndex(null);

        if (isMobile) {
            setSidebarOpen();
        }

        setAccount(item);
    };

    const handleClickBtmMenu = (index, itemText) => {
        if (index === sidebarContent.bottomMenu.length - 1) {
            setAlertModal(true);
        } else {
            if (isMobile) {
                setSidebarOpen();
            }

            if (index === 0) {
                toggleLang()
                return;
            } else {
                setBtmMenu(!btmMenu);
                setBtmMenuIndex(index);

                setActiveVista(false);
                setActiveVistaIndex(null);

                setUserMenu(false);
                setUserMenuIndex(null);

                setSettings(itemText);
            }
        }
    };

    const handleCloseSession = () => {
        const auth = getAuth();
        signOut(auth)
            .then(() => {
                Navigate("/");
            })
            .catch((error) => {
                console.error("An error happened.", error);
            });
    };

    const toggleImg =
        theme === "light"
            ? expanded
                ? sidebarContent.heading.close
                : sidebarContent.heading.open
            : expanded
              ? sidebarContent.heading.closeDark
              : sidebarContent.heading.openDark;

    const handleClickOutside = (e) => {
        if (!isMobile) {
            e.stopPropagation();
            if (expanded) {
                setExpanded(false);
            }
        }
    };

    const colorActiveItem = colors.secondaryContainer;
    const colorHoverItem = colors.surfaceContainerLow;

    const renderSidebar = () => {
        return (
            <>
                <Header expanded={expanded} $bg={colors.surfaceContainer}>
                    <div className="d-flex">
                        <Logo
                            src={
                                theme === "light"
                                    ? sidebarContent.heading.img
                                    : sidebarContent.heading.imgDark
                            }
                            alt={sidebarContent.heading.title}
                        />
                        <Title $col={colors.onSurface}>
                            {expanded ? sidebarContent.heading.title : null}
                        </Title>
                    </div>
                    <MenuIcon onClick={toggleSidebar} src={toggleImg} />
                </Header>
                <MenuSection>
                    <Profile>
                        {userData ? (
                            <div className="d-flex align-items-center">
                                <Logo
                                    src={
                                        userData.perfil.img ||
                                        userData.perfil.default
                                    }
                                    alt={sidebarContent.heading.title}
                                />
                                <div className="mx-2">
                                    <P $col={colors.onSurface}>
                                        {expanded
                                            ? `@${userData.perfil.nombre}`
                                            : null}
                                    </P>
                                    <P $col={colors.onSurface}>
                                        {expanded ? userData.perfil.mail : null}
                                    </P>
                                </div>
                                {expanded && (
                                    <Img
                                        src={
                                            theme === "light"
                                                ? "/img/svg/arrow.svg"
                                                : "/img/svg/arrow-dark.svg"
                                        }
                                        transform={rotate}
                                        onClick={handleUser}
                                    />
                                )}
                            </div>
                        ) : (
                            expanded && (
                                <div className="d-flex align-items-center">
                                    <AvatarSkeleton width="30px" />
                                    <TitleSubtitleSkeleton />
                                </div>
                            )
                        )}
                        {newLinkSubMenu && expanded && (
                            <Add $col={colors.onSurface} onClick={handleShake}>
                                {expanded ? "Crear nuevo RappidLink" : null}
                                <Lock onShake={shake} />
                            </Add>
                        )}
                    </Profile>
                </MenuSection>
                <Separator />
                {!isMobile && (
                    <>
                        <MenuSection>
                            {sidebarContent.dashboardMenu.map((item, index) => (
                                <MenuItem
                                    key={index}
                                    $just={expanded ? "" : "center"}
                                    $bg={
                                        activeVistaIndex === index
                                            ? colorActiveItem
                                            : ""
                                    }
                                    onClick={() => {
                                        handleClickVista(index, item.text);
                                    }}
                                    $bgHover={
                                        index !== activeVistaIndex
                                            ? colorHoverItem
                                            : ""
                                    }
                                >
                                    <MenuIcon
                                        src={
                                            theme === "light"
                                                ? item.icon
                                                : item.iconDark
                                        }
                                        alt={item.text}
                                    />
                                    <MenuText $col={colors.onSurface}>
                                        {expanded ? item.text : null}
                                    </MenuText>
                                </MenuItem>
                            ))}
                        </MenuSection>
                        <Separator />
                    </>
                )}
                <MenuSection>
                    {sidebarContent.accountMenu.map((item, index) => (
                        <MenuItem
                            key={index}
                            $just={expanded ? "" : "center"}
                            $bg={userMenuIndex === index ? colorActiveItem : ""}
                            onClick={() => {
                                handleClickUserMenu(index, item.text);
                                
                            }}
                            $bgHover={
                                index !== userMenuIndex ? colorHoverItem : ""
                            }
                        >
                            <MenuIcon
                                src={
                                    theme === "light"
                                        ? item.icon
                                        : item.iconDark
                                }
                                alt={item.text}
                            />
                            <MenuText $col={colors.onSurface}>
                                {expanded ? item.text : null}
                            </MenuText>
                        </MenuItem>
                    ))}
                </MenuSection>
                <Separator />
                <MenuSection>
                    {sidebarContent.bottomMenu.map((item, index) => (
                        <MenuItem
                            key={index}
                            $just={expanded ? "" : "center"}
                            $bg={btmMenuIndex === index ? colorActiveItem : ""}
                            $bgHover={
                                index !== btmMenuIndex ? colorHoverItem : ""
                            }
                            onClick={() => {
                                handleClickBtmMenu(index, item.text);
                            }}
                        >
                            <MenuIcon
                                src={
                                    theme === "light"
                                        ? item.icon
                                        : item.iconDark
                                }
                                alt={item.text}
                            />
                            <MenuText
                                $col={
                                    index ===
                                    sidebarContent.bottomMenu.length - 1
                                        ? colors.error
                                        : colors.onSurface
                                }
                            >
                                {expanded ? item.text : null}
                            </MenuText>
                        </MenuItem>
                    ))}
                </MenuSection>
                <MenuSection>
                    <MenuItem $just={expanded ? "" : "center"}>
                        <Tema version={expanded ? "expanded" : "collapsed"} />
                    </MenuItem>
                </MenuSection>
            </>
        );
    };

    return (
        <>
            {expanded && !isMobile && (
                <Background onClick={handleClickOutside} />
            )}
            <AlertModal
                show={alertModal}
                title={sidebarContent2.modalSession.title}
                parrafo={sidebarContent2.modalSession.text}
                onDelete={handleCloseSession}
                onCancel={() => setAlertModal(false)}
                actionText={sidebarContent2.modalSession.close}
            />
            {!isMobile && (
                <Container
                    isTablet={isTablet}
                    expanded={expanded}
                    $bg={colors.surfaceContainer}
                >
                    {renderSidebar()}
                </Container>
            )}
            {isMobile && (
                <ContainerMobile $bg={colors.surfaceContainer}>
                    {renderSidebar()}
                </ContainerMobile>
            )}
        </>
    );
};

const paddingLeft = "24px";

const Background = styled.div`
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
    backdrop-filter: blur(2px);
`;

const Container = styled.div`
    width: ${(props) => (props.expanded ? "400px" : "80px")};
    min-width: ${(props) => (props.expanded ? "300px" : "100px")};
    background-color: ${(props) => props.$bg};
    position: ${(props) =>
        props.isTablet || Container ? "absolute" : "relative"};
    max-width: 280px;
    height: calc(100vh - 40px);
    overflow-y: auto;
    top: 20px;
    left: 20px;
    z-index: 1000;
    transition: all 0.5s ease;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.3);
    border-radius: 24px;
`;

const ContainerMobile = styled.div`
    width: 100%;
    height: 100vh;
    background-color: ${(props) => props.$bg};
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    transition: all 0.5s ease;
`;

const Header = styled.div`
    display: flex;
    width: ${(props) => (props.expanded ? "280px" : "73px")};
    min-width: ${(props) => (props.expanded ? "280px" : "73px")};
    justify-content: space-between;
    align-items: center;
    padding: ${paddingLeft};
    padding-top: 40px;
    position: sticky;
    top: 0;
    background-color: ${(props) => props.$bg};
    z-index: 1;
    transition: all 0.5s ease;
`;

const Title = styled.h1`
    margin: 0 0 0 10px;
    padding-top: 5px;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: ${(props) => props.$col};
`;

const Img = styled.img`
    transform: rotate(${(props) => props.transform}deg);
    transition: transform 0.3s ease;
`;

const Add = styled.div`
    border: 1px solid ${(props) => props.$col};
    color: ${(props) => props.$col};
    border-radius: 16px;
    padding: 4px 8px;
    transition: transform 0.3s ease;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: space-around;
    margin-top: 16px;
`;

const Profile = styled.div`
    display: flex;
    flex-direction: column;
    align-items: left;
    max-width: 250px;
`;

const P = styled.p`
    color: ${(props) => props.$col};
    margin: 0;
`;

const Logo = styled.img`
    width: 30px;
    height: 30px;
    border-radius: 50%;
`;

const MenuSection = styled.div`
    padding-left: ${paddingLeft};
    padding-right: ${paddingLeft};
`;

const MenuItem = styled.div`
    display: flex;
    align-items: center;
    margin: 5px 0;
    padding: 10px 0;
    justify-content: ${(props) => props.$just};
    background-color: ${(props) => props.$bg};
    cursor: pointer;
    border-radius: 50px;
    padding-left: 10px;

    &:hover {
        background-color: ${(props) => props.$bgHover};
    }
`;

const MenuText = styled.span`
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: ${(props) => props.$col};
`;

const MenuIcon = styled.img`
    width: 24px;
    height: 24px;
    margin-right: 10px;
`;

export default SidebarDesktop;
