import React, { useState, useEffect } from "react";
import ToogleButton from "./ToogleButton";
import styled from "styled-components";
// import Section from "./Section";
import Lock from "./Lock";
import IconSlider from "./IconSlider";
import { useTheme } from "../hooks/ThemeContext";

const OptionEnable = ({
    onToggleChange,
    setTrue,
    disable,
    showToggle = true,
    text,
    text2 = "",
    img = "",
    setRadius,
    value
}) => {

    const { themeContent, theme } = useTheme();
    const [colors, setColors] = useState(themeContent);
    useEffect(() => {
        setColors(themeContent);
    }, [themeContent, theme]);

    const [isClick, setIsClick] = useState(false);

    const handleBlockClick = (e) => {
        e.stopPropagation();

        setIsClick(true);

        setTimeout(() => {
            setIsClick(false);
        }, 500);
    };

    const handleRadius = (value) => {
        setRadius(value)
    }

    return (
        <>
            <Container onClick={handleBlockClick} $bgcolor={colors.surfaceContainer}>
                <Div
                    $justifycontent={
                        img === "" && !disable ? "" : "space-between"
                    }
                >
                    <div style={{ display: "flex" }}>
                        {showToggle ? (
                            <ToogleButton
                                disable={disable}
                                onChange={onToggleChange}
                                setTrue={setTrue}
                            />
                        ) : null}
                        <StyledP color={colors.onSurface}>{text}</StyledP>
                    </div>
                    {text === "Redondeado" || text === "Rounded" ? (
                        <IconSlider onRadiusChange={handleRadius} value={value}/>
                    ) : null}
                    {img || disable ? <Lock onShake={isClick} /> : null}
                </Div>
                {text2 === "" ? null : (
                    <StyledP color={colors.onSurface}>{text2}</StyledP>
                )}
            </Container>
        </>
    );
};

const Container = styled.div`
    width: 100%;
    height: 100%;
    background-color: ${(props) => props.$bgcolor};
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
    border-radius: 12px;
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.2);
    margin: 10px 0;
`;

const StyledP = styled.p`
    margin: 5px;
    margin-left: 10px;
    font-size: 14px;
    color: ${(props) => props.color};
`;

const Div = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: ${(props) => props.$justifycontent};
`;

export default OptionEnable;
