import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import Loading from "../common/Loading";
import "react-circular-progressbar/dist/styles.css";
import { db, storage } from "../../../config/firebase.config.js";
import "firebase/firestore";
import "firebase/storage";
import { useTheme } from "../hooks/ThemeContext";
import constant from "../../../constants/constMap.js";
import { setCanvasPreview } from "./utils/utils.js";
import ReactCrop, {
    makeAspectCrop,
    centerCrop,
    convertToPixelCrop,
} from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

const ASPECT_RATIO = 1;
const MIN_DIMENSION = 150;

const UploadImage = ({ imgChange, title, user, fontSize }) => {
    const { themeContent, theme } = useTheme();
    const [colors, setColors] = useState(themeContent);
    useEffect(() => {
        setColors(themeContent);
    }, [themeContent, theme]);

    const collection = constant.collectionUser;

    const [crop, setCrop] = useState();
    const imgRef = useRef(null);
    const previewCanvasRef = useRef(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (user) {
                    const docRef = db.collection(collection).doc(user);
                    const docSnapshot = await docRef.get();
                    if (docSnapshot.exists) {
                        const userData = docSnapshot.data();
                    } else {
                        // console.log('Personaliza: No existe el usuario.')
                    }
                }
            } catch (error) {
                console.error(
                    "Fondo: Error al obtener la imagen de Firestore: ",
                    error,
                );
            }
        };

        fetchData();
    }, []);

    const [imgSrc, setImgSrc] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {}, [setLoading]);

    const handleImageSave = () => {
        setLoading(true);
        const canvas = previewCanvasRef.current;

        if (canvas) {
            const dataUrl = canvas.toDataURL();

            const storageRef = storage.ref(`${user}/images/Avatar.svg`);
            storageRef
                .putString(dataUrl, "data_url")
                .then((snapshot) => {
                    snapshot.ref.getDownloadURL().then((downloadURL) => {
                        db.collection(collection)
                            .doc(user)
                            .set(
                                {
                                    datos: {
                                        logo: downloadURL,
                                    },
                                },
                                { merge: true },
                            )
                            .then(() => {
                                db.collection(collection)
                                    .doc(user)
                                    .set(
                                        {
                                            perfil: {
                                                img: downloadURL,
                                            },
                                        },
                                        { merge: true },
                                    )
                                    .then(() => {
                                        console.log(
                                            "Imagen guardada correctamente",
                                        );
                                        setLoading(false);
                                        setImgSrc("");
                                        window.location.reload();
                                    })
                                    .catch((error) => {
                                        console.error(
                                            "Error al guardar la URL en Firestore: ",
                                            error,
                                        );
                                    });
                            })
                            .catch((error) => {
                                console.error(
                                    "Error al guardar la URL en Firestore: ",
                                    error,
                                );
                            });
                    });
                })
                .catch((error) => {
                    console.error(
                        "Error al subir la imagen recortada: ",
                        error,
                    );
                });
        }
    };

    const onSelectedFile = (e) => {
        const file = e.target.files?.[0];
        if (!file) return;

        const reader = new FileReader();
        reader.addEventListener("load", () => {
            const imageElement = new Image();
            const imageUrl = reader.result?.toString() || "";
            imageElement.src = imageUrl;

            imageElement.addEventListener("load", (e) => {
                const { naturalWidth, naturalHeight } = e.currentTarget;
                if (
                    naturalHeight < MIN_DIMENSION ||
                    naturalWidth < MIN_DIMENSION
                ) {
                    alert("La imagen es muy pequeña");
                    return setImgSrc("");
                }
            });

            setImgSrc(imageUrl);
        });
        reader.readAsDataURL(file);
    };

    const onImgLoad = (e) => {
        const { width, height } = e.currentTarget;
        const cropWidthPercent = (MIN_DIMENSION / width) * 100;

        const crop = makeAspectCrop(
            {
                unit: "%",
                width: cropWidthPercent,
            },
            ASPECT_RATIO,
            width,
            height,
        );
        const centeredCrop = centerCrop(crop, width, height);
        setCrop(centeredCrop);
    };

    return (
        <>
            <div className="d-flex justify-content-space-between">
                <div className="d-flex align-items-center justfy-content-center">
                    <label className="file-select d-flex">
                        <img src={imgChange} alt={title} />
                        <P $fontSize={fontSize} $col={colors.onSurface}>
                            {title}
                        </P>
                        <Input
                            className="file-input"
                            accept="image/*"
                            type="file"
                            onChange={onSelectedFile}
                        />
                    </label>
                    {imgSrc && (
                        <CropContainer $bgCol={colors.surface}>
                            <ReactCrop
                                crop={crop}
                                onChange={(percentCrop) => setCrop(percentCrop)}
                                circularCrop
                                keepSelection
                                aspect={ASPECT_RATIO}
                                minWidth={MIN_DIMENSION}
                            >
                                <img
                                    ref={imgRef}
                                    src={imgSrc}
                                    alt="Upload"
                                    style={{
                                        width: "100%",
                                        height: "100%",
                                        maxHeight: "600px",
                                        maxWidth: "600px",
                                    }}
                                    onLoad={onImgLoad}
                                />
                            </ReactCrop>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    width: "100%",
                                    gap: "0 10px",
                                }}
                            >
                                <Button
                                    $bgCol={colors.surface}
                                    $col={colors.onSurface}
                                    onClick={() => setImgSrc("")}
                                >
                                    Cancelar
                                </Button>
                                <Button
                                    $bgCol={colors.primary}
                                    $col={colors.onPrimary}
                                    onClick={() => {
                                        setCanvasPreview(
                                            imgRef.current,
                                            previewCanvasRef.current,
                                            convertToPixelCrop(
                                                crop,
                                                imgRef?.current.width,
                                                imgRef?.current.height,
                                            ),
                                        );
                                        handleImageSave();
                                    }}
                                >
                                    Cargar
                                </Button>
                            </div>
                            {loading ? <Loading height="auto" width="auto" /> : null}
                        </CropContainer>
                    )}
                    {crop && (
                        <canvas
                            ref={previewCanvasRef}
                            style={{
                                display: "none",
                            }}
                        />
                    )}
                </div>
            </div>
        </>
    );
};

const CropContainer = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    background-color: ${(props) => props.$bgCol};
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.3);
    height: auto;
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (max-width: 768px) {
        top: -300px;
        
    }
`;

const P = styled.p`
    font-size: ${(props) => props.$fontSize};
    color: ${(props) => props.$col};
    margin: 0;
`;

const Input = styled.input`
    margin-right: 20px;
`;

const Button = styled.button`
    background-color: ${(props) => props.$bgCol};
    color: ${(props) => props.$col};
    border: 1px solid ${(props) => props.$col};
    max-width: 221px;
    width: 100%;
    padding: 10px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    cursor: pointer;
    margin-top: 10px;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
`;

export default UploadImage;

{
    /* {uploadProgress > 0 && uploadProgress < 100 ? (
                        <div
                            className="progress-circle"
                            style={{ width: "30px", margin: "0 10px" }}
                        >
                            <CircularProgressbar
                                value={uploadProgress}
                                text={`${Math.round(uploadProgress)}%`}
                                styles={buildStyles({
                                    rotation: 0.25,
                                    strokeLinecap: "butt",
                                    textSize: "30px",
                                    pathTransitionDuration: 0.5,
                                })}
                            />
                        </div>
                    ) : null} */
}

{
    /* {imageUrl ? (
                    <Div>
                        <Img
                            style={{ margin: "0", width: "100%" }}
                            src={imageUrl}
                            alt="Imagen subida"
                        />
                    </Div>
                ) : null} */
}


    // const handleImageUpload = (e) => {
    //     const file = e.target.files[0];

    //     if (file) {
    //         const allowedExtensions = ["jpg", "jpeg", "png"];
    //         const fileExtension = file.name.split(".").pop().toLowerCase();

    //         if (allowedExtensions.includes(fileExtension)) {
    //             console.log("fileExtension: ", fileExtension);

    //             const storageRef = storage.ref(`${user}/images/Avatar.svg`);
    //             const uploadTask = storageRef.put(file);

    //             uploadTask.on(
    //                 "state_changed",
    //                 (snapshot) => {
    //                     const progress =
    //                         (snapshot.bytesTransferred / snapshot.totalBytes) *
    //                         100;

    //                     setUploadProgress(progress);
    //                 },
    //                 (error) => {
    //                     console.error("Error al subir la imagen: ", error);
    //                 },
    //                 () => {
    //                     uploadTask.snapshot.ref
    //                         .getDownloadURL()
    //                         .then((downloadURL) => {
    //                             setImageUrl(downloadURL);

    //                             db.collection(collection)
    //                                 .doc(user)
    //                                 .set(
    //                                     {
    //                                         datos: {
    //                                             logo: downloadURL,
    //                                         },
    //                                     },
    //                                     { merge: true },
    //                                 )
    //                                 .then(() => {
    //                                     db.collection(collection)
    //                                         .doc(user)
    //                                         .set(
    //                                             {
    //                                                 perfil: {
    //                                                     img: downloadURL,
    //                                                 },
    //                                             },
    //                                             { merge: true },
    //                                         );
    //                                     window.location.reload();
    //                                 })
    //                                 .catch((error) => {
    //                                     console.error(
    //                                         "Error al guardar la URL en Firestore: ",
    //                                         error,
    //                                     );
    //                                 });
    //                         });
    //                 },
    //             );
    //         } else {
    //             alert(
    //                 "Por favor, selecciona un archivo con extensión .jpg, .jpeg o .png",
    //             );
    //         }
    //     }
    // };