import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useTheme } from "../hooks/ThemeContext";

const Compartir = ({
    btnContent,
    href,
    setText = "Copiado",
    type = "copy",
    onClick,
}) => {
    const [copied, setCopied] = useState(btnContent);

    const { themeContent, theme } = useTheme();
    const [colors, setColors] = useState(themeContent);
    useEffect(() => {
        setColors(themeContent);
    }, [themeContent, theme]);

    const copyToClipboard = (e) => {
        e.preventDefault();
        navigator.clipboard
            .writeText(href)
            .then(() => {
                setCopied(setText);
            })
            .catch((err) => {
                console.error("Error al copiar al portapapeles: ", err);
            });
    };

    if (copied === setText) {
        setTimeout(() => {
            setCopied(btnContent);
        }, 3000);
    }

    useEffect(() => {
        setCopied(btnContent);
    }, [btnContent]);

    return (
        <StyledButton
            color={colors.onPrimary}
            $bg={colors.onSurface}
            onClick={type === "copy" ? copyToClipboard : onClick}
            $bgh={colors.onSurface}
        >
            {copied}
        </StyledButton>
    );
};

const StyledButton = styled.button`
    border: none;
    color: ${(props) => props.color};
    background-color: ${(props) => props.$bg};
    padding: 4px 10px;
    border-radius: 4px;
    gap: 10px;
    width: 100px;
    &:hover {
        background-color: ${(props) => props.$bgh};
    }

`;

export default Compartir;

    

    