import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Container } from "react-bootstrap";
import Slider from "react-slick";
import { useTheme } from "../hooks/ThemeContext";

const SimpleSlider = ({ Content, onSelect, tema, isMobile }) => {
    const { themeContent, theme } = useTheme();
    const [colors, setColors] = useState(themeContent);
    useEffect(() => {
        setColors(themeContent);
    }, [themeContent, theme]);

    const settings = {
        className: "center",
        infinite: false,
        centerPadding: "20px",
        slidesToShow: 4,
        swipeToSlide: false,
        slidesToScroll: 4,
    };

    const [selectedItemIndex, setSelectedItemIndex] = useState(null);

    const handleSelect = (item, index) => {
        onSelect(item);
        setSelectedItemIndex(index);
    };

    const getBorderStyle = (index) => {
        if (index === selectedItemIndex) {
            return `2px solid ${colors.primary}`;
        } else {
            return `2px solid ${colors.onBackground}`;
        }
    };

    useEffect(() => {
        if (tema === false) {
            setSelectedItemIndex(null);
        }
    }, [tema]);

    useEffect(() => {
        return;
    }, [isMobile]);

    return (
        <Container style={{ width: "100%" }}>
            {isMobile && (
                <Slider {...settings}>
                    {Content.map((item, index) => {
                        return (
                            <StyledDiv
                                key={index}
                                onClick={() => handleSelect(item, index)}
                            >
                                <StyledImg
                                    src={item.img}
                                    alt={item.title}
                                    border={getBorderStyle(index)}
                                />
                                <StyledP color={colors.onSurface}>
                                    {item.title}
                                </StyledP>
                            </StyledDiv>
                        );
                    })}
                </Slider>
            )}
            {!isMobile && (
                <DesktopContainer>
                    {Content.map((item, index) => {
                        return (
                            <StyledDiv
                                key={index}
                                onClick={() => handleSelect(item, index)}
                            >
                                <StyledImg
                                    src={item.img}
                                    alt={item.title}
                                    border={getBorderStyle(index)}
                                />
                                <StyledP color={colors.onSurface}>
                                    {item.title}
                                </StyledP>
                            </StyledDiv>
                        );
                    })}
                </DesktopContainer>
            )}
        </Container>
    );
};

const StyledDiv = styled.div`
    display: flex;
    margin: 0rem 1rem;
    
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;

const StyledImg = styled.img`
    max-width: 79px; /* Puedes eliminar esta línea si no necesitas un ancho máximo */
    aspect-ratio: 1/1.82; /* Mantén el aspect-ratio si necesitas mantener la proporción */
    border-radius: 8px;
    border: ${(props) => props.border};
    box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.2);
    
`;
const StyledP = styled.p`
    padding: 5px;
    font-size: 0.8rem;
    text-align: center;
    color: ${(props) => props.color};
`;

const DesktopContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 0rem;

    @media (max-width: 1600px) {
        grid-template-columns: repeat(4, 1fr);
    }

    @media (max-width: 1300px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: 1100px) {
        grid-template-columns: repeat(2, 1fr);
    }
`;

export default SimpleSlider;
