import React, { useState, useEffect } from "react";
import Content from "../../../content/es/dashboardContent.json";
import { useTheme } from "../hooks/ThemeContext";
const logo = Content.Header.logo;
const logow = Content.Header.logoW;

const Logo = () => {

    const { themeContent, theme } = useTheme();
    const [src, setSrc] = useState(logo);
    useEffect(() => {
        setSrc(theme === "light" ? logo : logow);
    }, [themeContent, theme]);

    return (
        <div>
            <img src={src} alt="" />
        </div>
    );
};


export default Logo;
