import React, { createContext, useState, useContext, useEffect } from "react";
import materialTheme from "../../../theme/theme";

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {

    const light = materialTheme.schemes.light
    const dark = materialTheme.schemes.dark

    const [themeContent, setThemeContent] = useState(light)

    const [theme, setTheme] = useState(
        localStorage.getItem("theme") || "light",
    );

    useEffect(() => {
        localStorage.setItem("theme", theme);
        document.body.className = theme;
        setThemeContent(theme === "light" ? light : dark)
    }, [theme]);

    const toggleTheme = () => {
        setTheme((prevTheme) => (prevTheme === "light" ? "dark" : "light"));
    };

    return (
        <ThemeContext.Provider value={{ theme, toggleTheme, themeContent }}>
            {children}
        </ThemeContext.Provider>
    );
};

export const useTheme = () => useContext(ThemeContext);
