import { db } from "../../../config/firebase.config.js";
import React, { useState, useEffect } from "react";
import Perfil from "../components/Perfil";
import ColorPicker from "../common/ColorPicker";
import constant from "../../../constants/constMap.js";
import { Container } from "react-bootstrap";
import OptionEnable from "../common/OptionEnable.jsx";
import {
    CardSkeleton,
    AvatarSkeleton,
    TitleSubtitleSkeleton,
} from "../common/Skeleton";
import Section from "../common/Section.jsx";
import { useDefaultDB } from "../hooks/RealTimeContext.jsx";
import { useLang } from "../hooks/LangContext";

const Header = ({ user }) => {

    const { langContent, lang } = useLang();
    const [content, setContent] = useState(langContent);
    useEffect(() => {
        setContent(langContent);
    }, [langContent, lang]);

    const waterMark = content.Header.modalbuttons.waterMark;

    const collection = constant.collectionUser;

    const [userData, setUserData] = useState(null);
    const [headerData, setHeaderData] = useState([]);

    const {DB, setDB} = useDefaultDB();

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (user) {
                    const docRef = db.collection(collection).doc(user);
                    const docSnapshot = await docRef.get();
                    if (docSnapshot.exists) {
                        const userData = docSnapshot.data();
                        setUserData(userData);

                        const header = [
                            {
                                text: userData.datos.titulo,
                                color: userData.styles.titulo.colorTexto,
                                enable:
                                    userData.datos.titulo !== "" ? true : false,
                            },
                            {
                                text: userData.datos.subtitulo,
                                color: userData.styles.subtitulo.colorTexto,
                                enable:
                                    userData.datos.subtitulo !== ""
                                        ? true
                                        : false,
                            },
                            {
                                text: userData.datos.descripcion,
                                color: userData.styles.descripcion.colorTexto,
                                enable:
                                    userData.datos.descripcion !== ""
                                        ? true
                                        : false,
                            },
                            // { text: "Etiqueta", color: userData.styles.etiqueta.colorTexto, enable: userData.datos.etiqueta !== "" ? true : false }
                        ];

                        setHeaderData(header);
                    } else {
                        // console.log('Header: No existe el usuario.')
                    }
                }
            } catch (error) {
                console.error(
                    "Header: Error al obtener la imagen de Firestore: ",
                    error,
                );
            }
        };

        fetchData();
    }, [user]);

    useEffect(() => {
        const updateData = async () => {
            if (headerData && headerData.length >= 3) {
                const userRef = db.collection(collection).doc(user);
                const updatedUserData = { ...userData };

                updatedUserData.datos.titulo = headerData[0].enable
                    ? headerData[0].text
                    : "";
                updatedUserData.datos.subtitulo = headerData[1].enable
                    ? headerData[1].text
                    : "";
                updatedUserData.datos.descripcion = headerData[2].enable
                    ? headerData[2].text
                    : "";

                updatedUserData.styles.titulo.colorTexto = headerData[0].color;
                updatedUserData.styles.subtitulo.colorTexto =
                    headerData[1].color;
                updatedUserData.styles.descripcion.colorTexto =
                    headerData[2].color;

                // try {
                //     await userRef.set(updatedUserData, { merge: true });
                // } catch (error) {
                //     console.error("Error al actualizar los datos:", error);
                // }
            }
        };
        updateData();
    }, [headerData, userData]);

    if (!headerData) {
        return null;
    }

    const handleColorChange = (index, newColor) => {
        const newData = [...headerData];
        newData[index].color = newColor;
        setHeaderData(newData);

        setDB({...DB, styles: {...DB.styles, [index === 0 ? "titulo" : index === 1 ? "subtitulo" : "descripcion"]: {colorTexto: newColor}}});

    };

    const handleTextChange = (index, newText) => {
        const newData = [...headerData];
        newData[index].text = newText;
        setHeaderData(newData);
        
        setDB({...DB, datos: {...DB.datos, [index === 0 ? "titulo" : index === 1 ? "subtitulo" : "descripcion"]: newText}});
        
    };

    const handleToggleChange = (index, newEnable) => {
        const newData = [...headerData];
        newData[index].enable = newEnable;
        setHeaderData(newData);

        setDB({...DB, datos: {...DB.datos, [index === 0 ? "titulo" : index === 1 ? "subtitulo" : "descripcion"]: newEnable ? newData[index].text : ""}});
    };

    const titles = ["Titulo", "Subtitulo", "Descripcion"];

    return (
        <Container>
            {headerData.length > 0 && user ? (
                <>
                    <Perfil user={user} />
                    <Section>
                        {headerData.map((data, index) => (
                            <ColorPicker
                                key={index}
                                text="Color"
                                value={data.text}
                                defaultColor={data.color}
                                setTrue={
                                    data === "Etiqueta" ? false : data.enable
                                }
                                onColorChange={(color) =>
                                    handleColorChange(index, color)
                                }
                                onTextChange={(text) =>
                                    handleTextChange(index, text)
                                }
                                onToggleChange={(enable) =>
                                    handleToggleChange(index, enable)
                                }
                                usable={
                                    data.text === "Descripcion" ? true : true
                                }
                                padding={
                                    data.text === "Descripcion" ? "40px" : ""
                                }
                                textArea="textArea"
                                placeholder={titles[index]}
                            />
                        ))}
                        <OptionEnable
                            text={waterMark.text}
                            text2={waterMark.text2}
                            showToggle={false}
                            disable={true}
                            img={waterMark.img}
                        ></OptionEnable>
                    </Section>
                </>
            ) : (
                <div className="d-flex flex-column align-items-center">
                    <TitleSubtitleSkeleton />
                    <br />
                    <AvatarSkeleton />
                    <br />
                    <br />
                    <CardSkeleton count={4}  />
                </div>
            )}
        </Container>
    );
};

export default Header;
