import React, { useState, useEffect } from "react";
import Title from "../common/TitleSection";
import styled from "styled-components";
import Section from "../common/Section";
import { useTheme } from "../hooks/ThemeContext.jsx";
import { useLang } from "../hooks/LangContext";


const Account = () => {
    const { themeContent, theme } = useTheme();
    const [colors, setColors] = useState(themeContent);
    
    useEffect(() => {
        setColors(themeContent);
    }, [themeContent, theme]);
    
    const { langContent, lang } = useLang();
    const [content, setContent] = useState(langContent);
    useEffect(() => {
        setContent(langContent);
    }, [langContent, lang]);
    
    const ContentWallet = content.Wallet;

    const [selectedInput, setSelectedInput] = useState(1);
    const handleCheckboxChange = (index) => {
        setSelectedInput(index);
    };

    return (
        <Container>
            <Section>
                <Title
                    size="1.5rem"
                    title={ContentWallet.title}
                    align="center"
                />
            </Section>
            <Section>
                <Title title={ContentWallet.pago.title} />
                <Form>
                    {ContentWallet.pago.options.map((input, index) => (
                        <>
                            <div>
                                <Input
                                    type="radio"
                                    value={input}
                                    id={input}
                                    checked={selectedInput === index}
                                    onChange={() => handleCheckboxChange(index)}
                                    $acc={colors.primary}
                                />
                                <Label $col={colors.onSurface} htmlFor={input}>{input}</Label>
                            </div>
                            {input === "Tarjeta de crédito" || input === "Credit card" ? (
                                <Form>
                                    <ContFlex>
                                        {ContentWallet.pago.inputs.map(
                                            (input, index) => (
                                                <InputCard
                                                    width={
                                                        index === 2
                                                            ? "55%"
                                                            : index === 3
                                                              ? "35%"
                                                              : "100%"
                                                    }
                                                    placeholder={input}
                                                    type={
                                                        index === 3
                                                            ? "password"
                                                            : index === 1
                                                              ? "number"
                                                              : index === 2
                                                                ? "month"
                                                                : "text"
                                                    }
                                                />
                                            ),
                                        )}
                                    </ContFlex>
                                </Form>
                            ) : null}
                        </>
                    ))}
                </Form>
            </Section>
            {/* <Section>
                <Title title={ContentWallet.detalles.title} />
                <Tema />
            </Section> */}
        </Container>
    );
};

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
`;

const Form = styled.form`
    display: flex;
    width: 100%;
    flex-direction: column;
    margin: 1rem 0;
`;

const ContFlex = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
`;

const Input = styled.input`
    width: 15px;
    aspect-ratio: 1/1;
    margin-right: 10px;
    color: ${(props) => props.$col};

    &:checked {
        accent-color: ${(props) => props.$acc};
    }
`;

const Label = styled.label`
    color: ${(props) => props.$col};
`;

const InputCard = styled.input`
    width: ${(props) => (props.width ? props.width : "100%")};
    height: 3rem;
    border-radius: 5px;
    border: none;
    border: 1px solid #cccccc;
    margin: 0 0.5rem 1rem 0;
    padding-left: 1rem;
    background-color: transparent;
    color: #6b6b6b;
    font-weight: regular;
    text-align: left;
`;

export default Account;
