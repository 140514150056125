import { db } from "../../../config/firebase.config.js";
import React, { useState, useEffect, useRef } from "react";
import styled, { keyframes } from "styled-components";

import MockupContainer from "./MockupContainer.jsx";
import { useTheme } from "../hooks/ThemeContext.jsx";
import constant from "../../../constants/constMap.js";
import { Nav } from "react-bootstrap";
import { CardSkeleton } from "./Skeleton.jsx";
import ButtonRappid from "./ButtonRappid.jsx";
import { useDefaultDB } from "../hooks/RealTimeContext.jsx";
import { useLang } from "../hooks/LangContext";
import { Content } from "antd/lib/layout/layout.js";

const ViewMockup = ({ user, isMobile }) => {
    const { themeContent, theme } = useTheme();
    const [colors, setColors] = useState(themeContent);
    useEffect(() => {
        setColors(themeContent);
    }, [themeContent, theme]);

    const { langContent, lang } = useLang();
    const [content, setContent] = useState(langContent);

    const collection = constant.collectionUser;

    const [userData, setUserData] = useState(null);

    const [showIcons, setShowIcons] = useState(false);

    const [showMobile, setShowMobile] = useState(false);
    const [showTablet, setShowTablet] = useState(false);
    const [showLaptop, setShowLaptop] = useState(false);

    const { DB } = useDefaultDB();

    const [loading, setLoading] = useState(false);
    const [updated, setUpdated] = useState(content.Views.buttonUpdate.updated);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (user) {
                    const docRef = db.collection(collection).doc(user);
                    const docSnapshot = await docRef.get();
                    if (docSnapshot.exists) {
                        const userData = docSnapshot.data();
                        setUserData(userData);
                    } else {
                        // console.log('Fondo: No existe el usuario.')
                    }
                }
            } catch (error) {
                console.error(
                    "Fondo: Error al obtener la imagen de Firestore: ",
                    error,
                );
            }
        };

        fetchData();
    }, [user]);

    const handleClick = () => {
        setShowIcons(!showIcons);
    };

    const handleMockup = (i) => {
        switch (i) {
            case 0:
                setShowMobile(!showMobile);
                setShowTablet(false);
                setShowLaptop(false);
                break;
            case 1:
                setShowTablet(!showTablet);
                setShowMobile(false);
                setShowLaptop(false);
                break;
            case 2:
                setShowLaptop(!showLaptop);
                setShowMobile(false);
                setShowTablet(false);
                break;
            default:
                setShowMobile(false);
                setShowTablet(false);
                setShowLaptop(false);
        }
    };

    const mockupRef = useRef();
    const iconsContainerRef = useRef();
    const iconsHandlerRef = useRef();

    const handleClickOutside = (event) => {
        if (
            mockupRef.current &&
            mockupRef.current.contains(event.target) &&
            isMobile
        ) {
            setShowIcons(false);
            setShowMobile(false);
            setShowTablet(false);
            setShowLaptop(false);
        }

        if (
            (iconsContainerRef.current &&
                iconsContainerRef.current.contains(event.target)) ||
            (iconsHandlerRef.current &&
                iconsHandlerRef.current.contains(event.target))
        ) {
            return;
        } else {
            setShowIcons(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [mockupRef]);

    const bottom = (i) => {
        return `${i * 70}px`;
    };

    const icons = theme === "light" ? content.Views.light : content.Views.dark;

    const show = [
        { show: showMobile, type: "showMobile" },
        { show: showTablet, type: "showTablet" },
        { show: showLaptop, type: "showLaptop" },
    ];

    let startIndex = 0;

    const [active, setActive] = useState(false);
    const [activeIndex, setActiveIndex] = useState(startIndex);

    const handleChange = (index) => {
        setActive(!active);
        setActiveIndex(index);
        switch (index) {
            case 0:
                setShowMobile(true);
                setShowTablet(false);
                setShowLaptop(false);
                break;
            case 1:
                setShowTablet(true);
                setShowMobile(false);
                setShowLaptop(false);
                break;
            case 2:
                setShowLaptop(true);
                setShowMobile(false);
                setShowTablet(false);
                break;
            default:
                setShowMobile(false);
                setShowTablet(false);
                setShowLaptop(false);
        }
    };

    const buttonImg = !showIcons
        ? theme === "light"
            ? "/img/svg/showW.svg"
            : "/img/svg/showD.svg"
        : theme === "light"
          ? "/img/svg/x-dark.svg"
          : "/img/svg/x.svg";

    const FirstNavLink = {
        padding: "0.5rem 1.5rem 0 1rem",
        border: `1px solid ${colors.onSurface}`,
        color: colors.onSurface,
        borderRadius: "50px 0 0 50px",
        boxShadow: "0px 4px 5px 0px rgba(0, 0, 0, 0.25)",
    };

    const NavLinkLast = {
        padding: "0.5rem 1.5rem 0 1rem",
        color: colors.onSurface,
        width: "25%",
        textAlign: "center",
        border: `1px solid ${colors.onSurface}`,
        borderRadius: "0 50px 50px 0",
        boxShadow: "0px 4px 5px 0px rgba(0, 0, 0, 0.25)",
    };

    const NavLinkStyle = {
        border: `1px solid ${colors.onSurface}`,
        borderRight: "none",
        padding: "0.5rem 0.2rem",
        color: colors.onSurface,
        width: "25%",
        textAlign: "center",
        boxShadow: "0px 4px 5px 0px rgba(0, 0, 0, 0.25)",
    };

    const getStyle = (index) => {
        const isFirst = index === 0;
        const isLast = index === pages.length - 1;
        const isActive = index === activeIndex;

        const bgActive = colors.secondaryContainer;
        const bgInactive = "";

        let linkStyle =
            isActive && isLast
                ? {
                      ...NavLinkLast,
                      backgroundColor: bgActive,
                  }
                : isActive && isFirst
                  ? {
                        ...FirstNavLink,
                        ...NavLinkStyle,
                        backgroundColor: bgActive,
                    }
                  : isActive
                    ? {
                          ...NavLinkStyle,
                          backgroundColor: bgActive,
                      }
                    : isLast
                      ? {
                            ...NavLinkLast,
                            backgroundColor: bgInactive,
                        }
                      : isFirst
                        ? {
                              ...FirstNavLink,
                              ...NavLinkStyle,
                              backgroundColor: bgInactive,
                          }
                        : {
                              ...NavLinkStyle,
                              backgroundColor: bgInactive,
                          };

        return linkStyle;
    };

    const pages = [
        {
            light: "/img/svg/mobile.svg",
            dark: "/img/svg/mobile-dark.svg",
        },
        {
            light: "/img/svg/tablet.svg",
            dark: "/img/svg/tablet-dark.svg",
        },
        {
            light: "/img/svg/laptop.svg",
            dark: "/img/svg/laptop-dark.svg",
        },
    ];

    useEffect(() => {
        if (DB) {
            setUpdated(content.Views.buttonUpdate.update);
        }
    }, [DB]);

    useEffect(() => {
        if (!isMobile) {
            handleChange(0);
        }
        setUpdated(content.Views.buttonUpdate.updated);
    }, []);


    const handleSaveData = async () => {
        const dbData = DB;
        setLoading(true);
        try {
            await db.collection(collection).doc(user).update({
                datos: dbData.datos,
                styles: dbData.styles,
            });
        } catch (error) {
            console.error("Error al guardar los datos: ", error);
        }
        setLoading(false);
        setUpdated(content.Views.buttonUpdate.updated);
    };

    return (
        <>
            {isMobile && (
                <>
                    {(showMobile || showLaptop || showTablet) && (
                        <>
                            <Background onClick={handleClickOutside} />
                            <StyledDiv ref={mockupRef}>
                                <div>
                                    {show.map((item, i) => {
                                        return (
                                            item.show && (
                                                <MockupContainer
                                                    key={i}
                                                    type={item.type}
                                                    user={user}
                                                />
                                            )
                                        );
                                    })}
                                </div>
                            </StyledDiv>
                        </>
                    )}
                    <>
                        {showIcons && (
                            <IconsContainer ref={iconsContainerRef}>
                                {icons.map((icon, i) => (
                                    <StyledButton
                                        key={i}
                                        width={"40px"}
                                        $bott={bottom(i + 2)}
                                        $bg={colors.primary}
                                        $ri={"27px"}
                                        onClick={() => handleMockup(i)}
                                    >
                                        <img src={icon.src} alt={icon.alt} />
                                    </StyledButton>
                                ))}
                            </IconsContainer>
                        )}
                        <StyledButton
                            width={"56px"}
                            $bott={bottom(1)}
                            $bg={colors.primary}
                            onClick={handleClick}
                            ref={iconsHandlerRef}
                        >
                            <img src={buttonImg} />
                        </StyledButton>
                        <ButtonRappid
                            text={updated}
                            onClick={handleSaveData}
                            loading={loading}
                        />
                    </>
                </>
            )}

            {!isMobile && (
                <>
                    <MockupController className="w-100">
                        <div
                            className="d-flex w-100 justify-content-center mt-5"
                            style={{ maxWidth: "300px", minWidth: "300px"}}
                        >
                            {pages.map((page, index) => {
                                const linkStyle = getStyle(index);
                                return (
                                    <Nav.Link
                                        key={index}
                                        style={linkStyle}
                                        href="#"
                                        onClick={() => {
                                            handleChange(index);
                                        }}
                                    >
                                        <img
                                            src={
                                                theme === "dark"
                                                    ? page.light
                                                    : page.dark
                                            }
                                        />
                                    </Nav.Link>
                                );
                            })}
                        </div>
                        <UpdateContainer className="d-flex justify-content-center mt-5">
                            <ButtonRappid
                                text={updated}
                                onClick={handleSaveData}
                                loading={loading}
                            />
                        </UpdateContainer>
                    </MockupController>
                    {(showMobile || showLaptop || showTablet) && (
                        <>
                            {show.map((item, i) => {
                                return (
                                    <>
                                        {userData
                                            ? item.show && (
                                                  <MockupContainer
                                                      key={i}
                                                      type={item.type}
                                                      user={user}
                                                  />
                                              )
                                            : item.show && (
                                                  <div
                                                      style={{
                                                          marginTop: "80px",
                                                      }}
                                                  >
                                                      <CardSkeleton
                                                          count={1}
                                                          height={614}
                                                          width={256}
                                                      />
                                                  </div>
                                              )}
                                    </>
                                );
                            })}
                        </>
                    )}
                </>
            )}
        </>
    );
};

const Background = styled.div`
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
    backdrop-filter: blur(2px);
`;

const MockupController = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    z-index: 2;
`;

const UpdateContainer = styled.div`

`;

const slideUp = keyframes`
    from {
        transform: translateY(20px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
`;

const StyledButton = styled.button`
    position: fixed;
    bottom: ${(props) => props.$bott};
    width: ${(props) => props.width};
    aspect-ratio: 1/1;
    right: ${(props) => (props.$ri ? props.$ri : "20px")};
    border: none;
    background-color: ${(props) => props.$bg};
    padding: 5px 10px;
    border-radius: 12px;
    cursor: pointer;
    animation: ${slideUp} 0.5s ease-out;
    animation-delay: 0;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const IconsContainer = styled.div`
    position: fixed;
    z-index: 30;
    bottom: 120px;
    right: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const StyledDiv = styled.div`
    @media (max-width: 767px) {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 25;
    }

    @media (min-width: 768px) {
        position: fixed;
        top: ${(props) => props.$top};
        display: flex;
        justify-content: center;
        align-items: center;
        height: 800px;
    }
`;

export default ViewMockup;
