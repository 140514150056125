import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Avatar from "../common/Avatar";
import ButtonRappid from "./ButtonRappid";
import { Container, Row, Col } from "react-bootstrap";
import { useLang } from "../hooks/LangContext";

const Card = ({ title, subtitle, colors, onClickButton }) => {

    const { langContent, lang } = useLang();
    const [content, setContent] = useState(langContent);
    useEffect(() => {
        setContent(langContent);
    }, [langContent, lang]);

    return (
        <>
            <StyledCard $bg={colors.surface}>
                <Container>
                    <Row>
                        <Col xs={2}>
                            <Avatar />
                        </Col>
                        <Col xs={8}>
                            <StyledTitle $col={colors.onSurface}>{title}</StyledTitle>
                            <StyledSubtitle $col={colors.onSurface}>{subtitle}</StyledSubtitle>
                        </Col>
                    </Row>
                    <Row>
                        <ButtonRappid text={content.Account.new} onClick={onClickButton}/>
                    </Row>
                </Container>
            </StyledCard>
        </>
    );
};

const StyledCard = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    background-color: ${(props) => props.$bg};
    border-radius: 10px;
    padding: 1rem;
`;

const StyledTitle = styled.h2`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    color: ${(props) => props.$col};
    font-weight: bold;
`;

const StyledSubtitle = styled.p`
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 0.8rem;
    color: ${(props) => props.$col};
    font-weight: bold;
`;

export default Card;
