import React, { useState, useEffect } from "react";
import UploadAvatar from "./UploadAvatar";
import styled from "styled-components";
import Content from "../../../content/es/dashboardContent.json";
import { useTheme } from "../hooks/ThemeContext";

const ContentBottom = Content.BottomSheet.picture;

const HandlePicture = ({ user, onDeletePicture }) => {
    const { themeContent, theme } = useTheme();
    const [colors, setColors] = useState(themeContent);
    useEffect(() => {
        setColors(themeContent);
    }, [themeContent, theme]);
    

    return (
        <Ul>
            <Li>
                <UploadAvatar
                    user={user}
                    imgChange={theme === 'light' ? ContentBottom[1].img : ContentBottom[1].imgDark}
                    title={ContentBottom[1].title}
                    fontSize={"16px"}
                />
            </Li>
            <Li onClick={onDeletePicture}>
                <img src={theme === 'light' ? ContentBottom[2].img : ContentBottom[2].imgDark} alt={ContentBottom[2].title} />
                <p
                    style={{
                        color: colors.error,
                        margin: "0",
                        fontSize: "16px",
                    }}
                >
                    {ContentBottom[2].title}
                </p>
            </Li>
        </Ul>
    );
};

const Ul = styled.ul`
    padding: 0;
    margin: 0;
`;



const Li = styled.li`
    width: 100%;
    display: flex;
    align-items: center;
    padding: 6px 0px;
    margin: 0px 0px;
    border-radius: 50px;
    cursor: pointer;
    list-style: none;
    img {
        width: 24px;
        height: 24px;
        margin-right: 10px;
    }
    .file-select {
        position: relative;
        overflow: hidden;
        display: inline-block;
        cursor: pointer;
    }
    .file-input {
        position: absolute;
        opacity: 0;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
        display: none;
    }
`;

export default HandlePicture;

