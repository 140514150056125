import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-regular-svg-icons";

const normalColor = "#101010";
const hoverColor = "#A84AC9";

function BotonCompartir({ url, textoCopiar, textoCopiado, hover, copiado }) {

    const [color, setColor] = useState(normalColor);
    const [textoBoton, setTextoBoton] = useState(textoCopiar);
    const copiarAlPortapapeles = () => {
        navigator.clipboard
            .writeText(url)
            .then(() => {
                setTextoBoton(textoCopiado);
                setTimeout(() => setTextoBoton(textoCopiar), 3000);
            })
            .catch((err) => {
                console.error("Error al copiar texto: ", err);
            });
    };

    useEffect(() => {
        if (hover) {
            setColor(hoverColor);
        } else {
            setColor(normalColor);
        }
    }, [hover]);

    useEffect(() => {
        if (copiado) {
            copiarAlPortapapeles();
        }
    }, [copiado]);

    const Enlace = styled.a`
        font-size: 1em;
        text-align: center;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        color: ${color};
        text-decoration: none !important;

        &:hover {
            cursor: pointer;
            color: ${hoverColor};
        }
    `;

    return (
        <Enlace>
            <div>
                <FontAwesomeIcon color={color} icon={faCopy} size="1x" />
            </div>
            <div style={{ margin: "0 10px" }}>{textoBoton}</div>
        </Enlace>
    );
}

export default BotonCompartir;
