import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useTheme } from "../hooks/ThemeContext";

export const TitleSubtitleSkeleton = () => {
    
    const { themeContent, theme } = useTheme();
    const [colors, setColors] = useState(themeContent);
    useEffect(() => {
        setColors(themeContent);
    }, [themeContent, theme]);

    let colorSkeleton = [colors.surfaceContainerLow, colors.surfaceContainerLowest];

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
            }}
        >
            <div
                style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    width: "85%",
                    flexDirection: "column",
                }}
            >
                <Skeleton
                    baseColor={colorSkeleton[0]}
                    highlightColor={colorSkeleton[1]}
                    borderRadius={"5%"}
                    width={"20%"}
                    height={"20px"}
                    style={{ width: "55%" }}
                />
                <Skeleton
                    baseColor={colorSkeleton[0]}
                    highlightColor={colorSkeleton[1]}
                    borderRadius={"5%"}
                    width={"80%"}
                    height={"20px"}
                    style={{ width: "55%" }}
                />
            </div>
        </div>
    );
};

export const CardMockupSkeleton = ({ count }) => {

    const { themeContent, theme } = useTheme();
    const [colors, setColors] = useState(themeContent);
    useEffect(() => {
        setColors(themeContent);
    }, [themeContent, theme]);

    let colorSkeleton = [colors.surfaceContainerLow, colors.surfaceContainerLowest];
    
    return (
        <div style={{ display: "flex" }}>
            <Skeleton
                count={count}
                inline={true}
                baseColor={colorSkeleton[0]}
                highlightColor={colorSkeleton[1]}
                borderRadius={"5%"}
                width={"18vw"}
                height={"35vw"}
                style={{
                    maxHeight: "153px",
                    maxWidth: "79px",
                    margin: "10px",
                }}
            />
        </div>
    );
};

export const CardSkeleton = ({ count = 3, height = 130, width = 380 }) => {

    const { themeContent, theme } = useTheme();
    const [colors, setColors] = useState(themeContent);
    useEffect(() => {
        setColors(themeContent);
    }, [themeContent, theme]);

    let colorSkeleton = [colors.surfaceContainerLow, colors.surfaceContainerLowest];
    
    return (
        <Skeleton
            baseColor={colorSkeleton[0]}
            highlightColor={colorSkeleton[1]}
            borderRadius={"10px"}
            height={height}
            width={width}
            count={count}
        />
    );
};

export const AvatarSkeleton = ({width=100}) => {

    const { themeContent, theme } = useTheme();
    const [colors, setColors] = useState(themeContent);
    useEffect(() => {
        setColors(themeContent);
    }, [themeContent, theme]);

    let colorSkeleton = [colors.surfaceContainerLow, colors.surfaceContainerLowest];
    
    return (
        <Skeleton
            baseColor={colorSkeleton[0]}
            highlightColor={colorSkeleton[1]}
            borderRadius={"50%"}
            width={width}
            height={width}
        />
    );
};
