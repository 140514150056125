import React, { Suspense } from "react";
import Logo from "./Logo.jsx";
import Footer from "./Footer.jsx";
import Subtitulo from "./Subtitulo.jsx";
import Descripcion from "./Descripcion.jsx";
import Titulo from "./Titulo.jsx";
import Redes from "./Redes.jsx";
import BotonCompartir from "./Compartir.jsx";
import HeaderBackground from "./HeaderBackground.jsx";
import styled from "styled-components";
import { useState } from "react";
import Loading from "../../../dashboard/src/common/Loading";
import Enlaces from "./Enlaces.jsx";
import TarjetaCompartir from "./MenuCompartir.jsx";

export default function Home({
    data,
    styles,
    radius = "0",
    border = "none",
    showShare = true,
}) {
    const [mostrarTarjeta, setMostrarTarjeta] = useState(false);

    if (!styles || !data) {
        console.error("Styles is undefined.");
        return <div>No existe la pagina</div>;
    }

    const toggleTarjeta = () => {
        setMostrarTarjeta(!mostrarTarjeta);
    };

    return (
        <>
            <Suspense fallback={<Loading />}>
                <TarjetaCompartir
                    mostrar={mostrarTarjeta}
                    onCerrar={toggleTarjeta}
                    data={data}
                />
                <AppContainer
                    fondoApp={styles.general.backgroundColor}
                    radius={radius}
                    border={border}
                >
                    <HeaderBackground styles={styles} />

                    <div id="compartir">
                        {showShare && (
                            <BotonCompartir
                                onToggle={toggleTarjeta}
                                styles={styles}
                            />
                        )}
                    </div>
                    <header>
                        <Logo data={data} styles={styles} />
                        <Titulo data={data} styles={styles} />
                        <Subtitulo data={data} styles={styles} />
                        <Descripcion text={data.descripcion} styles={styles} />
                    </header>
                    <Main>
                        {styles.redes.position === "Arriba" ? (
                            <Redes data={data} styles={styles} />
                        ) : null}
                        <Enlaces data={data} styles={styles} />
                        {styles.redes.position === "Abajo" ? (
                            <Redes data={data} styles={styles} />
                        ) : null}
                    </Main>
                    <FooterContainer
                        fondoFooter={styles.footer.backgroundColor}
                        height={styles.footer.height}
                    >
                        {showShare && (
                            <Footer
                                data={data}
                                styles={styles}
                                fondoFooter={styles.footer.backgroundColor}
                            />
                        )}
                    </FooterContainer>
                </AppContainer>
            </Suspense>
        </>
    );
}

const AppContainer = styled.div`
    background: ${(props) => props.fondoApp};
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    text-align: center;
    display: flex;
    flex-direction: column;
    background-color: transparent;
    color: white;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    border-radius: ${(props) => props.radius};
    border: ${(props) => props.border};
    height: 100vh;
    width: 100%;
`;

const FooterContainer = styled.footer`
    background-color: transparent;
    display: flex;
    width: 100%;
    flex-direction: column;
    height: ${({ height }) => height};
    align-items: center;
    justify-content: center;
`;

const Main = styled.main`
    margin: -20px 0 12px 0;
    max-width: 90%;
`;
