import React, { createContext, useState, useContext, useEffect } from "react";
import defaultDB from "../../../constants/defaultDB";
import constantes from "../../../constants/constMap.js";
import { db } from "../../../config/firebase.config.js";

const DefaultDBContext = createContext();

export const DefaultDBProvider = ({ children, user }) => {

    const [DB, setDB] = useState(defaultDB);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const docRef = await db
                    .collection(constantes.collectionUser)
                    .doc(user)
                    .get();
    
                if (docRef.exists) {
                    const data = docRef.data();
                    console.log("set context Data:", data);
                    setDB(data);
                } else {
                    return
                }
            } catch (error) {
                console.error("Error al cargar datos:", error);
            }
        };
    
        fetchData();
    }, [user]);

    return (
        <DefaultDBContext.Provider value={{ DB, setDB }}>
            {children}
        </DefaultDBContext.Provider>
    );
};

export const useDefaultDB = () => useContext(DefaultDBContext);
