import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
// import Section from "./Section";
import ToogleButton from "./ToogleButton";
import ContentLink from "../../../content/es/dashboardContent.json";
import { isValidUrl, isValidWPNumber } from "./utils/utils";
import AlertModal from "./AlertModal.jsx";
import { useTheme } from "../hooks/ThemeContext";

const AddLinkContent = ContentLink.Links.addedLink;

const AddedLink = ({
    add = "link",
    remove,
    link,
    linkNombre,
    showLink,
    onLinkChange,
    onSocialChange,
    index,
    socials,
}) => {
    const { themeContent, theme } = useTheme();
    const [colors, setColors] = useState(themeContent);
    useEffect(() => {
        setColors(themeContent);
    }, [themeContent, theme]);

    const titleRef = useRef(null);
    const urlRef = useRef(null);
    const socialRef = useRef(null);

    const [title, setTitle] = useState(linkNombre);
    const [isEditingTitle, setIsEditingTitle] = useState(false);
    const [editedTitle, setEditedTitle] = useState("");

    const [url, setUrl] = useState(link);
    const [isEditingUrl, setIsEditingUrl] = useState(false);
    const [editedUrl, setEditedUrl] = useState("");

    const [social, setSocial] = useState(link);
    const [isEditingSocial, setIsEditingSocial] = useState(false);
    const [editedSocial, setEditedSocial] = useState("");

    const [updatedLinkData, setUpdatedLinkData] = useState(null);
    const [updatedSocialData, setUpdatedSocialData] = useState(null);

    const [showError, setShowError] = useState(false);

    // Title
    const handleEditTitle = () => {
        setIsEditingTitle(true);
        setEditedTitle(title);
    };

    const handleSaveEditTitle = () => {
        setTitle(editedTitle);
        setIsEditingTitle(false);
        handleTitleChange(editedTitle);
    };

    const handleCancelEditTitle = () => {
        setIsEditingTitle(false);
    };

    const handleInputChangeTitle = (e) => {
        setEditedTitle(e.target.value);
    };

    // Url
    const handleEditUrl = () => {
        setIsEditingUrl(true);
        setEditedUrl(url);
    };

    const handleSaveEditUrl = () => {
        if (!isValidUrl(editedUrl)) {
            setShowError(true);
            return;
        } else {
            setUrl(editedUrl);
            setIsEditingUrl(false);
            handleUrlChange(editedUrl);
        }
    };

    const handleCancelEditUrl = () => {
        setIsEditingUrl(false);
    };

    const handleInputChangeUrl = (e) => {
        const newValue = e.target.value;

        if (newValue.startsWith("https://")) {
            const urlNoHttps = newValue.slice(8);
            setEditedUrl("https://" + urlNoHttps);
        } else if (newValue.startsWith("http://")) {
            const urlNoHttp = newValue.slice(7);
            setEditedUrl("http://" + urlNoHttp);
        }
    };

    // Social

    const handleEditSocial = () => {
        setIsEditingSocial(true);
        setEditedSocial(socials.user);
    };

    const handleSaveEditSocial = () => {
        if (editedSocial.startsWith("@")) {
        } else if (editedSocial.startsWith("https://")) {
            if (!isValidUrl(editedSocial)) {
                setShowError(true);
                return;
            }
        } else if (editedSocial.startsWith("+")) {
            if (!isValidWPNumber(editedSocial.slice(1))) {
                setShowError(true);
                return;
            }
        }

        setIsEditingSocial(false);
        setSocial(editedSocial);

        setSocialData((prevData) => {
            const updatedSocialData = { ...prevData, user: editedSocial };
            setUpdatedSocialData(updatedSocialData);
            return updatedSocialData;
        });
    };

    const handleCancelEditSocial = () => {
        setIsEditingSocial(false);
    };

    const handleInputChangeSocial = (e) => {
        const newValue = e.target.value;

        if (newValue.startsWith("@")) {
            const username = newValue.slice(1);
            setEditedSocial("@" + username);
        } else if (newValue.startsWith("https://")) {
            const urlHttps = newValue.slice(8);
            setEditedSocial("https://" + urlHttps);
        } else if (newValue.startsWith("+")) {
            const wpNumber = newValue.slice(1);
            setEditedSocial("+" + wpNumber);
        }
    };

    const handleKeyPressSocial = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            handleSaveEditSocial();
        }
    };

    // Handle Changes

    const [linkData, setLinkData] = useState({
        url: link,
        title: title,
        show: showLink,
    });

    const userValue =
        socials && socials.user !== undefined && socials.user !== ""
            ? socials.user
            : "";
    const nombreValue =
        socials && socials.nombre !== undefined && socials.nombre !== ""
            ? socials.nombre
            : "";

    const [socialData, setSocialData] = useState({
        url: link,
        user: userValue,
        show: showLink,
        nombre: nombreValue,
    });

    // Title

    const handleTitleChange = (newTitle) => {
        setLinkData((prevData) => {
            const updatedLinkData = { ...prevData, nombre: newTitle };
            setUpdatedLinkData(updatedLinkData);
            return updatedLinkData;
        });
    };

    const handleKeyPressTitle = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            handleSaveEditTitle();
        }
    };

    // Url

    const handleUrlChange = (newUrl) => {
        if (add === "link") {
            setLinkData((prevData) => {
                const updatedLinkData = { ...prevData, url: newUrl };
                setUpdatedLinkData(updatedLinkData);
                return updatedLinkData;
            });
        } else {
            // setSocialData((prevData) => {
            //     const updatedSocialData = { ...prevData, user: newUrl };
            //     setUpdatedSocialData(updatedSocialData);
            //     return updatedSocialData;
            // });
        }
    };

    const handleKeyPressUrl = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            handleSaveEditUrl();
            setShowError(false);
        }
    };

    const handleToggleChange = (showLink) => {
        if (add === "link") {
            setLinkData((prevData) => {
                const updatedLinkData = { ...prevData, show: showLink };
                setUpdatedLinkData(updatedLinkData);
                return updatedLinkData;
            });
        } else {
            setSocialData((prevData) => {
                const updatedSocialData = { ...prevData, show: showLink };
                setUpdatedSocialData(updatedSocialData);
                return updatedSocialData;
            });
        }
    };

    // Cambios en links
    useEffect(() => {
        if (updatedLinkData !== null) {
            onLinkChange(updatedLinkData);
        }
    }, [updatedLinkData]);

    // Cambios en redes sociales
    useEffect(() => {
        if (updatedSocialData !== null) {
            onSocialChange(updatedSocialData);
        }
    }, [updatedSocialData]);

    useEffect(() => {
        if (isEditingTitle) {
            titleRef.current.focus();
        }
    }, [isEditingTitle]);

    useEffect(() => {
        if (isEditingUrl) {
            urlRef.current.focus();
        }
    }, [isEditingUrl]);

    useEffect(() => {
        if (isEditingSocial) {
            socialRef.current.focus();
        }
    }, [isEditingSocial]);

    const AddLink = () => {
        return (
            <>
                <AlertModal
                    show={showError}
                    onlyAlert={true}
                    title="Error"
                    parrafo="Debes insertar un valor válido"
                    onCancel={() => setShowError(false)}
                />
                <UpperCard>
                    <Editable>
                        <Title>
                            {isEditingTitle ? (
                                <>
                                    <EditInput
                                        type="text"
                                        value={editedTitle}
                                        onChange={handleInputChangeTitle}
                                        ref={titleRef}
                                        onKeyDown={handleKeyPressTitle}
                                        $col={colors.onSurface}
                                    />
                                    <div>
                                        <Button onClick={handleCancelEditTitle}>
                                            <Img src={AddLinkContent.x} />
                                        </Button>
                                        <Button onClick={handleSaveEditTitle}>
                                            <Img src={AddLinkContent.check} />
                                        </Button>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <StyledP
                                        onClick={handleEditTitle}
                                        $col={colors.onSurface}
                                    >
                                        {title}
                                    </StyledP>
                                    <Img
                                        $mar={"5px"}
                                        $wid={"18px"}
                                        src={
                                            theme === "light"
                                                ? AddLinkContent.edit
                                                : AddLinkContent.editDark
                                        }
                                        onClick={handleEditTitle}
                                    />
                                </>
                            )}
                        </Title>
                        <Title>
                            {isEditingUrl ? (
                                <>
                                    <EditInput
                                        type="text"
                                        value={editedUrl}
                                        onChange={handleInputChangeUrl}
                                        ref={urlRef}
                                        onKeyDown={handleKeyPressUrl}
                                        $col={colors.onSurface}
                                    />
                                    <div>
                                        <Button onClick={handleCancelEditUrl}>
                                            <Img src={AddLinkContent.x} />
                                        </Button>
                                        <Button onClick={handleSaveEditUrl}>
                                            <Img src={AddLinkContent.check} />
                                        </Button>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <StyledP
                                        onClick={handleEditUrl}
                                        $col={colors.onSurface}
                                    >
                                        {social}
                                    </StyledP>
                                    <Img
                                        $mar={"5px"}
                                        $wid={"18px"}
                                        src={
                                            theme === "light"
                                                ? AddLinkContent.edit
                                                : AddLinkContent.editDark
                                        }
                                        onClick={handleEditUrl}
                                    />
                                </>
                            )}
                        </Title>
                    </Editable>
                    <div style={{ minWidth: "40px" }}>
                        <ToogleButton
                            onChange={handleToggleChange}
                            setTrue={showLink}
                        />
                    </div>
                </UpperCard>
                <Options>
                    <Menu>
                        {/* {AddLinkContent.options.map((item, index) => (
                            <Li key={index}>
                                <Img src={item} />
                            </Li>
                        ))} */}
                    </Menu>
                    <Delete
                        onClick={() => remove(index)}
                        src={
                            theme === "light"
                                ? AddLinkContent.delete
                                : AddLinkContent.deleteDark
                        }
                    />
                </Options>
            </>
        );
    };

    const AddSocial = () => {
        return (
            <>
                <AlertModal
                    show={showError}
                    onlyAlert={true}
                    title="Error"
                    parrafo="Debes insertar un enlace válido"
                    onCancel={() => setShowError(false)}
                />
                <UpperCard>
                    <Editable>
                        <Title>
                            {isEditingSocial ? (
                                <>
                                    <EditInput
                                        type="text"
                                        value={editedSocial}
                                        onChange={handleInputChangeSocial}
                                        ref={socialRef}
                                        onKeyDown={handleKeyPressSocial}
                                        $col={colors.onSurface}
                                    />
                                    <div className="d-flex">
                                        <Button
                                            onClick={handleCancelEditSocial}
                                        >
                                            <Img src={AddLinkContent.x} />
                                        </Button>
                                        <Button onClick={handleSaveEditSocial}>
                                            <Img src={AddLinkContent.check} />
                                        </Button>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <Img
                                        $mar={"0 8px"}
                                        src={
                                            theme === "light"
                                                ? socials.Img
                                                : socials.ImgDark
                                        }
                                    />
                                    <StyledP
                                        onClick={handleEditSocial}
                                        $col={colors.onSurface}
                                    >
                                        {socials.user}
                                    </StyledP>
                                    <Img
                                        $mar={"5px"}
                                        $wid={"18px"}
                                        src={
                                            theme === "light"
                                                ? AddLinkContent.edit
                                                : AddLinkContent.editDark
                                        }
                                        onClick={handleEditSocial}
                                    />
                                </>
                            )}
                        </Title>
                    </Editable>
                    <HandleElement style={{ minWidth: "40px" }}>
                        <ToogleButton
                            onChange={handleToggleChange}
                            setTrue={showLink}
                        />
                        <Delete
                            className="mx-2"
                            onClick={() => remove(index)}
                            src={
                                theme === "light"
                                    ? AddLinkContent.delete
                                    : AddLinkContent.deleteDark
                            }
                        />
                    </HandleElement>
                </UpperCard>
            </>
        );
    };

    return (
        <div className="my-3">
            <AddLinkStyled $bgcolor={colors.surfaceContainer}>
                {theme === "light" ? (
                    <Img src={AddLinkContent.handle} className="handle" />
                ) : (
                    <Img src={AddLinkContent.handleDark} className="handle" />
                )}
                <Content>{add === "link" ? AddLink() : AddSocial()}</Content>
            </AddLinkStyled>
        </div>
    );
};

const HandleElement = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
`;

const UpperCard = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`;

const Editable = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
`;

const AddLinkStyled = styled.div`
    display: flex;
    justify-content: space-around;
    padding: 10px;
    align-items: center;
    width: 100%;
    height: auto;
    background-color: ${(props) => props.$bgcolor};
    border-radius: 10px;
    cursor: pointer;
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.2);
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 90%;
    margin: 10px;
`;

const Title = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

// const SocialTitle = styled.div`
//     display: flex;
//     justify-content: flex-end;
//     align-items: center;
//     width: 100%;
// `;

const StyledP = styled.p`
    font-size: 1rem;
    text-align: center;
    margin: 0;
    max-width: 120px;
    max-height: 25px;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${(props) => props.$col || "#000"};
`;

const EditInput = styled.input`
    width: auto;
    border: none;
    background-color: transparent;
    display: inline-block;
    color: ${(props) => props.$col || "#000"};
    &:focus,
    &:active,
    &:hover {
        outline: none;
    }
`;

const Button = styled.button`
    background-color: transparent;
    border: none;
    cursor: pointer;
`;

// const Url = styled.div`
//     width: 100%;
//     margin: 10px 0;
// `;

// const InputUrl = styled.input`
//     width: 100%;
//     height: 30px;
//     border-radius: 4px;
//     border: 1px solid #818181;
//     padding: 0 10px;
//     outline: none;
//     background-color: #e9e9e9;
// `;

const Options = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

const Menu = styled.ul`
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0;
`;

// const Li = styled.li`
//     margin: 0 3px;
// `;

const Delete = styled.img`
    width: 25px;
    height: 25px;
    cursor: pointer;
    margin-left: ${(props) => (props.$mr ? props.$mr : "0")};
`;

const Img = styled.img`
    width: ${(props) => (props.$wid ? props.$wid : "25px")};
    height: ${(props) => (props.$wid ? props.$wid : "25px")};
    margin: ${(props) => (props.$mar ? props.$mar : "0")};
`;

export default AddedLink;
