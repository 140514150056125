import React, { useState, useEffect } from "react";
import Title from "../common/TitleSection";
import styled from "styled-components";
import Section from "../common/Section";
import { useTheme } from "../hooks/ThemeContext.jsx";
import { useLang } from "../hooks/LangContext";

const Privacy = () => {
    const { langContent, lang } = useLang();
    const [content, setContent] = useState(langContent);
    useEffect(() => {
        setContent(langContent);
    }, [langContent, lang]);

    const ContentPrivacy = content.Privacy;

    const { themeContent, theme } = useTheme();
    const [colors, setColors] = useState(themeContent);

    useEffect(() => {
        setColors(themeContent);
    }, [themeContent, theme]);

    return (
        <Container>
            <Section>
                <Title
                    size="1.5rem"
                    title={ContentPrivacy.title}
                    align="center"
                />
            </Section>
        </Container>
    );
};

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
`;

export default Privacy;
