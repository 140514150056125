import React, { useEffect, useState } from "react";
import styled from "styled-components";

const calculateBrightness = (hex) => {
    let alpha = false;
    let h = hex.slice(hex.startsWith("#") ? 1 : 0);
    if (h.length === 3) h = [...h].map((x) => x + x).join("");
    else if (h.length === 8) alpha = true;
    h = parseInt(h, 16);

    const r = h >>> 16;
    const g = (h & 0x00ff00) >>> 8;
    const b = h & 0x0000ff;
    const rgba = r * 0.299 + g * 0.587 + b * 0.114;

    return rgba;
};

const getTextColor = (styles) => {
    if (!styles || !styles.type) {
        return "#000";
    }
    if (styles.type === "vertical" || styles.type === "image") {
        return "#000";
    } else if (styles.type === "solido") {
        const color1 = styles.color1;
        const brightness = calculateBrightness(color1);
        if (brightness < 80) {
            return "#fff";
        }
    } else if (styles.type === "horizontal") {
        const color2 = styles.color2;
        const brightness = calculateBrightness(color2);
        if (brightness < 80) {
            return "#fff";
        }
    }
    return "#000";
};

function Footer({ data, styles }) {
    const [colorTexto, setColorTexto] = useState("#000");
    const [arrowImg, setArrowImg] = useState("/img/svg/arrow.svg");

    useEffect(() => {
        const getColor = getTextColor(styles.general.fondoData);
        setColorTexto(getColor);
        if (getColor === "#000") {
            setArrowImg("/img/svg/arrow.svg");
        } else {
            setArrowImg("/img/svg/arrow-dark.svg");
        }
    }, []);

    const handleClick = () => {
        window.open(data.enlaceRappid, "_blank");
    };
    return (
        <ButtonContainer colorTexto={colorTexto} onClick={handleClick}>
            <div className="d-flex align-items-center justify-content-center">
                <PContainer colorTexto={colorTexto}>
                    Crear mi RappidLink gratis
                </PContainer>
                <Icon src={arrowImg} />
            </div>
        </ButtonContainer>
    );
}

const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    background-color: transparent;
    border: 1px solid ${({ colorTexto }) => colorTexto};
    border-radius: 50px;
    cursor: pointer;
`;

const Icon = styled.img`
    width: 20px;
    height: 20px;
    margin-left: 5px;
    margin-top: 3px;
    transform: rotate(180deg);
`;

const PContainer = styled.p`
    color: ${({ colorTexto }) => colorTexto};
    margin: 0;
    font-size: 0.9rem;
`;

// const AFooter = styled.a`
//     color: ${({ colorTexto }) => colorTexto};
//     text-decoration: none;
//     margin-left: 5px;
//     font-size: 0.9rem;

//     &:hover {
//         text-decoration: underline;
//         color: ${({ colorTexto }) => colorTexto};
//     }
// `;

export default Footer;
