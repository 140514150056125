import "firebase/auth";

import {
    getAuth,
    GoogleAuthProvider,
    signInWithPopup,
    FacebookAuthProvider,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    sendEmailVerification,
    signOut,
    deleteUser,
} from "firebase/auth";

const auth = getAuth();

export const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    await signInWithPopup(auth, provider)
        .then((result) => {
            const user = result.user;
        })
        .catch((error) => {
            console.log(
                "Error en el inicio de sesión con Google:",
                error.message,
            );
        });
};

export const signInWithFacebook = async () => {
    const provider = new FacebookAuthProvider();
    await signInWithPopup(auth, provider)
        .then((result) => {
            const user = result.user;
        })
        .catch((error) => {
            console.log(
                "Error en el inicio de sesión con Facebook:",
                error.message,
            );
        });
};

export const signUpWithMail = async (email, password) => {
    await createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            const user = userCredential.user;
            sendEmailVerification(user);
        })
        .catch((error) => {
            console.log(
                "Error en el inicio de sesion con email",
                error.message,
            );
        });
};

export const signInWithMail = async (email, password) => {
    try {
        const userCredential = await signInWithEmailAndPassword(
            auth,
            email,
            password,
        );
        return userCredential;
    } catch (error) {
        console.error("Error en el inicio de sesión:", error);
        throw error;
    }
};

export const signOutUser = async () => {
    await signOut(auth)
        .then((result) => {
            // console.log("Sesion cerrada");
        })
        .catch((error) => {
            console.log("Error en el cierre de sesion");
        });
};

export const deleteAccount = async () => {
    const user = auth.currentUser;
    if (user) {
        await deleteUser(user)
            .then(() => {
                // console.log("Usuario eliminado");
            })
            .catch((error) => {
                console.log("Error en la eliminacion de cuenta");
            });
    }
};
