import { db } from "../../../config/firebase.config.js";
import React, { useEffect, useState } from "react";
import Title from "../common/TitleSection";
import Subtitle from "../common/SubtitleSection";
import Section from "../common/Section";
import ColorPicker from "../common/ColorPicker";
import constant from "../../../constants/constMap.js";
import styled from "styled-components";
import Lock from "../common/Lock";
import { useTheme } from "../hooks/ThemeContext";
import { useLang } from "../hooks/LangContext";


const Perzonaliza = ({
    user,
    onChangeColor1,
    onChangeColor2,
    onChangeType,
    defaultCol1,
    defaultCol2,
    personalize,
}) => {
    const { themeContent, theme } = useTheme();
    const [colors, setColors] = useState(themeContent);
    useEffect(() => {
        setColors(themeContent);
    }, [themeContent, theme]);
    
    const { langContent, lang } = useLang();
    const [content, setContent] = useState(langContent);
    useEffect(() => {
        setContent(langContent);
    }, [langContent, lang]);
    
    const ContentName = content.ColorPicker;

    const [isClick, setIsClick] = useState(false);

    const collection = constant.collectionUser;

    const [userData, setUserData] = useState(null);

    const [picker, setPicker] = useState(true);

    const [gradiente1, setGradiente1] = useState("Gradiente 1");
    const [gradiente2, setGradiente2] = useState("Gradiente 2");

    const [color1, setColor1] = useState(defaultCol1);
    const [color2, setColor2] = useState(defaultCol2);

    const [selectedItemIndex, setSelectedItemIndex] = useState(null);

    const getBorderStyle = (index) => {
        if (index === selectedItemIndex) {
            return `2px solid ${colors.primary}`;
        } else {
            return `2px solid ${colors.onBackground}`;
        }
    };

    const handleBlockClick = (e) => {
        e.stopPropagation();

        setIsClick(true);
        setTimeout(() => {
            setIsClick(false);
        }, 500);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const docRef = db.collection(collection).doc(user);
                const docSnapshot = await docRef.get();
                if (docSnapshot.exists) {
                    const userData = docSnapshot.data();
                    setUserData(userData);
                    const fondoData = userData.styles.general.fondoData;
                    setPicker(
                        fondoData.type === ContentName.vertical ||
                            fondoData.type === ContentName.horizontal
                            ? false
                            : true,
                    );
                } else {
                    console.error("Personaliza: No existe el usuario.");
                }
            } catch (error) {
                console.error(
                    "Personaliza: Error al obtener la imagen de Firestore: ",
                    error,
                );
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (personalize === false) {
            setSelectedItemIndex(null);
        }
    }, [personalize]);

    const handleColorChange = (newColor) => {
        onChangeColor1(newColor);
        setColor1(newColor);
    };

    const handleColorChangeGrad1 = (newColor) => {
        onChangeColor1(newColor);
        setColor1(newColor);
    };

    const handleColorChangeGrad2 = (newColor) => {
        onChangeColor2(newColor);
        setColor2(newColor);
    };

    const onCarruselSelect = (item) => {
        if (item.title === ContentName.ColSolid) {
            setSelectedItemIndex(0);
            setPicker(true);
            onChangeType(ContentName.solido);
        } else if (item.title === ContentName.GradVertical) {
            setSelectedItemIndex(1);
            setPicker(false);
            onChangeType(ContentName.vertical);
        } else {
            setSelectedItemIndex(2);
            setPicker(false);
            onChangeType(ContentName.horizontal);
        }
    };

    const linearGrad0 = `linear-gradient(to right, ${color1} , ${color1})`;
    const linearGrad1 = `linear-gradient(to right, ${color1} , ${color2})`;
    const linearGrad2 = `linear-gradient(to bottom, ${color1} , ${color2})`;

    const item = [
        {
            title: ContentName.ColSolid,
        },
        {
            title: ContentName.GradVertical,
        },
        {
            title: ContentName.GradHorizontal,
        },
        {
            title: ContentName.Col,
        },
    ];

    const linearGrad = [linearGrad0, linearGrad1, linearGrad2];

    return (
        <Section>
            <Title title={content.Personaliza.title} />
            <Subtitle title={content.Personaliza.subtitle} />
            <PersonalizaContainer>
                {item.map((item, index) =>
                    index === 3 ? null : (
                        <Div key={index}>
                            <PersonalizaItem
                                border={() => getBorderStyle(index)}
                                onClick={() => onCarruselSelect(item)}
                                $bg={linearGrad[index]}
                            />
                            <P color={colors.onSurface}>{item.title}</P>
                        </Div>
                    ),
                )}
                <Div>
                    <PersonalizaItem onClick={handleBlockClick} $bg="#D9D9D9">
                        <Lock onShake={isClick} />
                    </PersonalizaItem>
                    <P color={colors.onSurface}>Subir imagen</P>
                </Div>
            </PersonalizaContainer>
            {picker ? (
                <ColorPicker
                    onColorChange={handleColorChange}
                    defaultColor={defaultCol1}
                />
            ) : (
                <>
                    <ColorPicker
                        onColorChange={handleColorChangeGrad1}
                        $bgcolor="#F4ECF0"
                        defaultColor={defaultCol1}
                        text={gradiente1}
                    />
                    <ColorPicker
                        onColorChange={handleColorChangeGrad2}
                        $bgcolor="#F4ECF0"
                        defaultColor={defaultCol2}
                        text={gradiente2}
                    />
                </>
            )}
        </Section>
    );
};

const PersonalizaContainer = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
`;

const PersonalizaItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${(props) => props.$bg};
    border: ${(props) => props.border};
    box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    max-width: 79px;
    width: 79px;
    aspect-ratio: 1/1.82;
    margin: 5px;
    cursor: pointer;
`;

const Div = styled.div`
    min-height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
`;

const P = styled.p`
    font-size: 12px;
    text-align: center;
    padding: 5px;
    color: ${(props) => props.color};
`;

export default Perzonaliza;
