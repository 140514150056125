import React, { useState, useEffect } from "react";
import { getAuth } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { SvgIcon } from "../common/SvgIcon";
import { Link } from "react-router-dom";
import Loading from "../common/Loading";
import { Title } from "../../../dashboard/src/components/Login";
import styled from "styled-components";
import content from "../../../content/es/dashboardContent.json"

const Verificada = () => {
    const Navigate = useNavigate();
    const [showLoading, setShowLoading] = useState(false);

    const auth = getAuth();
    const currentUser = auth.currentUser;

    useEffect(() => {
        setShowLoading(true);
        if (currentUser) {
            setShowLoading(false);
        } else {
            setShowLoading(false);
            Navigate("/auth");
        }
    }, [currentUser]);

    const handleContinue = () => {
        setShowLoading(true);
        Navigate("/welcome");
        setShowLoading(false);
    };

    return (
        <>
            <div
                style={{ height: "100vh", width: "100vw" }}
                className="d-flex justify-content-center align-items-center flex-column"
            >
                {showLoading ? (
                    <Loading />
                ) : (
                    <>
                        <Link to="/" className="d-flex" aria-label="homepage">
                            <SvgIcon
                                src="logo.svg"
                                width="40px"
                                height="60px"
                            />
                        </Link>
                        <Title style={{ marginTop: "5vh" }}>
                            {content.Verificada.title}
                        </Title>
                        <img src="./img/svg/verificada.svg" alt="" />
                        <ButtonContinue onClick={handleContinue}>
                            {content.Verificada.button}
                        </ButtonContinue>
                    </>
                )}
            </div>
        </>
    );
};

const ButtonContinue = styled.button`
    background-color: #a84ac9;
    color: #fff;
    font-size: 12px;
    padding: 10px 45px;
    border: 1px solid transparent;
    border-radius: 8px;
    max-width: 300px;
    width: 40vw;
    margin-top: 10px;
    cursor: pointer;

    &:hover {
        background-color: #9c3dbd;
        color: #fff;
    }
`;

export default Verificada;
