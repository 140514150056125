import React, { useEffect, useState } from "react";
import Compartir from "./BotonCompartir";
import { Container } from "react-bootstrap";
import { useTheme } from "../hooks/ThemeContext";
import constantes from "../../../constants/constMap";
import styled from "styled-components";
// import Content from "../../../content/es/dashboardContent.json";
// const accountText = Content.Header.account;

const LinkShare = ({ account = "Active", user }) => {
    const url = window.location.origin;

    const { themeContent, theme } = useTheme();
    const [colors, setColors] = useState(themeContent);
    useEffect(() => {
        setColors(themeContent);
    }, [themeContent, theme]);

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleWidthChange = () => {
            const newWidth = window.innerWidth;
            setIsMobile(newWidth < constantes.tablet);
        };

        window.addEventListener("resize", handleWidthChange);

        handleWidthChange();

        return () => {
            window.removeEventListener("resize", handleWidthChange);
        };
    }, []);

    const ActiveAccount = {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: colors.surfaceContainer,
        color: colors.onSurface,
        width: "100%",
        maxWidth: "500px",
        borderRadius: "0.5rem",
        padding: "0.5rem 1rem",
        position: "sticky",
        bottom: "0",
        zIndex: "100",
    };

    const ActiveAccountMobile = {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: colors.surfaceContainer,
        color: colors.onSurface,
        width: "100%",
        maxWidth: "100%",
        padding: "0.5rem 1rem",
        position: "fixed",
        bottom: "0px",
        zIndex: "100",
        margin: "0",
    };

    return (
        <>
            <Container style={isMobile ? ActiveAccountMobile : ActiveAccount}>
                <A
                    $col={colors.onSurface}
                    $colHover={colors.primary}
                    href={`${url}/${user}`}
                    target="_blank"

                >
                    {`${url}/${user}`}
                </A>
                <Compartir btnContent="Compartir" href={`${url}/${user}`} />
            </Container>
        </>
    );
};

const A = styled.a`
    margin: 0;
    font-size: 14px;
    color: ${(props) => props.$col};
    text-decoration: none;
    cursor: pointer;

    &:hover {
        color: ${(props) => props.$colHover};
    }

`;

export default LinkShare;
