import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import content from "../../../content/es/linksContent";
import CambiarTexto from "./CambiarTexto";

const normalColorIcon = "#666666";
const hoverColorIcon = "#A84AC9";
const normalColorText = "#101010";
const hoverColorText = "#A84AC9";

const transparentBehind = "rgba(0, 0, 0, 0.4)";
const backgroundCard = "#f9f9f9";
const borderColor = "#ccc";
const backgroundHover = "#EAEAEA";

function TarjetaCompartir({ mostrar, onCerrar, data }) {
    const [hoverCopy, setHoverCopy] = useState(false);
    const [copiado, setCopiado] = useState(false);

    useEffect(() => {
        const handleCloseOnOutsideClick = (event) => {
            if (
                mostrar &&
                event.target.contains(
                    document.getElementById("tarjeta-compartir"),
                )
            ) {
                onCerrar();
            }
        };

        document.addEventListener("mousedown", handleCloseOnOutsideClick);

        return () => {
            document.removeEventListener(
                "mousedown",
                handleCloseOnOutsideClick,
            );
        };
    }, [mostrar, onCerrar]);

    const enlacePrincipal = `${window.location.host}${window.location.pathname}`;
    const enlaceRappid = data.enlaceRappid;

    const share = (item, index) => {
        return (
            <A
                href={item.url}
                target="_blank"
                rel="noopener noreferrer"
                key={index}
            >
                <FontAwesomeIcon
                    style={{
                        marginRight: "1rem",
                        fontSize: "1.5rem",
                    }}
                    icon={item.icon}
                />
                <span>{item.title}</span>
            </A>
        );
    };

    const A = styled.a`
        text-decoration: none;
        color: ${normalColorText};
        padding: 6px;
        border-radius: 5px;
        cursor: pointer;

        svg {
            color: ${normalColorIcon};
        }

        &:hover {
            background-color: ${backgroundHover};
            color: ${hoverColorText};

            svg {
                color: ${hoverColorIcon};
            }
        }
    `;

    return (
        <MenuCompartir mostrar={mostrar} id="tarjeta-compartir">
            <CardContent>
                <div className="d-flex justify-content-end">
                    <BotonCerrar onClick={onCerrar}>
                        <Close href="#">&times;</Close>
                    </BotonCerrar>
                </div>
                <Header>
                    <Titulo className="">
                        {content.tarjetaCompartir.title}
                    </Titulo>
                </Header>
                <ListaRedes>
                    {content.tarjetaCompartir.shareSocial.map((item, index) => {
                        return share(item, index);
                    })}
                </ListaRedes>
                <Center>
                    <Enlace
                        onClick={() => setCopiado(!copiado)}
                        onMouseOver={() => setHoverCopy(true)}
                        onMouseLeave={() => setHoverCopy(false)}
                    >
                        <CambiarTexto
                            copiado={copiado}
                            hover={hoverCopy}
                            url={enlacePrincipal}
                            textoCopiar={
                                content.tarjetaCompartir.cambiarTexto.textoCopiar
                            }
                            textoCopiado={
                                content.tarjetaCompartir.cambiarTexto.textoCopiado
                            }
                        />
                    </Enlace>
                    <P href={enlaceRappid}>{content.tarjetaCompartir.crea}</P>
                </Center>
            </CardContent>
        </MenuCompartir>
    );
}

const MenuCompartir = styled.div`
    display: ${(props) => (props.mostrar ? "flex" : "none")};
    position: absolute;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    overflow: auto;
    background-color: ${transparentBehind};
`;

const Close = styled.a`
    aspect-ratio: 1/1;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${normalColorText};
    &:hover {
        cursor: pointer;
        background-color: ${backgroundHover};
        color: ${hoverColorText};
    }
`;

const Titulo = styled.h3`
    font-size: 1rem;
    font-weight: 500;
`;

const BotonCerrar = styled.button`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: transparent;
    border: none;
    font-size: 1.4rem;
    padding: 2px;
    border-radius: 5px;
    width: 30px;
    height: 20px;
`;

const CardContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: ${backgroundCard};
    margin: 10% auto;
    padding: 20px;
    border: 1px solid gray;
    border-radius: 15px;
    width: 350px;
    min-height: 500px;
    max-height: 550px;
`;
const ListaRedes = styled.ul`
    padding: 0;
    margin: 30px 30px;
    display: flex;
    flex-direction: column;
    font-weight: regular;
`;

const Enlace = styled.a`
    font-size: 0.9em;
    text-align: center;
    margin-top: 20px;
    border: 1px solid ${borderColor};
    border-radius: 10px;
    padding: 20px;
    width: 80%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    text-decoration: none;

    &:hover {
        cursor: pointer;
        background-color: ${backgroundHover};
    }
`;

const Header = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 38px;
`;

const Center = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
`;

const P = styled.a`
    font-size: 0.9em;
    text-align: center;
    margin-top: 20px;
    border: 1px solid ${borderColor};
    border-radius: 10px;
    padding: 20px;
    width: 80%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    background-color: #000;
    color: ${backgroundCard};
    text-decoration: none;
    &:hover {
        cursor: pointer;
        background-color: ${normalColorText};
        color: ${backgroundCard};
    }
`;

export default TarjetaCompartir;
