import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useTheme } from "../../src/hooks/ThemeContext";
import Content from "../../../content/es/dashboardContent.json";

const ThemeToggleButton = ({ version = "expanded" }) => {
    const { themeContent, theme, toggleTheme } = useTheme();
    const [colors, setColors] = useState(themeContent);
    const themeFeatures = Content.Theme;

    const lightImg = themeFeatures.lightb;
    const darkImg = themeFeatures.darkw;

    const collapsedLightImg = themeFeatures.lightw;
    const collapsedDarkImg = themeFeatures.darkb;

    const [lightBg, setLightBg] = useState(colors.onPrimary);
    const [darkBg, setDarkBg] = useState("trasparent");

    useEffect(() => {
        setColors(themeContent);
    }, [themeContent]);

    useEffect(() => {
        setColors(themeContent);

        if (theme === "light") {
            setLightBg(themeContent.onPrimary);
            setDarkBg("trasparent");
        } else {
            setLightBg("trasparent");
            setDarkBg(themeContent.onPrimary);
        }
    }, [themeContent, theme]);

    const handleDarkClick = () => {
        if (theme === "light") {
            toggleTheme();
        } else {
            return;
        }
    };

    const handleLightClick = () => {
        if (theme === "dark") {
            toggleTheme();
        } else {
            return;
        }
    };

    return (
        <>
            {version === "expanded" ? (
                <ToggleButton $bg={colors.primary}>
                    <ImgCointainer $bg={lightBg} onClick={handleLightClick}>
                        <LightTheme src={lightImg}></LightTheme>
                    </ImgCointainer>
                    <ImgCointainer $bg={darkBg} onClick={handleDarkClick}>
                        <DarkTheme src={darkImg}></DarkTheme>
                    </ImgCointainer>
                </ToggleButton>
            ) : (
                <ToggleButton $bg={colors.primary}>
                    {theme === "light" ? (
                        <ImgCointainer  onClick={toggleTheme}>
                            <LightTheme src={collapsedLightImg}></LightTheme>
                        </ImgCointainer>
                    ) : (
                        <ImgCointainer  onClick={toggleTheme}>
                            <DarkTheme src={collapsedDarkImg}></DarkTheme>
                        </ImgCointainer>
                    )}
                </ToggleButton>
            )}
        </>
    );
};

const ToggleButton = styled.button`
    background-color: ${(props) => props.$bg};
    display: flex;
    flex-direction: row;
    gap: 6px;
    justify-content: space-between;
    max-width: 106px;
    max-height: 40px;
    height: auto;
    padding: 6px;
    display: flex;
    align-items: center;
    border-radius: 50px;
    border: none;
    transition: background-color 0.3s ease;
`;

const ImgCointainer = styled.div`
    background-color: ${(props) => props.$bg};
    display: flex;
    width: 50px;
    padding: 2px 6px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 100px;
    transition: background-color 0.3s ease;
`;

const LightTheme = styled.img`
    width: 24px;
    height: 24px;
    border-radius: 50%;
`;

const DarkTheme = styled.img`
    width: 24px;
    height: 24px;
    border-radius: 50%;
`;

export default ThemeToggleButton;
