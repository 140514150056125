import React from "react";
import styled from "styled-components";

function Titulo({ data, styles }) {
    const text = data.titulo;

    const { titulo } = styles;
    const margin = titulo.margin;
    const colorTexto = titulo.colorTexto;
    const textTransform = titulo.textTransform;
    const fontSize = titulo.fontSize;

    return (    
        <TituloContainer
            margin={margin}
            textTransform={textTransform}
        >
            <TituloText 
                fontSize={fontSize}
                colorTexto={colorTexto}
            >
                {text}
            </TituloText>
        </TituloContainer>
    );
}

const TituloContainer = styled.div`
    margin: ${({ margin }) => margin};
    text-transform: ${({ textTransform }) => textTransform};
`;

const TituloText = styled.h1`
    font-size: ${({ fontSize }) => fontSize};
    color: ${({ colorTexto }) => colorTexto};
    margin: 0 0 0 0;};
`;

export default Titulo;
