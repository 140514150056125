import {
    getDocs,
    query,
    where,
    collection,
} from "firebase/firestore";
import constant from "../constants/constMap.js";
export const checkUserDocument = async (db, uid) => {
    const q = query(
        collection(db, constant.collectionUser),
        where("perfil.uid", "==", uid),
    );

    try {
        const querySnapshot = await getDocs(q);
        if (querySnapshot.empty) {
            return false;
        } else {
            return true;
        }
    } catch (error) {
        console.error("Error al verificar el documento del usuario:", error);
        return false;
    }
};
