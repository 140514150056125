import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Search from "./Search";
// import Content from "../../../content/es/dashboardContent.json";
import { useTheme } from "../hooks/ThemeContext";
import { useLang } from "../hooks/LangContext";

const HandleIcons = ({ onClickSocial, toggleBottomSheet, showCloseButton, onClickClose }) => {
    const { themeContent, theme } = useTheme();
    const [colors, setColors] = useState(themeContent);
    useEffect(() => {
        setColors(themeContent);
    }, [themeContent, theme]);

    const { langContent, lang } = useLang();
    const [content, setContent] = useState(langContent);
    useEffect(() => {
        setContent(langContent);
    }, [langContent, lang]);

    const ContentApps = content.BottomSheet;

    const [filteredApps, setFilteredApps] = useState([]);

    useEffect(() => {
        setFilteredApps(ContentApps.apps);
    }, []);

    useEffect(() => {
        if (!toggleBottomSheet) {
            setFilteredApps(ContentApps.apps);
        }
    }, [toggleBottomSheet]);

    const handleChange = (e) => {
        const value = e.target.value.toLowerCase();
        try {
            if (value === "") {
                setFilteredApps(ContentApps.apps);
            } else {
                setFilteredApps(
                    ContentApps.apps.filter((app) =>
                        app.title.toLowerCase().includes(value),
                    ),
                );
            }
        } catch (error) {
            console.error("error");
        }
    };

    const handleClose = () => {
        onClickClose();
    }

    return (
        <>
            <Div>
                <Header
                    $bgCol={colors.surfaceContainer}
                    className="d-flex flex-column w-100 justify-content-center align-items-center gap-4"
                >
                    <H3 $col={colors.onSurface}>{ContentApps.add}</H3>
                    <Search
                        handleChange={handleChange}
                        toggleBottomSheet={toggleBottomSheet}
                    />
                    {showCloseButton && (
                        <Close
                            onClick={handleClose}
                            src={
                                theme === "light"
                                    ? "./img/svg/x.svg"
                                    : "./img/svg/x-dark.svg"
                            }
                            alt="close"
                        />
                    )}
                </Header>
                <Ul>
                    {filteredApps.map((app, index) => (
                        <Li key={index} onClick={() => onClickSocial(app)}>
                            <div className="d-flex justify-content-between w-100">
                                <div className="d-flex">
                                    <img
                                        src={
                                            theme === "light"
                                                ? app.img
                                                : app.imgDark
                                        }
                                        alt={app.title}
                                    />
                                    <p
                                        style={{
                                            color: colors.onSurface,
                                            margin: "0",
                                            fontSize: "16px",
                                        }}
                                    >
                                        {app.title}
                                    </p>
                                </div>
                                <Img
                                    src={
                                        theme === "light"
                                            ? "./img/svg/arrow.svg"
                                            : "./img/svg/arrow-dark.svg"
                                    }
                                    alt={app.title}
                                />
                            </div>
                        </Li>
                    ))}
                </Ul>
            </Div>
        </>
    );
};

const Header = styled.div`
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: ${(props) => props.$bgCol};
`;

const Ul = styled.ul`
    padding: 0;
`;

const H3 = styled.h3`
    color: ${(props) => props.$col};
`;

const Img = styled.img`
    transform: rotate(180deg);
`;

const Li = styled.li`
    width: 100%;
    display: flex;
    align-items: center;
    padding: 6px 0px;
    margin: 0px 0px;
    border-radius: 50px;
    cursor: pointer;
    list-style: none;
    img {
        width: 24px;
        height: 24px;
        margin-right: 10px;
    }
    .file-select {
        position: relative;
        overflow: hidden;
        display: inline-block;
        cursor: pointer;
    }
    .file-input {
        position: absolute;
        opacity: 0;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
        display: none;
    }
`;

const Div = styled.div`
    height: 470px;
    overflow-y: auto;
    width: 100%;
    h3 {
        font-size: 14px;
        font-weight: 500;
        margin: 0;
    }
    ul {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }
`;

const Close = styled.img`
    position: absolute;
    right: 12px;
    top: 0px;
    width: 20px;
    height: 20px;
    cursor: pointer;
`;

export default HandleIcons;

{
    /* <Li onClick={onTakePicture}>
<img
src={ContentBottom[0].img}
alt={ContentBottom[0].title}
/>
<p
style={{
color: "#101010",
margin: "0",
fontSize: "16px",
}}
>
{ContentBottom[0].title}
</p>
</Li> */
}
