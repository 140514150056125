import { db, storage } from "../../../config/firebase.config.js";
import "firebase/firestore";
import "firebase/storage";
import React, { useEffect, useState } from "react";
import Carrusel from "../common/Carrusel";
import Title from "../common/TitleSection";
import Subtitle from "../common/SubtitleSection";
import Section from "../common/Section";
import constant from "../../../constants/constMap.js";
import { useLang } from "../hooks/LangContext";

const Temas = ({ user, onChangeImage, tema, isMobile }) => {

    const { langContent, lang } = useLang();
    const [content, setContent] = useState(langContent);
    useEffect(() => {
        setContent(langContent);
    }, [langContent, lang]);

    const collection = constant.collectionUser;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const docRef = db.collection(collection).doc(user);
                const docSnapshot = await docRef.get();
                if (docSnapshot.exists) {
                    const userData = docSnapshot.data();
                } else {
                    // console.log('Personaliza: No existe el usuario.')
                }
            } catch (error) {
                console.error(
                    "Fondo: Error al obtener la imagen de Firestore: ",
                    error,
                );
            }
        };

        fetchData();
    }, []);

    const handleImageUpload = (imageUrl) => {
        const storageRef = storage.ref(`${user}/images/fondo1.svg`);
        fetch(imageUrl)
            .then((response) => response.blob())
            .then((blob) => {
                storageRef
                    .put(blob)
                    .then(() => {
                        storageRef
                            .getDownloadURL()
                            .then((downloadURL) => {
                                db.collection(collection)
                                    .doc(user)
                                    .set(
                                        {
                                            styles: {
                                                general: {
                                                    fondoData: {
                                                        image: downloadURL,
                                                    },
                                                },
                                            },
                                        },
                                        { merge: true },
                                    )
                                    .then(() => {
                                        return db
                                            .collection(collection)
                                            .doc(user)
                                            .set(
                                                {
                                                    styles: {
                                                        general: {
                                                            backgroundColor: `url(${downloadURL})`,
                                                        },
                                                    },
                                                },
                                                { merge: true },
                                            );
                                    })
                                    .catch((error) => {
                                        console.error(
                                            "Error al guardar la URL en Firestore: ",
                                            error,
                                        );
                                    });
                            })
                            .catch((error) => {
                                console.error(
                                    "Error al obtener la URL de descarga de Firebase: ",
                                    error,
                                );
                            });
                    })
                    .catch((error) => {
                        console.error(
                            "Error al subir la imagen a Firebase: ",
                            error,
                        );
                    });
            })
            .catch((error) => {
                console.error(
                    "Error al obtener la imagen desde la URL: ",
                    error,
                );
            });
    };

    const onCarruselSelect = (item) => {
        handleImageUpload(item.img);
        onChangeImage(item.img);
    };

    return (
        <Section>
            <Title title={content.Temas.title} />
            <Subtitle title={content.Temas.subtitle} />
            <Carrusel
                tema={tema}
                onSelect={onCarruselSelect}
                Content={content.Carrusel1}
                isMobile={isMobile}
            />
        </Section>
    );
};

export default Temas;
