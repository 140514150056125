import React, { useState, useEffect } from "react";
import { SvgIcon } from "../common/SvgIcon";
import { Link } from "react-router-dom";
import { Anchor, Span, Title } from "./Login";
import styled from "styled-components";
import { getAuth } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import Loading from "../common/Loading";
import content from "../../../content/es/dashboardContent.json";
const verif = content.Verificacion;

const Verificacion = () => {
    const Navigate = useNavigate();
    const [showLoading, setShowLoading] = useState(false);

    const auth = getAuth();
    const currentUser = auth.currentUser;
    const email = currentUser?.email;

    useEffect(() => {
        if (!currentUser) {
            Navigate("/auth");
        }
    }, [currentUser]);

    const handleContinue = () => {
        setShowLoading(true);

        if (currentUser) {
            currentUser.reload();
            const emailVerified = currentUser.emailVerified;

            if (emailVerified) {
                setShowLoading(false);
                Navigate("/verificada");
            } else {
                setShowLoading(false);
                alert(
                    "Aún no has verificado tu cuenta. Por favor, revisa tu correo electrónico.",
                );
            }
        }
    };

    return (
        <>
            <div
                style={{ height: "100vh", width: "100vw" }}
                className="d-flex justify-content-center align-items-center flex-column"
            >
                {showLoading ? (
                    <Loading />
                ) : (
                    <>
                        <Link to="/" className="d-flex" aria-label="homepage">
                            <SvgIcon
                                src="logo.svg"
                                width="40px"
                                height="60px"
                            />
                        </Link>
                        <Title style={{ marginTop: "5vh" }}>
                            {verif.title}
                        </Title>
                        <Span
                            style={{
                                textAlign: "center",
                                display: "flex",
                                justifyContent: "center",
                                marginBottom: "5vh",
                            }}
                        >
                            {verif.text1}
                            <br />
                            {`(${email})`}
                            <br />
                            {verif.text2}
                        </Span>
                        <img src="./img/svg/verificacion.svg" alt="verificacion" />
                        <Anchor onClick={handleContinue}>
                            <ButtonContinue>{verif.button1}</ButtonContinue>
                        </Anchor>
                        <Anchor>
                            <ButtonContinue>{verif.button2}</ButtonContinue>
                        </Anchor>
                    </>
                )}
            </div>
        </>
    );
};

const ButtonContinue = styled.button`
    background-color: #a84ac9;
    color: #fff;
    font-size: 12px;
    padding: 10px 45px;
    border: 1px solid transparent;
    border-radius: 8px;
    max-width: 300px;
    width: 40vw;
    margin-top: 10px;
    cursor: pointer;

    &:hover {
        background-color: #9c3dbd;
        color: #fff;
    }
`;

export default Verificacion;
