import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useTheme } from "../hooks/ThemeContext";

const CustomCheckbox = ({
    usable = true,
    setTrue = false,
    onChange,
    disable = false,
}) => {
    const { themeContent, theme } = useTheme();
    const [colors, setColors] = useState(themeContent);

    const [isChecked, setIsChecked] = useState(setTrue);
    const [img, setImg] = useState(null);
    useEffect(() => {
        setColors(themeContent);
        setImg(
            isChecked
                ? theme === "light"
                    ? "/img/svg/check.svg"
                    : "/img/svg/check-dark.svg"
                : theme === "light"
                  ? "/img/svg/x-gray.svg"
                  : "/img/svg/x-dark.svg",
        );
    }, [themeContent, theme]);

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
        setImg(
            !isChecked
                ? theme === "light"
                    ? "/img/svg/check.svg"
                    : "/img/svg/check-dark.svg"
                : theme === "light"
                  ? "/img/svg/x-gray.svg"
                  : "/img/svg/x-dark.svg",
        );
        if (onChange) {
            onChange(!isChecked);
        }
    };

    return (
        <CustomCheckboxContainer
            $bgcolor={
                isChecked && usable
                    ? colors.primary
                    : colors.bgSwitchDesactivado
            }
        >
            <CustomCheckboxInput
                type="checkbox"
                checked={isChecked}
                onChange={() => {
                    handleCheckboxChange();
                }}
                disabled={disable ? true : false}
            />
            <CustomCheckboxLabel
                $bgcolor={
                    isChecked
                        ? colors.onPrimary
                        : colors.circleSwitchDesactivado
                }
                $transition={usable ? "transform 0.3s ease-in-out" : ""}
                transform={usable ? "translateX(18px)" : ""}
            >
                <Img src={img} alt="ToogleButton" />
            </CustomCheckboxLabel>
        </CustomCheckboxContainer>
    );
};

export default CustomCheckbox;

const CustomCheckboxContainer = styled.label`
    display: inline-block;
    position: relative;
    width: 40px;
    height: 22px;
    background-color: ${(props) => props.$bgcolor};
    border-radius: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
    bo
`;

const CustomCheckboxInput = styled.input`
    display: none;
`;

const CustomCheckboxLabel = styled.span`
    display: block;
    position: absolute;
    top: 2px;
    left: 2px;
    width: 18px;
    aspect-ratio: 1;
    background-color: ${(props) => props.$bgcolor};
    border-radius: 50%;
    transition: ${(props) => props.$transition};

    ${CustomCheckboxContainer} input[type="checkbox"]:checked + & {
        transform: ${(props) => props.transform};
    }
`;

const Img = styled.img`
    position: absolute;
    display: block;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
`;
