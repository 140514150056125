import React from "react";
import Router from "./AppRouter";
import { ThemeProvider } from "./dashboard/src/hooks/ThemeContext";
import { DefaultDBProvider } from "./dashboard/src/hooks/RealTimeContext";
import { LangProvider } from "./dashboard/src/hooks/LangContext";

import { useState, useEffect } from "react";
import {
    getFirestore,
    getDocs,
    query,
    where,
    collection,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import { useNavigate } from "react-router-dom";
import { checkUserDocument } from "./utils/utils.js";
import constant from "./constants/constMap.js";


const App = () => {
    const [user, setUser] = useState(null);
    const Navigate = useNavigate();

    const [userId, setUserId] = useState(null);

    useEffect(() => {
        const auth = getAuth();
        const db = getFirestore();
        const currentUser = auth.currentUser;
        const uid = currentUser ? currentUser.uid : "";

        const checkUser = async () => {
            try {
                if (!currentUser) {
                    return;
                } else if (!currentUser.emailVerified) {
                    Navigate("/verificacion");
                    return;
                } else {
                    const documentExists = await checkUserDocument(db, uid);

                    if (!documentExists) {
                        Navigate("/welcome");
                        return;
                    }

                    const q = query(
                        collection(db, constant.collectionUser),
                        where("perfil.uid", "==", uid),
                    );

                    try {
                        const querySnapshot = await getDocs(q);
                        const userData =
                            querySnapshot.docs[0].data().perfil.nombre;
                        setUser(userData);
                    } catch (error) {
                        console.error("Error al verificar el usuario:", error);
                    }
                }
            } catch (error) {
                console.error("Error al verificar el usuario:", error);
            }
        };

        checkUser();
    }, []);

    useEffect(() => {
        setUserId(user);
    }, [user]);
 

    return (
        <DefaultDBProvider user={userId}>
            <ThemeProvider>
                <LangProvider>
                    <Router />
                </LangProvider>
            </ThemeProvider>
        </DefaultDBProvider>
    );
};

export default App;
