import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import Avatar from "../common/Avatar.jsx";
import ButtonRappid from "../common/ButtonRappid.jsx";
import Content from "../../../content/es/dashboardContent.json";
import Tema from "../common/Tema.jsx";
import constant from "../../../constants/constMap.js";
import { useNavigate } from "react-router-dom";
import { db } from "../../../config/firebase.config.js";
import { getAuth, signOut } from "firebase/auth";
import { useTheme } from "../hooks/ThemeContext.jsx";

const SidebarComponent = ({ sidebarOpen, setSidebarOpen, user }) => {
    const { themeContent, theme } = useTheme();
    const [colors, setColors] = useState(themeContent);
    useEffect(() => {
        setColors(themeContent);
    }, [themeContent, theme]);

    const Navigate = useNavigate();
    const collection = constant.collectionUser;
    const side = Content.Sidebar;
    const [userData, setUserData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (user) {
                    const docRef = db.collection(collection).doc(user);
                    const docSnapshot = await docRef.get();
                    if (docSnapshot.exists) {
                        // console.log(docSnapshot.data())
                        setUserData(docSnapshot.data());
                    } else {
                        // console.log('Fondo: No existe el usuario.')
                    }
                }
            } catch (error) {
                console.error(
                    "Sidebar: Error al obtener la imagen de Firestore: ",
                    error,
                );
            }
        };
        fetchData();
    }, [user]);

    // const handleSidebar = () => {
    //     setSidebarOpen(!sidebarOpen);
    // };

    const handleCloseSession = () => {
        const auth = getAuth();
        signOut(auth)
            .then(() => {
                Navigate("/");
            })
            .catch((error) => {
                console.error("An error happened.", error);
            });
    };

    return (
        <>
            <Sidebar $bgcolor={colors.surface}>
                <AvatarContainer onClick={() => setSidebarOpen(!sidebarOpen)}>
                    <Avatar />
                </AvatarContainer>
                <SidebarContent>
                    <Profile>
                        {userData && (
                            <>
                                <P
                                    $col={colors.onSurface}
                                >{`@${userData.perfil.nombre}`}</P>
                                <P $col={colors.onSurface}>
                                    {userData.perfil.mail}
                                </P>
                                <P
                                    $col={colors.onSurface}
                                >{`Tu plan: ${userData.perfil.plan}`}</P>
                                <ButtonRappid />
                            </>
                        )}
                    </Profile>
                    <Menu>
                        {side.menu.map((item, index) => (
                            <Li key={index}>
                                <img
                                    src={
                                        theme === "light"
                                            ? item.icon
                                            : item.iconDark
                                    }
                                    alt={item.text}
                                />
                                <A $col={colors.onSurface} href={item.link}>
                                    {item.text}
                                </A>
                            </Li>
                        ))}
                    </Menu>
                    <Bottom>
                        <Tema />
                        {side.bottom.text.map((item, index) => (
                            <Li
                                key={index}
                                onClick={handleCloseSession}
                                $col={colors.onSurface}
                            >
                                <img
                                    src={
                                        theme === "light"
                                            ? side.bottom.iconCerrar
                                            : side.bottom.iconCerrarDark
                                    }
                                    alt={item}
                                />
                                {item}
                            </Li>
                        ))}
                    </Bottom>
                </SidebarContent>
            </Sidebar>
        </>
    );
};

const slideIn = keyframes`
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(0);
    }
`;

const Sidebar = styled.div`
    @media (max-width: 767px) {
        z-index: 200;
        position: fixed;
        display: flex;
        flex-direction: column;
        right: 0;
        top: 0;
        width: 80%;
        height: 100%;
        border-left: 1px solid #101010;
        background-color: ${(props) => props.$bgcolor};
        transition: transform 0.6s ease-in-out;
        transform: translateX(0);
        animation: ${slideIn} 0.4s ease-in-out;
    }
    @media (min-width: 768px) {
        position: fixed;
        left: 0;
        top: 0;
        width: 250px;
        height: 100%;
    }
`;

const SidebarContent = styled.div`
    display: flex;
    flex-direction: column;
    padding: 8px 24px;
    margin-bottom: 8px;
    height: 100%;
`;

const AvatarContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 8px 24px;
`;

const Li = styled.li`
    display: flex;
    align-items: center;
    padding: 8px 0px;
    margin: 8px 0px;
    border-radius: 50px;
    cursor: pointer;
    list-style: none;
    color: ${(props) => props.$col};
    img {
        width: 24px;
        height: 24px;
        margin-right: 10px;
    }
`;

const A = styled.a`
    text-decoration: none;
    color: ${(props) => props.$col};
    &:hover {
        color: ${(props) => props.$col};
    }
`;

const Profile = styled.div`
    display: flex;
    flex-direction: column;
    align-items: left;
    margin-bottom: 50px;
`;

const P = styled.p`
    color: ${(props) => props.$col};
`;

const Menu = styled.ul`
    display: flex;
    flex-direction: column;
    align-items: left;
    padding-left: 0px;
    margin-bottom: 8px;
    list-style: none;
`;

const Bottom = styled.ul`
    display: flex;
    flex-direction: column;
    margin-top: auto;
    padding-left: 0px;
    margin-bottom: 30px;
    list-style: none;
`;

export default SidebarComponent;
