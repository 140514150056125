import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useTheme } from "../hooks/ThemeContext";
const TitleSection = ({
    title,
    align = "left",
    show = true,
    size = "16px",
}) => {
    const { themeContent, theme } = useTheme();
    const [colors, setColors] = useState(themeContent);
    useEffect(() => {
        setColors(themeContent);
    }, [themeContent, theme]);

    return (
        <>
            {show ? (
                <StyledH2
                    size={size}
                    color={colors.onSurface}
                    $alignitem={align}
                >
                    {title}
                </StyledH2>
            ) : null}
        </>
    );
};

const StyledH2 = styled.h2`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: ${(props) => props.$alignitem};
    justify-content: center;
    font-size: ${(props) => props.size};
    color: ${(props) => props.color};
    font-weight: bold;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.15px;
`;

export default TitleSection;
