import React, { useState, useEffect } from "react";
import styled from "styled-components";

const Recuperar = ({ isOpen, message, onClose }) => {
    const [visible, setVisible] = useState(isOpen);

    useEffect(() => {
        setVisible(isOpen);

        if (isOpen) {
            const timer = setTimeout(() => {
                setVisible(false);
                onClose();
            }, 30000);

            return () => clearTimeout(timer);
        }
    }, [isOpen, onClose]);

    return (
        <RecStyle visible={visible}>
            <Container>
                <p style={{ margin: "0 30px", fontSize: "15px" }}>{message}</p>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        paddingRight: "12px",
                    }}
                >
                    <span className="close" onClick={onClose}>
                        {" "}
                        x{" "}
                    </span>
                </div>
            </Container>
        </RecStyle>
    );
};

const RecStyle = styled.div`
    color: #333333;
    background-color: #cdb6e1;
    width: 100%;
    display: ${(props) => (props.visible ? "flex" : "none")};
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 5px;

    @media (min-width: 768px) {
        margin-top: 20px;
        max-width: 500px;
    }
`;

const Container = styled.div`
    display: flex;
    justify-content: space-between;
`;

export default Recuperar;
