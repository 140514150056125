import React from "react";
import styled from "styled-components";

function Logo({ data, styles }) {
    const src = data.logo;
    const link = data.enlacePrincipal;

    const { logo } = styles;
    // const logoSize = logo.logoSize;
    const margin = logo.margin;

    return (
        <LogoContainer margin={margin}>
            <a target="blank" href={link}>
                <LogoImg src={src} alt="Logo" logoSize="120px" />
            </a>
        </LogoContainer>
    );
}

const LogoContainer = styled.div`
    margin: ${({ margin }) => margin};
    display: flex;
    align-items: center;
    justify-content: center;
    height: 10vw;
    width: 100%;
    margin: 10vh 0 5vh 0;
`;

const LogoImg = styled.img`
    height: ${({ logoSize }) => logoSize};
    width: ${({ logoSize }) => logoSize};
    border-radius: 50%;
`;

export default Logo;
