import { CircularProgress } from "react-cssfx-loading/";
import styled from "styled-components";

const Loading = ({ position = "relative", height = "100vh", width = "100vw" }) => {
    return (
        <Container height={height} width={width} $position={position}>
            <CircularProgress
                color="#A84AC9"
                width="100px"
                height="100px"
                duration="2s"
            />
        </Container>
    );
};

const Container = styled.div`
    position: ${(props) => props.$position || "relative"};
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${(props) => props.width || "100vw"};
    height: ${(props) => props.height || "100vh"};
`;

export default Loading;
