import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Draggable from "react-draggable";
import HandlePicture from "./HandlePicture";
import HanadleIcons from "./HandleIcons";
import constant from "../../../constants/constMap.js";
import { useTheme } from "../hooks/ThemeContext";

const BottomSheets = ({
    toggleBottomSheet,
    onClose,
    onDeletePicture,
    onClickSocial,
    user,
    pictureHandle = true,
    heigth = "auto",
    show = false,
}) => {
    const { themeContent, theme } = useTheme();
    const [colors, setColors] = useState(themeContent);
    useEffect(() => {
        setColors(themeContent);
    }, [themeContent, theme]);

    const [draggedDown, setDraggedDown] = useState(false);
    const [position, setPosition] = useState({ x: 0, y: 0 });

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const isTablet = windowWidth < constant.breakpoints.tablet;
    const isMobile = windowWidth < constant.breakpoints.mobile;

    useEffect(() => {
        if (!toggleBottomSheet) {
            setPosition({ x: 0, y: 0 });
        }
    }, [toggleBottomSheet]);

    const handleStart = () => {
        setDraggedDown(true);
    };

    const handleStop = (e, data) => {
        if (draggedDown && data.y > 60) {
            onClose();
        } else {
            setPosition({ x: 0, y: 0 });
        }
        setDraggedDown(false);
    };

    const handleClickSocial = (app) => {
        onClickSocial(app);
        onClose();
    };

    const handleClickOutside = (e) => {
        if (!isMobile) {
            e.stopPropagation();
            onClose();
        }
    };

    const renderBottomSheet = () => {
        return (
            <>
                <Draggable
                    axis="y"
                    handle=".draggable-handle"
                    onStart={handleStart}
                    onStop={handleStop}
                    position={position}
                    allowAnyClick={false}
                    bounds={{ top: 0 }}
                >
                    <BottomSheetContainer
                        $toggle={toggleBottomSheet}
                        $hei={heigth}
                        $bgCol={colors.surfaceContainer}
                    >
                        <ContentContainer className={"draggable-handle"}>
                            <IconContainer>
                                {theme === "light" ? (
                                    <img
                                        src="/img/svg/lineClose.svg"
                                        alt="Close"
                                        title="Close"
                                    />
                                ) : (
                                    <img
                                        src="/img/svg/lineClose-dark.svg"
                                        alt="Close"
                                        title="Close"
                                    />
                                )}
                            </IconContainer>
                        </ContentContainer>
                        <ContentContainer>
                            {pictureHandle ? (
                                <HandlePicture
                                    user={user}
                                    onDeletePicture={onDeletePicture}
                                />
                            ) : (
                                <HanadleIcons
                                    onClickSocial={handleClickSocial}
                                    toggleBottomSheet={toggleBottomSheet}
                                />
                            )}
                        </ContentContainer>
                    </BottomSheetContainer>
                </Draggable>
                <BottomSheetBackdrop
                    $show={toggleBottomSheet}
                    onClick={onClose}
                />
            </>
        );
    };
    const renderModal = () => {
        return (
            <>
                <Background onClick={handleClickOutside} />
                <ModalContainer
                    $toggle={toggleBottomSheet}
                    $hei={heigth}
                    $bgCol={colors.surfaceContainer}
                    position={pictureHandle}
                >
                    <ContentContainer pictureHandle={pictureHandle}>
                        {pictureHandle ? (
                            <HandlePicture
                                user={user}
                                onDeletePicture={onDeletePicture}
                            />
                        ) : (
                            <HanadleIcons
                                onClickSocial={handleClickSocial}
                                toggleBottomSheet={toggleBottomSheet}
                                showCloseButton={true}
                                onClickClose={onClose}
                            />
                        )}
                    </ContentContainer>
                </ModalContainer>
            </>
        );
    };

    return (
        <>
            {show && (
                <>
                    {isMobile ? (
                        <>{renderBottomSheet()}</>
                    ) : (
                        <>{renderModal()}</>
                    )}
                </>
            )}
        </>
    );
};

const BottomSheetContainer = styled.div`
    position: fixed;
    bottom: ${(props) => (props.$toggle ? "0" : "-100%")};
    left: 0;
    right: 0;
    height: ${(props) => props.$hei};
    background-color: ${(props) => props.$bgCol};
    transition: bottom 0.3s ease-in-out;
    z-index: 2001;
    box-shadow: 0px -5px 5px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    border-radius: 20px 20px 0 0;
`;

const ContentContainer = styled.div`
    border-radius: 20px 20px 0 0;
    padding: 10px 20px;
    flex-grow: 1;
    overflow: auto;
    margin-top: ${(props) => (props.pictureHandle ? "0" : "20px")};
`;

const BottomSheetBackdrop = styled.div`
    display: ${(props) => (props.$show ? "block" : "none")};
    position: fixed;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.2);
    z-index: 30;
    overflow: hidden;
`;

const ModalContainer = styled.div`
    position: fixed;
    max-width: 505px;
    width: auto;
    height: auto;
    background-color: ${(props) => props.$bgCol};
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 4000;
    padding: ${(props) => (!props.position ? "24px 18px" : "0")};
    border-radius: 12px;
    top: ${(props) => (!props.position ? "20%" : "15%")};
    left: ${(props) => (!props.position ? "20%" : "40%")};;
`;

const IconContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    background-color: transparent;
    cursor: pointer;
    width: 100%;
    height: 50%x;
    img {
        width: 20px;
        height: 20px;
    }
`;

const Background = styled.div`
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
`;

export default BottomSheets;
