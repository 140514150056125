import { Suspense } from "react";
import { Route, Routes, useLocation, Navigate } from "react-router-dom";
import Links from "./links/src/App";
import dashboard from "./config/config.js";
import "./App.css";

import AuthRoute from "./dashboard/src/components/AuthRoute";
import Loading from "./dashboard/src/common/Loading";

const AppRouter = () => {
    const location = useLocation();

    const protectedRoutes = ["/editor"];

    const protect = protectedRoutes.some((route) =>
        location.pathname.startsWith(route),
    );

    return (
        <Suspense fallback={<Loading></Loading>}>
            {/* <Styles /> */}
            <Routes>
                <Route path="/" element={<Navigate replace to="/editor" />} />
                {dashboard.map((route, index) => {
                    if (protect) {
                        return (
                            <Route
                                key={index}
                                path={route.path}
                                element={
                                    <AuthRoute>
                                        <route.component />
                                    </AuthRoute>
                                }
                            />
                        );
                    } else
                        return (
                            <Route
                                key={index}
                                path={route.path}
                                element={<route.component />}
                            />
                        );
                })}
                <Route path="/:user" element={<Links />} />
            </Routes>
        </Suspense>
    );
};

export default AppRouter;
