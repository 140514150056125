import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { db } from "../../config/firebase.config.js";
import Home from "./components/Home";
import constantes from "../../constants/constMap.js";
import Loading from "../../dashboard/src/common/Loading";
import styled from "styled-components";
import "./App.css";
import "firebase/firestore";

function UserPage() {
    const { user } = useParams();
    const [userData, setUserData] = useState(null);
    const [userStyles, setUserStyles] = useState(null);
    const [userNotFound, setUserNotFound] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const docRef = await db
                    .collection(constantes.collectionUser)
                    .doc(user)
                    .get();

                if (docRef.exists) {
                    const data = docRef.data().datos;
                    const styles = docRef.data().styles;

                    setUserData(data);
                    setUserStyles(styles);
                } else {
                    setUserNotFound(true);
                }
            } catch (error) {
                console.error("Error al cargar datos:", error);
            }
        };

        fetchData();
    }, [user]);

    if (userNotFound) {
        return (
            <Center>
                <ErrorMessage>No existe</ErrorMessage>
            </Center>
        );
    }

    if (!userData || !userStyles) {
        return (
            <Center>
                <Loading />
            </Center>
        );
    }

    return <Home data={userData} styles={userStyles} />;
}

const Center = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
`;

const ErrorMessage = styled.div`
    font-size: 24px;
    color: red;
`;

export default UserPage;
