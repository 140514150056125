import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Container from "react-bootstrap/Container";
import { useTheme } from "../hooks/ThemeContext";

const UsableButton = ({ onClick, btnContent, imgSrc, imgSrcDark }) => {
    const { themeContent, theme } = useTheme();
    const [colors, setColors] = useState(themeContent);
    useEffect(() => {
        setColors(themeContent);
    }, [themeContent, theme]);

    return (
        <Container className="d-flex justify-content-center px-0">
            <Button
                onClick={onClick}
                color={colors.onPrimary}
                bg={colors.primary}
            >
                {
                    imgSrc && theme === 'light' && <Img src={imgSrc} />
                }
                {
                    imgSrcDark && theme === 'dark' && <Img src={imgSrcDark} />
                }
                {btnContent}
            </Button>
        </Container>
    );
};

export default UsableButton;

const Button = styled.button`
    width: 100%;
    border: none;
    border-radius: 50px;
    color: ${(props) => props.color};
    background-color: ${(props) => props.bg};
    max-width: 100%;
    padding: 10px 0px;
    margin: 10px 0px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; 
    letter-spacing: 0.1px;
`;

const Img = styled.img`
    width: 18px;
    margin-right: 10px;
`;
