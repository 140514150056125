const defaultDB = {
    perfil: {
        nombre: "",
        mail: "",
        plan: "",
        img: "",
        default: "",
        uid: "",
    },
    datos: {
        logo: "",
        titulo: "Titulo",
        subtitulo: "Subtitulo",
        descripcion: "Descripcion",
        poweredBy: "RAppID Tech",
        enlacePrincipal: "",
        enlacePrinCorto: "",
        enlaceRappid: "https://app.rappidtech.com/",
        redes: [],
        enlaces: [],
        carrusel: [],
    },
    styles: {
        general: {
            backgroundColor: "#F9F9F9",
            headerBackground: "",
            headerBackgroundSize: "cover",
            headerBackgroundPosition: "center",
            headerBackgroundRepeat: "",
            headerHeight: "15vh",
            headerWidth: "100vw",
            headerPosition: "absolute",
            fondoData: {
                color1: "#F9F9F9",
                color2: "#D1D1D1",
                type: "solido",
                image: null,
            },
            defaultImg:
                "https://firebasestorage.googleapis.com/v0/b/rappid-links-web-test.appspot.com/o/default%2Ffondo1.png?alt=media&token=0a2f1ce5-48c4-4a1b-be8d-c639a0ed6067",
        },
        logo: {
            margin: "15vh 0 0 0",
            logoSize: "15vw",
        },
        titulo: {
            margin: "5vh 0 0 0",
            colorTexto: "#101010",
            fontSize: "1.5rem",
            textTransform: "none",
        },
        subtitulo: {
            margin: "0vh 0 0vh 0",
            colorTexto: "#101010",
            fontSize: "1rem",
            fontWeigth: "regular",
            textTransform: "none",
        },
        descripcion: {
            colorTexto: "#101010",
            fontSize: "1rem",
            fontWeigth: "regular",
            textTransform: "none",
        },
        enlaces: {
            colorTexto: "#F9F9F9",
            colorFondo: "#101010",
            colorHover: "",
            colorActive: "#A3B6B7",
            borderRadius: "0",
            colorIcon: "",
            fontWeigth: "600",
            fontSize: "1.5rem",
            textTransform: "none",
            scaleLink: "scale(1.05)",
            margin: "6px 0",
            padding: "12px 20px",
            border: "none",
            justifyContent: "space-between",
            widthMD: "30vw",
            widthSM: "70vw",
            leftIcon: "icon-delete",
            rightIcon: "icon-delete",
        },
        carrusel: {
            height: "80px",
            width: "80px",
        },
        redes: {
            colorPrincipal: "transparent",
            colorHover: "transparent",
            margin: "0",
            padding: "0",
            colorIconSVG: "#101010",
            position: "Arriba"
        },
        footer: {
            colorTexto: "#F9F9F9",
            backgroundColor: "#101010",
            height: "10vh",
        },
        compartir: {
            colorIcon: "#F9F9F9",
            fondoIcon: "#101010",
        },
    },
};

export default defaultDB;
