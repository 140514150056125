import React from "react";
import "antd/dist/antd.css";
import { Divider } from "antd";

function Separator() {
    return <Divider style={SeparatorStyled} />;
}

const SeparatorStyled = {
    borderColor: "#cccccc",
    margin: "10px 0",
    maxWidth: "320px",
};


export default Separator;
